import {Component, Input} from '@angular/core';
import {SettingsService} from '../../services/settings/settings.service';

@Component({
  selector: 'app-contact-button',
  templateUrl: './contact-buttons.component.html',
  styleUrls: ['./contact-buttons.component.scss']
})
export class ContactButtonsComponent {
  @Input() mailTo: string;
  @Input() mailToLabel: string;
  @Input() phoneNo: string;
  @Input() isColumn: boolean = true;

  constructor(settingService: SettingsService) {
    this.mailTo = settingService.getSetting('emails.p4p');
  }
}
