import {JsonConverter, JsonElementType, JsonObject, JsonProperty} from 'ta-json';
import {DbId} from '../dbid';
import {ListButton} from '../list/list-data';
import {UTCInterpreter} from '../utcinterpreter';
import {Asset} from '../assets';
import {LocalDateTime} from '@js-joda/core';

@JsonObject()
export class Toolbox {
  @JsonProperty() public id: DbId = DbId.from();
  @JsonProperty() public name: string;
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt?: LocalDateTime = null;
  @JsonProperty() @JsonElementType(Asset) public assets: Asset[] = [];
  @JsonProperty() public isFixed: boolean;
}

export interface IToolboxColumnData {
  id: number;
  name: string;
  createdAt: LocalDateTime;
  actions: ListButton<IToolboxColumnData>[];
}

