<app-modal-default
    [translatePrefix]="translatePrefix"
    (modalAccepted)="onButtonClicked($event)"
    [confirmEnabled]="valid">
  <div class="mb-3" [innerHTML]="translatePrefix + '.text' | translate"></div>
  <span><strong>{{ translatePrefix + '.span' | translate }}</strong></span>
  <app-input-field
    [required]="true"
    [errorText]="'nawForm.email.errorText'"
    [placeholder]="translatePrefix + '.placeholder'"
    [validateOnChange]="true"
    [pattern]="regexEmail"
    (validChange)="setValid($event)"
    [(data)]="newEmail">
  </app-input-field>
</app-modal-default>

