import {LoginStateService} from '../../login/loginstate.service';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, retry} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {PermissionsService} from '../../permissions/permissions.service';

enum ResponseStatus {
  Unauthorized = 401,
  Forbidden = 403
}

/**
 * Interceptor to append authorisation headers to every request
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private numberOfRetries: number = 0;

  constructor(private loginStateService: LoginStateService,
              private permissionsService: PermissionsService,
              private router: Router) {
  }

  private handleError<T>(router: Router, loginStateService: LoginStateService, numberOfRetries: number):
    (error: HttpErrorResponse, caught: Observable<T>) => Observable<HttpErrorResponse> {
      return (error: HttpErrorResponse, caught: Observable<T>) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error(`An error occurred after ${numberOfRetries} retries:`, error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          if (error.status === ResponseStatus.Unauthorized) {
            // Set current user and token to null
            loginStateService.currentUser$.next(null);
            loginStateService.auth$.next(null);
            // Redirect to login
            router.navigate(['/login']);
          } else if (error.status === ResponseStatus.Forbidden) {
            this.permissionsService.modalService.showWarning({translatePrefix: 'permissions.restricted'});
          }
        }
        // return an ErrorObservable with a user-facing error message
        return throwError(error);
      };
  }

  private retryOrCatchErrorPipe$(response: Observable<HttpEvent<any>>): Observable<any> {
    return response.pipe(
      retry(this.numberOfRetries), // always retry if any error occurs...
      catchError(this.handleError(this.router, this.loginStateService, this.numberOfRetries)) // check if the authorisation succeeded
    );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Get the auth token from the service.
    const authToken = this.loginStateService.getFromLocalStorage();

    /**
     * Check if a token exists and if so, include in the header
     * If the token does not exist it should be a get token request
     */
    if (!!authToken && authToken.token) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', authToken.token)
      });
      return this.retryOrCatchErrorPipe$(next.handle(authReq));
    } else {
      // return unedited request
      return this.retryOrCatchErrorPipe$(next.handle(req));
    }
  }
}
