import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DocumentService} from '../../services/document/document.service';
import {MsgraphService} from '../../services/msgraph/msgraph.service';
import {ModalService} from '../../services/modal/modal.service';
import {CustomSnackbarService} from '../../services/snackbar/snackbar.service';
import {DriveFileTypes, globalFileTypes} from '../../domain/drive-item-ids';
import {filter, switchMap} from 'rxjs/operators';
import {FileService, FileServiceType} from '../../services/file.service';
import {ProductService} from '../../services/product/product.service';
import {ServiceService} from '../../services/service/service.service';
import {HistoryItemService} from '../../services/history-item/history-item.service';
import {ServiceListTypes} from '../../domain/service/service-data';

@Component({
  selector: 'app-open-file',
  templateUrl: './open-file.component.html'
})
export class OpenFileComponent implements OnInit {
  public fetchingUrl: boolean = false;
  public showError: boolean = false;
  public driveFileTypes: DriveFileTypes[] = globalFileTypes;
  public DriveFileTypes: typeof DriveFileTypes = DriveFileTypes;

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private productService: ProductService,
    private serviceService: ServiceService,
    private historyItemService: HistoryItemService,
    private msGraphService: MsgraphService,
    private snackBarService: CustomSnackbarService,
    private _modalService: ModalService
  ) {}

  ngOnInit(): void {
    const serviceType = this.route.snapshot.params['serviceType'];
    const dft = this.route.snapshot.params['driveFileType'];
    const itemId = this.route.snapshot.params['id'];
    let service: FileService;

    switch (serviceType) {
      case FileServiceType.Document:
        service = this.documentService;
        break;
      case FileServiceType.Service:
        service = this.serviceService;
        break;
      case FileServiceType.Product:
        service = this.productService;
        break;
      case FileServiceType.HistoryItem:
        service = this.historyItemService;
        break;
      default: // nothing
    }

    this.getTemplate(dft, itemId, service);
  }

  /**
   * Handles response of getting file url
   * If file exists, redirects to file url
   * If file doesn't exist in OneDrive, fires callback function to open modal to instantiate file
   * If there is another error, fires callback function to show error
   */
  getTemplate(driveFileType: DriveFileTypes, id: number, service: FileService): void {
    this.fetchingUrl = true;
    this.showError = false;
    service.getFileUrl$(driveFileType, id).subscribe((resp) => {
      this.fetchingUrl = false;
      if (!resp[0]) {
        if (resp[2] === 'ITEM_NOT_FOUND') {
          this._modalService.showFileWarning().pipe(
            filter((result) => result),
            switchMap(() => {
              this.fetchingUrl = true;
              return service.instantiateFile$(driveFileType, id);
            }),
          ).subscribe((res) => {
            this.fetchingUrl = false;
            if (!res[0]) {
              this.showError = true;
            } else {
              window.location.href = res[1];
            }
          });
        } else {
          this.showError = true;
        }
      } else {
        window.location.href = resp[1];
      }
    });
  }
}
