import { BrowserModule } from '@angular/platform-browser';
import {ApplicationRef, DoBootstrap, ErrorHandler, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {HttpClientModule, HttpClient, HttpBackend} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from 'app/app.component';
import {AppRoutingModule} from 'app/app.routing';
import {httpInterceptorProviders} from './services/http/index';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {SharedModule} from 'app/shared/shared.module';
import {LayoutModule} from 'app/layouts/layout.module';
import * as Sentry from '@sentry/browser';
import * as env from '../environments/environment';
import {SentryErrorHandler} from './errors/sentry';
import {environment} from '../environments/environment';
import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';

// AoT requires an exported function for factories
export function createTranslateLoader(httpBackend: HttpBackend): MultiTranslateHttpLoader {
  // load translation file from back-end, and also a small translation from the front-end in case there is no back-end connection
  return new MultiTranslateHttpLoader(httpBackend, [
    {prefix: environment.assetsFolder + '/i18n/', suffix: '.json'},
    // add time postfix to prevent using cache
    {prefix: `${environment.backendUrl}${environment.apiVersion}/publicAssets/`, suffix: `.json&time=${new Date().valueOf()}`}
  ]);
}

registerLocaleData(localeNl, 'nl');

Sentry.init({
  dsn: env.environment.sentryDSN,
  release: 'powerengine@' + env.environment.versions.version
});

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        SharedModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpBackend]
            }
        }),
        LayoutModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        httpInterceptorProviders,
        { provide: ErrorHandler, useClass: SentryErrorHandler }
    ]
})
export class AppModule implements DoBootstrap {
  private app: ApplicationRef;

  /**
   * Use the bootstrap hook to perform the chcp update
   *
   * @param app
   */
  ngDoBootstrap(app: ApplicationRef): void {
    this.app = app;
    let counter: number = 0;
    const cycletime: number = 200; // ms
    const safetytime: number = 10; // min

    // Poll the chcpFinished flag to check if angular can be started
    const timer = setInterval( () => {
      const chcpFinished = window['chcpFinishedFlag'];

      if ( chcpFinished === undefined || chcpFinished || counter > (safetytime * 60 * 1000 / cycletime) ) {
        this.startAngular();
        clearInterval(timer);
      }

      counter++;
    }, cycletime);
  }

  /**
   * Bootstrap Angular to make it start
   */
  private startAngular(): void {
    this.app.bootstrap(AppComponent);
  }
}
