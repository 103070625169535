import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {InputComponent} from './input.component';

@Component({
  selector: 'app-input-field',
  templateUrl: 'input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent extends InputComponent<any> implements OnInit {

  @ViewChild('input', { static: false }) inputRef: ElementRef;

  @Input() type: string = 'text';
  @Input() minlength: number = 1;
  @Input() maxlength: number = 255;
  @Input() minValue: number = Number.MIN_SAFE_INTEGER;
  @Input() maxValue: number = Number.MAX_SAFE_INTEGER;
  @Input() pattern: RegExp;
  @Input() validateOnChange: boolean = false;
  @Input() placeholder: string = '';
  @Input() cssClass: string;

  public isPassword: boolean = false;
  public showPassword: boolean = false;

  ngOnInit(): void {
    this.isPassword = this.type === 'password';
    this.dataEmpty = this.checkEmptyData();
    // custom validation because component has not loaded yet
    const valid = (!this.required && this.dataEmpty) ||
      ((!this.required || !this.dataEmpty) &&
        (!this.pattern || this.pattern.test(this.data)) &&
        (this.data.length >= this.minlength && this.data.length <= this.maxlength));
    this.validChanged(valid);
  }

  checkEmptyData(): boolean {
    return !(!!this.data || this.data === 0);
  }

  onKeyUp(): void {
    if (this.validateOnChange) {
      this.validChanged(this.inputRef.nativeElement.classList.contains('ng-valid'));
      this.dataChange.emit(this.data);
    }
  }

  onBlur(): void {
    this.dataChange.emit(this.data);
    // check validation
    if (!this.validateOnChange) {
      this.validChanged(this.inputRef.nativeElement.classList.contains('ng-valid'));
    }
    this.focusChanged(false);
  }
}
