import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonType} from '../../domain/button/button-type';
import {take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-modal-default',
  templateUrl: './modal-default.component.html',
  styleUrls: ['./modal-default.component.scss']
})
export class ModalDefaultComponent implements OnInit {
  public _translatePrefix: string;

  @Input() set translatePrefix(value: string) {
    this._translatePrefix = value;
    this.translateService.get(`${value}.cancelCaption`)
      .pipe(take(1)).subscribe((t) => this.cancelBtn = t !== `${value}.cancelCaption`);
  }

  @Input() public customTitle: boolean = false;
  @Input() public typeOfButton: ButtonType = ButtonType.BtnPrimary;
  @Input() public confirmEnabled: boolean = true;
  @Input() public confirmVisible: boolean = true;
  @Input() public canClose: boolean = true;
  @Input() public mustAnswer: boolean = false;
  @Output() public modalAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();

  public cancelBtn: boolean;

  constructor(private translateService: TranslateService) {}

  // Somehow needed...
  ngOnInit(): void { }

  buttonClicked($event: boolean): void {
    this.modalAccepted.emit($event);
  }
}
