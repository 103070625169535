import {Injectable, Inject} from '@angular/core';
import {SettingsService} from 'app/services/settings/settings.service';

import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {Theme} from 'app/domain/theme';
import {ISettings} from '../settings/settings.interface';

/**
 * Created by basbreijer on 05/04/2017.
 */

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private currentTheme$: Subject<Theme>;

  constructor(@Inject(SettingsService) private settingsService: ISettings) {
    const themes = settingsService.getSetting('themes');
    if (themes.length ) {
      this.currentTheme$ = new BehaviorSubject(settingsService.getSetting('themes')[0]);
    }
  }

  /**
   * Returns the theme observable.
   *
   * @returns
   */
  getTheme$(): Observable<Theme> {
    return this.currentTheme$.asObservable();
  }

  /**
   * Switch theme to the given theme id
   *
   * @param themeId
   */
  switchTheme(themeId: number): void {
    const theme = this.settingsService.getSetting('themes')
      .find((item: any) => item.id === themeId);

    if ( theme ) {
      this.currentTheme$.next(theme);
    }
  }
}
