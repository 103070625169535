<app-modal-default
  [customTitle]="true"
  [translatePrefix]="translatePrefix"
  (modalAccepted)="onButtonClicked($event)">
  <h4>{{ translatePrefix + '.title' | translate: {idType: ( 'identity.type.' + idType | translate ) } }}</h4>
  <div class="modal-body">
    <p>{{ translatePrefix + '.message' | translate: {idType: ( 'identity.type.' + idType | translate ) } }}</p>
  </div>
</app-modal-default>

