import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ThemeService} from 'app/services/core/theme.service';

import {Observable} from 'rxjs';
import {SettingsService} from 'app/services/settings/settings.service';
import {TranslatorService} from './services/translator/translator.service';
import {map} from 'rxjs/operators';
import {NgSelectConfig} from '@ng-select/ng-select';
import {TranslateService} from '@ngx-translate/core';
import {VersionCheckService} from './services/version-check.service';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  public currentThemeClazz$: Observable<string>;

  constructor(themeService: ThemeService,
              settingsService: SettingsService,
              translatorService: TranslatorService,  // Initializes the TranslatorService
              private translateService: TranslateService,
              private ngSelectConfig: NgSelectConfig,
              private versionCheckService: VersionCheckService
  ) {
    this.currentThemeClazz$ = themeService.getTheme$().pipe(
      map( (x) => settingsService.getSetting('themesClass') + x.id)
    );

    translateService.get('dropdown.noResults').subscribe((text) => ngSelectConfig.notFoundText = text);
  }

  ngOnInit(): void {
    this.versionCheckService.initVersionCheck(environment.versionCheckUrl);
  }
}
