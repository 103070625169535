import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor() {}

  public redirectUrl: string = '/';
}
