import {Component} from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { regexEmail } from 'app/domain/regex';

@Component({
  selector: 'app-change-email-modal',
  templateUrl: './change-email-modal.component.html'
})
export class ChangeEmailModalComponent {
  public translatePrefix: string = '';
  public regexEmail: RegExp = regexEmail;
  public newEmail: string;
  public onClose: Subject<string> = new Subject();
  public valid: boolean = false;

  constructor(private _bsModalRef: BsModalRef) {}

  setValid(b: boolean): void {
    this.valid = b;
  }

  onButtonClicked(accepted: boolean): void {
    if (accepted) {
      this.onClose.next(this.newEmail);
    } else {
      this.onClose.next(null);
    }
    this._bsModalRef.hide();
  }
}
