import {JsonConverter, JsonElementType, JsonProperty} from 'ta-json';
import {DateInterpreter, UTCInterpreter} from '../utcinterpreter';
import {ActionDeadlines} from '../engine/action';
import {LocalDate} from '@js-joda/core';

export enum ReportStatus {
  ReportOpen = 'reportOpen',
  ReportClosed = 'reportClosed'
}

export class Report {
  @JsonProperty() postfix?: string;
  @JsonProperty() offsetId: string;
  @JsonProperty() opstellenDeadlines: ActionDeadlines = new ActionDeadlines();
  @JsonProperty() verzendDeadlines: ActionDeadlines = new ActionDeadlines();
  @JsonProperty() includeVoorleggenAction: boolean;
  @JsonProperty() includeBewakenAction: boolean;
  @JsonProperty() status: ReportStatus;
  @JsonProperty() isEnd: boolean;

  hasSameDeadlines(): boolean {
    return this.opstellenDeadlines.redDeadline.toEpochDay() === this.verzendDeadlines.redDeadline.toEpochDay();
  }
}

export class TemporisationDeadlines {
  @JsonProperty() @JsonConverter(DateInterpreter) startDate: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) inventorisationDate: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) endDate: LocalDate;
}

export class ReportTemporisationData {
  @JsonProperty() @JsonElementType(Report) reports: Report[];
  @JsonProperty() @JsonElementType(TemporisationDeadlines) temporisationDeadlines?: TemporisationDeadlines[];
}


