<div class="d-flex contact" [ngClass]="'flex-' + (isColumn ? 'column' : 'row')">
  <button class="btn-contact phone mb-1">
    <ion-icon name="call" class="me-1"></ion-icon>
    <div class="d-none d-sm-inline">
      {{ phoneNo | translate }}
    </div>
  </button>
  <a class="btn-contact mail mb-1" [ngClass]="{'me-0': !isColumn}" href="{{'mailto:' + mailTo}}">
    <ion-icon name="mail" class="me-1"></ion-icon>
    <div class="d-none d-sm-inline">
      {{ mailToLabel | translate }}
    </div>
  </a>
</div>
