import {Pipe, PipeTransform} from '@angular/core';
import {Genders, Identity, Naw} from '../domain/identity/identitydata';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'nawName'
})
export class NawNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transformNaw(value: Naw, args?: any): string {
    return this.transform(value as {
      lastName: string;
      lastNameInfix: string;
      email: string;
      firstName: string;
      initials: string;
      gender: Genders;
    }, args);
  }

  transform(value: {
    lastName: string;
    lastNameInfix: string;
    email: string;
    firstName: string;
    initials: string;
    gender: Genders;
  }, args?: any): string {
    if (!value) {
      return '';
    }

    let firstName: string = '';
    let lastName: string = '';
    if (value.lastName) {
      lastName = value.lastNameInfix ? `${value.lastNameInfix} ${value.lastName}` : value.lastName;
    }

    const emailFallback = !!args && args.emailFallback;
    const isInternal: boolean = !!args && args.isInternal;
    const useAlternateNameFormat: boolean = !!args && args.useAlternateNameFormat;

    if (!lastName && emailFallback) {
      // put zero-width whitespace after @, so it is wrapped here
      return value.email.replace('@', '@\u200B');
    }

    if (isInternal) {
      if (value.firstName) {
        firstName = value.firstName;
      } else if (value.initials) {
        firstName = value.initials;
      }
      return `${firstName} ${lastName}`;
    } else {
      const genderPrefix: string = useAlternateNameFormat ? 'alt' : 'main';
      let gender: string;
      if (value.gender) {
        gender = this.translateService.instant(`gender.${genderPrefix}.${value.gender}`);
      } else {
        gender = this.translateService.instant(`gender.${genderPrefix}.unknown`);
      }

      if (value.initials) {
        firstName = value.initials;
      } else if (value.firstName) {
        firstName = value.firstName;
      }

      const withGender: boolean = !args || !args.hasOwnProperty('withGender') || args.withGender;
      const withFirstName: boolean = !args || !args.hasOwnProperty('withFirstName') || args.withFirstName;

      if (useAlternateNameFormat) {
        const separator: string = !!lastName && !!value.initials ? ', ' : '';
        return `${lastName}${separator}${value.initials ? value.initials : ''} ${gender}`;
      } else {
        return `${withGender ? gender + ' ' : ''}${withFirstName ? firstName + ' ' : ''}${lastName}`;
      }
    }
  }
}
