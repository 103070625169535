import {JsonConverter, JsonElementType, JsonObject, JsonProperty} from 'ta-json';
import {Identity, Naw} from '../identity/identitydata';
import {BillingInfo, Product} from '../product/product-data';
import {IdentityTypes} from '../identity/identitytypes';
import {DateInterpreter, UTCInterpreter} from '../utcinterpreter';
import {ListButton} from '../list/list-data';
import {
  DetailedQuotationStatusCodes,
  PublicQuotationStatusCodes,
  ServiceStatusCodes,
  ServiceStatus,
  courseDone
} from './service-status';
import {DbId} from '../dbid';
import {Asset} from '../assets';
import {DriveFileTypes, DriveItemIds} from '../drive-item-ids';
import {Toolbox} from '../toolbox/product-data';
import {TemporisationData} from './temporisation-data';
import {IdentityDetails} from '../identity/identityTableData';
import {LocalDate, LocalDateTime} from '@js-joda/core';

/**
 * Enum for course action codes
 */
export enum CourseActionCodes {
  ControleerPlanningWisselDienst = 'controleer_planning_wissel_dienst',
  PlanDienstverlening = 'plan_dienstverlening',
  OpdrachtgeverBellen = 'opdrachtgever_bellen',
  ClientBellenOptions = 'client_bellen_metOpties',
  ResultaatBegeleiding = 'resultaat_begeleiding',
  CheckVoortgang = 'check_voortgang',
  CheckWerkzaamheden = 'check_werkzaamheden',
  KoppelTrajectassistent = 'koppel_trajectassistent',
  KoppelCoach = 'koppel_coach',
  RapportageVerzenden = 'rapportage_verzenden',
  RapportageOpstellen = 'rapportage_opstellen',
  RapportageNakijken = 'rapportage_nakijken',
  RapportageBellen = 'rapportage_bellen',
  Sessie = 'session',
  TemporiseerBegeleiding = 'temporiseer_begeleiding',
  HerstartBegeleiding = 'herstart_begeleiding',
  TevredenheidsmetingSturen = 'send_satisfaction_survey',
  WiaUitslagOpvragen = 'wia_uitslag_opvragen'
}

// simple function to get the type of code an action belongs to.
// i.e. rapportage_verzenden_2 has type "RapportageVerzenden".
export function getCourseActionCode(actionCode: string): CourseActionCodes {
  return Object.keys(CourseActionCodes)
    .map((k) => CourseActionCodes[k])
    .find((c) => actionCode.startsWith(c)) as CourseActionCodes;
}

export enum QuotationCancelReason {
  VerkeerdeDienst = 'verkeerde_dienst',
  Uitval = 'uitval',
  ZonderOfferte = 'zonder_offerte',
}

export enum QuotationRejectReason {
  NietMeerNodig = 'niet_meer_nodig',
  Prijs = 'prijs',
  AnderBureau = 'ander_bureau'
}

export enum SessionRealisation {
  Persoonlijk = 'persoonlijk',
  TelefonischBeeldbellen = 'telefonisch_beeldbellen',
  Huisbezoek = 'huisbezoek',
  Teams = 'teams',
  NoShow = 'no_show',
  AfgezegdCoach = 'afgezegd_coach',
  AfgezegdClient = 'afgezegd_client',
  Vervallen = 'vervallen'
}

export enum SessionPlanning {
  Original = 'original',
  Once = 'once',
  Twice = 'twice'
}

export enum SessionStatus {
  Lopend = 'lopend',
  Gereed = 'gereed',
  NogDoen = 'nog_doen',
  Gesloten = 'gesloten'
}


@JsonObject()
export class FileReadyStates {
  @JsonProperty() public reporting?: boolean;
  @JsonProperty() public reportingSending?: boolean;
  @JsonProperty() public intake?: boolean;
  @JsonProperty() public intakeSending?: boolean;
  @JsonProperty() public quotation?: boolean;
  @JsonProperty() public quotationSending?: boolean;
  @JsonProperty() public startCoachingMail?: boolean;
  @JsonProperty() public startCoachingMailSending?: boolean;
  @JsonProperty() public startResearchMail?: boolean;
  @JsonProperty() public startResearchMailSending?: boolean;
}

@JsonObject()
export class QuotationAdditionalDetails {
  @JsonProperty() public lastEmployer?: string = '';
  @JsonProperty() public locationLastEmployer?: string = '';
  @JsonProperty() @JsonConverter(DateInterpreter) public endWaitingTime?: LocalDate = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public endDateLastContract?: LocalDate = null;
  @JsonProperty() public benefitType?: string = '';
  @JsonProperty() @JsonConverter(DateInterpreter) public endDateBenefit?: LocalDate = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public dateResignation?: LocalDate = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public dateEzwb?: LocalDate = null;
  @JsonProperty() public additionalNotes: string = '';
  @JsonProperty() public clientAdditionalInfo?: string;
  @JsonProperty() public budget?: string = '';
}

@JsonObject()
export class QuotationDates {
  @JsonProperty() @JsonConverter(DateInterpreter) public entry?: LocalDate = null;
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt?: LocalDateTime = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public startPlanOn?: LocalDate = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public sentOn?: LocalDateTime = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public acceptedRejectedOn?: LocalDateTime = null;
}

export enum ContactTypes {
  RequesterContact = 'requesterContact',
  Approve = 'approve',
  Receive = 'receive',
  ReceiveInvoice = 'receiveInvoice'
}

@JsonObject()
export class QuotationContact {
  @JsonProperty() public id: DbId;
  @JsonProperty() public quotation: number;
  @JsonProperty() public email: string;
  @JsonProperty() public contactType: ContactTypes;
  @JsonProperty() public nawId?: DbId = null;

  static from(quotation: number, email: string, contactType: ContactTypes, nawId: DbId, id?: number): QuotationContact {
    const quotationContact = new QuotationContact();
    quotationContact.id = DbId.from(id);
    quotationContact.quotation = quotation;
    quotationContact.email = email;
    quotationContact.contactType = contactType;
    quotationContact.nawId = nawId;
    return quotationContact;
  }
}

@JsonObject()
export class ApproveOrRejectData {
  @JsonProperty() public approvedOrRejectedBy?: Naw = null;
  @JsonProperty() public approveOrRejectId?: string = '';
  @JsonProperty() @JsonElementType(QuotationContact)
  public approveOrRejectContact: QuotationContact;
  @JsonProperty() public autoApprove: boolean;
}

@JsonObject()
export class Quotation {
  @JsonProperty() public quotationType: IdentityTypes;
  @JsonProperty() @JsonElementType(QuotationDates)
  public dates: QuotationDates = new QuotationDates();
  @JsonProperty() public processId: DbId;
  @JsonProperty() @JsonElementType(QuotationAdditionalDetails)
  public additionalDetails: QuotationAdditionalDetails = new QuotationAdditionalDetails();
  @JsonProperty() public intake?: boolean;
  @JsonProperty() @JsonElementType(Asset) public assets: Asset[];
  @JsonProperty() public approveOrRejectData: ApproveOrRejectData;
}

@JsonObject()
export class CourseDates {
  @JsonProperty() @JsonConverter(DateInterpreter) public startDate: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) public endDate: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) public endDateTreatment: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) public stoppedDate?: LocalDate;
}

@JsonObject()
export class Session {
  @JsonProperty() id: DbId;
  @JsonProperty() sessionNumber: number;
  @JsonProperty() flowNumber: number;
  @JsonProperty() status: SessionStatus;
  @JsonProperty() serviceId: number;
  @JsonProperty() theme: string;
  @JsonProperty() agenda: string;
  @JsonProperty() toolbox: Toolbox;
  @JsonProperty() secondToolbox?: Toolbox;
  @JsonProperty() previousToolbox?: Toolbox;
  @JsonProperty() previousSecToolbox?: Toolbox;
  @JsonProperty() notes?: string;
  @JsonProperty() sendHomework: boolean = true;
  @JsonProperty() reasonHomeworkNotSent?: string;
  @JsonProperty() realisation?: string;
  @JsonProperty() @JsonConverter(DateInterpreter) endDate?: LocalDate;
  @JsonProperty() @JsonConverter(UTCInterpreter) updatedAt?: LocalDateTime = null;
  @JsonProperty() version: number;

  public strip(): Session {
    const copy = Object.assign(new Session(), this);
    copy.agenda = '';
    copy.notes = '';
    return copy;
  }
}

@JsonObject()
export class SkipData {
  @JsonProperty() public startSessionNr?: number;
  @JsonProperty() public startWeekNr?: number;
}

@JsonObject()
export class SwapData {
  @JsonProperty() public swappedFrom?: number;
  @JsonProperty() public swappedReasonForOldService?: string;
}

@JsonObject()
export class CourseResult {
  @JsonProperty() public result?: string;
  @JsonProperty() public clarification?: string;
}

export enum CourseActivityValues {
  EigenWerk = 'eigen_werk',
  WerkzaamhedenIntern = 'werkzaamheden_intern',
  WerkzaamhedenExterne = 'werkzaamheden_extern',
  CombinatieInternExtern = 'combinatie_intern/extern',
  ClientWerktNiet = 'client_werkt_niet',
  NietVanToepassing = 'niet_van_toepassing'
}

@JsonObject()
export class CourseActivity {
  @JsonProperty() public activity?: CourseActivityValues;
  @JsonProperty() public clarification?: string;
}

@JsonObject()
export class Course {
  @JsonProperty() public processId?: DbId;
  @JsonProperty() public courseDates: CourseDates;
  @JsonProperty() public courseFlow: string;
  @JsonProperty() public stoppedReason: string;
  @JsonProperty() public result: CourseResult;
  @JsonProperty() @JsonElementType(Session) public sessions?: Session[];
  @JsonProperty() public report: Asset;
  @JsonProperty() public satisfactionSurveyId: string;
  @JsonProperty() @JsonElementType(TemporisationData) temporisationDatas: TemporisationData[];
  @JsonProperty() public startToolbox?: Toolbox;

  @JsonProperty() public activity: CourseActivity;

  public strip(): Course {
    const copy = Object.assign(new Course(), this);
    copy.sessions = copy.sessions?.map(s =>
      s.strip()
    );
    return copy;
  }
}

export enum QuotationFlowType {
  QuotationWithApproval = 'quotationWithApproval',
  QuotationWithoutApproval = 'quotationWithoutApproval', // deprecated
  NoQuotation = 'noQuotation'
}

@JsonObject()
export class ServiceIdentities {
  @JsonProperty() public owner: Identity = null;
  @JsonProperty() public referrer?: Identity = null;
  @JsonProperty() public company?: Identity = null;
  @JsonProperty() public client?: Identity = null;
  @JsonProperty() public coach?: Identity = null;
  @JsonProperty() public trajectAssistent?: Identity = null;
  @JsonProperty() public noEmployer: boolean = false;

  public strip(): ServiceIdentities {
    const copy = Object.assign(new ServiceIdentities(), this);
    copy.owner = copy.owner?.strip();
    copy.referrer = copy.referrer?.strip();
    copy.company = copy.company?.strip();
    copy.client = copy.client?.strip();
    copy.coach = copy.coach?.strip();
    copy.trajectAssistent = copy.trajectAssistent?.strip();
    return copy;
  }
}

@JsonObject()
export class Service {
  @JsonProperty() public id: DbId;
  @JsonProperty() public serviceIdentities: ServiceIdentities;
  @JsonProperty() public product?: Product = null;
  @JsonProperty() public serviceName?: string = null;
  @JsonProperty() public status: ServiceStatus;
  @JsonProperty() public locationCoach: string = '';
  @JsonProperty() public rating?: number = null;
  @JsonProperty() public billingDetails: string = '';
  @JsonProperty() public billingInfo: BillingInfo = null;
  @JsonProperty() public driveItemIds: DriveItemIds;
  @JsonProperty() public fileReadyStates: FileReadyStates;
  @JsonProperty() public course?: Course = null;
  @JsonProperty() public quotation: Quotation;
  @JsonProperty() public quotationFlowType: QuotationFlowType = QuotationFlowType.QuotationWithApproval;
  @JsonProperty() public extensionService?: number;
  @JsonProperty() public extendedService?: number;
  @JsonProperty() public swapData?: SwapData;
  @JsonProperty() public skipData?: SkipData;
  @JsonProperty() public strips?: number;
  @JsonProperty() public plannerInfo?: string;
  @JsonProperty() @JsonConverter(UTCInterpreter) archivedOn?: LocalDateTime;
  @JsonProperty() public version: number;

  public strip(): Service {
    const copy = Object.assign(new Service(), this);
    copy.serviceIdentities = copy.serviceIdentities.strip();
    copy.course = copy.course?.strip();
    return copy;
  }
}

@JsonObject()
export class ServiceWithMetadata {
  @JsonProperty()
  public service: Service;

  @JsonProperty()
  @JsonElementType(QuotationContact)
  public quotationContacts: QuotationContact[];

  @JsonProperty()
  public isFirst: boolean;
}

@JsonObject()
export class ServiceTableData {
  @JsonProperty() public id: DbId;
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt?: LocalDateTime = null;
  @JsonProperty() public status: ServiceStatus;
  @JsonProperty() public serviceName?: string = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public entryDate?: LocalDate = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public courseStartDate?: LocalDate = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public courseEndDate?: LocalDate = null;
  @JsonProperty() @JsonConverter(DateInterpreter) public courseEndDateTreatment?: LocalDate = null;
  @JsonProperty() public approveContactEmail: string;
  @JsonProperty() public report?: Asset;
  @JsonProperty() clientIdentity: IdentityDetails = null;
  @JsonProperty() employerIdentity: IdentityDetails = null;
  @JsonProperty() ownerIdentity: IdentityDetails = null;
  @JsonProperty() @JsonConverter(UTCInterpreter) archivedOn?: LocalDateTime;
  @JsonProperty() version: number;
}

export interface IServiceColumnData {
  id: DbId;
  client?: string;
  clientLastName?: string;
  clientId?: number;
  pdfTitle?: string;
  owner?: string;
  ownerId?: number;
  employerId?: number;
  company?: string;
  product?: string;
  entry?: LocalDate;
  start?: LocalDate;
  end?: LocalDate;
  originalStatus?: ServiceStatus;
  status: ServiceStatusCodes | DetailedQuotationStatusCodes | PublicQuotationStatusCodes;
  createdAt: LocalDateTime;
  actions: ListButton<IServiceColumnData>[];
  report?: ListButton<IServiceColumnData>[];
  quotation?: ListButton<IServiceColumnData>[];
  reportAsset?: Asset;
  approveContactEmail?: string;
  rowColor: string;
  showTooltip?: boolean;
  version: number;
}

export interface ISessionColumnData {
  id: number;
  sessionNumber: number;
  theme: string;
  originalStatus?: ServiceStatus;
  status: SessionStatus;
  toolbox: string;
  realisation: string;
  endDate: LocalDate;
}

export interface ISwapDataWithProduct {
  swapData: SwapData;
  skipData: SkipData;
  product: Product;
}

export enum ServiceListTypes {
  PendingList = 'PendingList',
  ListForIdentity = 'ListForIdentity'
}


