import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from '../settings/settings.service';
import {firstValueFrom, Observable, of} from 'rxjs';
import {User, UserTableData, UserWithPermissions} from '../../domain/userdata';
import {Identity} from '../../domain/identity/identitydata';
import {catchError, map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {ListSettings} from '../../components/list/list-settings';
import {getAutoFilterItems$, getCount$} from '../paginationHelper';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private httpService: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  getInternalUsers$(listSettings: ListSettings): Observable<UserTableData[]> {
    const limit: number = listSettings.paginationParams.limit;
    const offset: number = listSettings.paginationParams.offset;
    return this.httpService.post(
      this.settingsService.backendUrl(`/user/internal?limit=${limit}&offset=${offset}`), JSON.serialize(listSettings))
      .pipe(map((result) => JSON.deserialize<UserTableData[]>(result, UserTableData)));
  }

  getExternalUsers$(listSettings: ListSettings): Observable<UserTableData[]> {
    const limit: number = listSettings.paginationParams.limit;
    const offset: number = listSettings.paginationParams.offset;
    return this.httpService.post(
      this.settingsService.backendUrl(`/user/external?limit=${limit}&offset=${offset}`), JSON.serialize(listSettings))
      .pipe(map((result) => JSON.deserialize<UserTableData[]>(result, UserTableData)));
  }

  getInternalUsersCount$(listSettings: ListSettings): Observable<number> {
    return getCount$(listSettings, `/user/internal`, this.httpService, this.settingsService);
  }

  getExternalUsersCount$(listSettings: ListSettings): Observable<number> {
    return getCount$(listSettings, `/user/external`, this.httpService, this.settingsService);
  }

  getInternalUsersAutoFilter$(viewFieldId: string, sortFieldId: string): Observable<string[]> {
    return getAutoFilterItems$(`/user/internal`, viewFieldId, sortFieldId, this.httpService, this.settingsService);
  }

  getExternalUsersAutoFilter$(viewFieldId: string, sortFieldId: string): Observable<string[]> {
    return getAutoFilterItems$(`/user/external`, viewFieldId, sortFieldId, this.httpService, this.settingsService);
  }

  getByIdentityId$(identityId: number): Observable<User> {
    return this.httpService.get(this.settingsService.backendUrl(`/user/${identityId}`))
      .pipe(map((result) => JSON.deserialize<User>(result, User)));
  }

  userExistsForIdentity$(identityId: number): Observable<boolean> {
    return this.httpService.get(this.settingsService.backendUrl(`/user/${identityId}/exists`), {observe: 'response'})
      .pipe(map((result) => result.status === 200));
  }

  archive$(id: number): Observable<boolean> {
    return this.httpService.post(
      this.settingsService.backendUrl(`/user/${id}/archive`), {}, {observe: 'response'})
      .pipe(map((result) => JSON.deserialize<boolean>(result)));
  }

  unArchive$(id: number): Observable<boolean> {
    return this.httpService.post(
      this.settingsService.backendUrl(`/user/${id}/unArchive`), {}, {observe: 'response'})
      .pipe(map((result) => JSON.deserialize<boolean>(result)));
  }

  setFirstLogin$(): Observable<boolean> {
    return this.httpService.post(
      this.settingsService.backendUrl(`/user/setFirstLogin`), {}, {observe: 'response'})
      .pipe(map((result) => JSON.deserialize<boolean>(result)));
  }

  isFirstLogin$(): Observable<boolean> {
    return this.httpService.get(
      this.settingsService.backendUrl(`/user/isFirstLogin`))
      .pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
  }

  inviteUserFromIdentity$(userIdentity: Identity): Observable<boolean> {
    return this.httpService.post(
      this.settingsService.backendUrl(`/user/invite/fromIdentity`),
      JSON.serialize(userIdentity),
      {observe: 'response'}
    ).pipe(map((result) => result.status === 200));
  }

  public requestPwReset(email: string): Observable<boolean> {
    return this.httpService.post(
      this.settingsService.backendUrl('/user/requestPwReset'),
      email,
      {observe: 'response'}
    ).pipe(map((res) => res.ok), catchError(() => of(false)));
  }

  public changeEmail(identity: Identity): Observable<boolean> {
    return this.httpService.post(
      this.settingsService.backendUrl(`/user/changeEmail`),
      JSON.serialize(identity),
      {observe: 'response'}
    ).pipe(map((result) => result.status === 200));
  }

  public getCurrentUser(): Observable<UserWithPermissions> {
    return this.httpService.get(this.settingsService.backendUrl('/user/current'))
      .pipe(map((response) => JSON.deserialize<UserWithPermissions>(response, UserWithPermissions)));
  }
}
