import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {ModalService} from '../../modal/modal.service';

/**
 * Interceptor to check responses that content is too large
 */
@Injectable()
export class ContentTooLargeInterceptor implements HttpInterceptor {

  constructor(private modalService: ModalService) {
  }

  private showError(): void {
    this.modalService.showWarning({translatePrefix: 'contentTooLarge'});
  }

  private handleError<T>(): (error: HttpErrorResponse, caught: Observable<T>) => Observable<HttpErrorResponse> {
    return (error: HttpErrorResponse, caught: Observable<T>) => {
      // status = unknown error, most likely connection failure
      if (error.status === 413) {
        this.showError();
      }
      // return an ErrorObservable with a user-facing error message
      return throwError(error);
    };
  }

  private retryOrCatchErrorPipe$(response: Observable<HttpEvent<any>>): Observable<any> {
    return response.pipe(
      catchError(this.handleError())
    );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.retryOrCatchErrorPipe$(next.handle(req));
  }
}
