/**
 * Created by basbreijer on 27/03/2017.
 */
import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {SettingsService} from 'app/services/settings/settings.service';
import {LoginStateService} from './loginstate.service';
import {ILogin} from './login.interface';

import {AppAccessToken, UserWithPermissions} from 'app/domain/userdata';

import {firstValueFrom, Observable, of, Subject} from 'rxjs';
import {JSON} from 'ta-json';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {catchError, first, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {UserService} from '../userlist/user.service';
import {ModalService} from '../modal/modal.service';
import {ArchivedModalComponent} from '../../components/archived-modal/archived-modal.component';

@Injectable({
  providedIn: 'root',
})
export class LoginService implements ILogin, OnDestroy {

  private onDestroy$: Subject<void> = new Subject();

  constructor(private httpService: HttpClient,
              private router: Router,
              private settingsService: SettingsService,
              private loginStateService: LoginStateService,
              private userService: UserService,
              private modalService: ModalService) {

    this.setCurrentUser();
  }

  /**
   * Login the given user with password.
   *
   * @returns if full access is granted (both valid login, and user is external)
   * As side effect the new token is added to the login state service, and the current user info is retrieved
   */
  public login(email: string, password: string): Promise<boolean> {
    const params = {e: email, p: password, observe: 'response'};
    return firstValueFrom(this.httpService.post(this.settingsService.backendUrl('/auth/getToken'), params, {observe: 'response'})
        .pipe(
            map((response: HttpResponse<AppAccessToken>) => {
              if (response.body) {
                return JSON.deserialize<AppAccessToken>(response.body, AppAccessToken);
              } else {
                return null;
              }
            }),
            catchError(_ => Promise.reject('Not authorized'))
        )).then((token) => {
              if (token) {
                this.loginStateService.auth$.next(token);
                this.setCurrentUser();
                return token.mfaValid && !token.passwordExpired;
                // if the response is successful, but the token is empty, we are dealing with an archived user.
              } else {
                this.modalService.show(ArchivedModalComponent, {translatePrefix: 'login.archived'});
                return Promise.reject('Account archived');
              }
            }
        );
  }

  /**
   * Logout the current user.
   *
   * @returns access granted
   */
  public logout(): Promise<void> {
    return this.loginStateService.auth$.pipe(take(1)).toPromise().then((auth) => {
      if (auth) {
        return this.httpService.delete(`${this.settingsService.backendUrl('/auth/reset')}?token=${auth.token}`)
            .toPromise().then(() => {
              this.loginStateService.auth$.next(null);
              this.loginStateService.currentUser$.next(null);

              this.router.navigate(['/login']);
              return Promise.resolve();
            })
            .catch((error) => {
              console.error('Logout failed');
              this.loginStateService.auth$.next(null);
            });
      }

      return Promise.resolve();
    });
  }

  /**
   * Return the current user.
   *
   * @returns
   */
  getUser$(): Observable<UserWithPermissions> {
    return this.loginStateService.currentUser$;
  }

  /**
   * Validates the current token with the backend
   */
  public validate(): Observable<AppAccessToken> {
    // return an observable of boolean based on the currently active token
    return this.loginStateService.auth$.pipe(
        takeUntil(this.onDestroy$), take(1),
        switchMap((token) => {
          // not undefined or null
          if (token) {
            // query the backend whether the token is valid
            return this.httpService.get(`${this.settingsService.backendUrl('/auth/refresh')}?token=${token.token}`, {observe: 'response'})
                .pipe(
                    takeUntil(this.onDestroy$), take(1),
                    map((response) => {
                      // parse token from response json
                      const _token = JSON.deserialize<AppAccessToken>(response.body, AppAccessToken);

                      // Only set new auth value if token exists
                      this.loginStateService.auth$.next(!!_token && !!_token.token ? _token : null);

                      // if the responded token doesn't exist, return invalid
                      return _token;
                    })
                );
          } else {
            // invalid
            return of(null);
          }
        })
    );
  }

  public setCurrentUser(refetchUser: boolean = false): void {
    // obtain current user if we have a token but no user or no full first name of user.
    // do validate() call to refresh token (result is unused)
    combineLatest([this.validate(), this.loginStateService.auth$, this.loginStateService.currentUser$]).pipe(
        first(([_, t, user]) => !!t && !!t.token && (!user || refetchUser)))
        .subscribe(([isAuthenticated]) => {
          if (isAuthenticated) {
            this.userService.getCurrentUser().subscribe((user) => {
              this.loginStateService.currentUser$.next(user);
            });
          }
        });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
