/**
 * Crud service implementation for quotations
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
    IServiceColumnData,
    ISwapDataWithProduct,
    QuotationCancelReason,
    QuotationContact,
    Service,
    ServiceListTypes,
    ServiceTableData,
    ServiceWithMetadata,
    Session
} from '../../domain/service/service-data';
import {catchError, map} from 'rxjs/operators';
import {SettingsService} from '../settings/settings.service';
import {JSON, JsonConverter, JsonObject, JsonProperty} from 'ta-json';
import {Observable, of} from 'rxjs';
import {Identity, Naw} from '../../domain/identity/identitydata';
import {IdType} from '../crud/crud.interface';
import {IdentityNamePipe} from '../../pipes/identity-name.pipe';
import {TranslateService} from '@ngx-translate/core';
import {DbId} from '../../domain/dbid';
import {IdentityTypes} from '../../domain/identity/identitytypes';
import {IAssetWithData} from '../../domain/assets';
import {MsgraphService} from '../msgraph/msgraph.service';
import {FileService, FileServiceType} from '../file.service';
import {checkFileUploadError, getFormData} from '../formHelper';
import {ButtonEvent} from '../../domain/engine/action';
import {ButtonColorType, ListButton, rowColor} from '../../domain/list/list-data';
import {TemporisationData} from '../../domain/service/temporisation-data';
import {DateInterpreter, UTCInterpreter} from '../../domain/utcinterpreter';
import {DriveFileTypes} from '../../domain/drive-item-ids';
import {returnStatusOfUpdatedItem} from '../../domain/out-of-date-check';
import {NawWithAttributes} from './nawWithAttributes';
import {Report, ReportTemporisationData} from '../../domain/report-temporisation-data/report-temporisation-data';
import {ListSettings} from '../../components/list/list-settings';
import {getAutoFilterItems$, getCount$} from '../paginationHelper';
import {ServiceStatusCodes} from 'app/domain/service/service-status';
import {ModalService} from '../modal/modal.service';
import {LocalDate, LocalDateTime} from '@js-joda/core';
import {PermissionName} from '../../domain/userdata';

@JsonObject()
class ExtraReportData {
    @JsonProperty() @JsonConverter(DateInterpreter) yellowDeadline: LocalDate;
    @JsonProperty() @JsonConverter(DateInterpreter) redDeadline: LocalDate;
    @JsonProperty() postfix: string;
}

@JsonObject()
class EditReportData {
    @JsonProperty() @JsonConverter(DateInterpreter) yellowDeadline: LocalDate;
    @JsonProperty() @JsonConverter(DateInterpreter) redDeadline: LocalDate;
    @JsonProperty() offsetId: string;
    @JsonProperty() name: string;
}

@Injectable({
    providedIn: 'root',
})
export class ServiceService extends FileService {
    public serviceType: FileServiceType = FileServiceType.Service;

    constructor(public httpService: HttpClient,
                private settingsService: SettingsService,
                public translateService: TranslateService,
                public msGraphService: MsgraphService,
                private modalService: ModalService) {
        super(msGraphService, translateService);
    }

    createFromSlug$(productSlug: string): Observable<Service> {
        return this.httpService.post(this.settingsService.backendUrl(`/service/create/${productSlug}`), {})
            .pipe(map((result) => JSON.deserialize<Service>(result, Service)));
    }

    createFromSlugWithIdentityType$(productSlug: string, identityType: IdentityTypes): Observable<Service> {
        return this.httpService.post(this.settingsService.backendUrl(`/service/create/${productSlug}/${identityType}`), {})
            .pipe(map((result) => JSON.deserialize<Service>(result, Service)));
    }

    get$(id: number): Observable<Service> {
        return this.httpService.get(this.settingsService.backendUrl(`/service/${id}`))
            .pipe(map((result) => JSON.deserialize<Service>(result, Service)));
    }

    getByProcessId$(processId: number): Observable<ServiceWithMetadata> {
        return this.httpService.get(this.settingsService.backendUrl(`/service/fromProcessWithMetadata/${processId}`))
            .pipe(map((result) => JSON.deserialize<ServiceWithMetadata>(result, ServiceWithMetadata)));
    }

    /**
     * Get the service with some extra info (if this is the first service for this user) from the back-end
     */
    getWithMetadata$(id: number): Observable<ServiceWithMetadata> {
        return this.httpService.get(this.settingsService.backendUrl(`/service/${id}/withMetadata`))
            .pipe(map((result) => JSON.deserialize<ServiceWithMetadata>(result, ServiceWithMetadata)));
    }

    // Performs a call to the endpoint [[/service/list/:identityId/:showAll]]
    getAllFilteredByIdentity$(identityId: number, showAll: boolean, listSettings: ListSettings): Observable<ServiceTableData[]> {
        const limit: number = listSettings.paginationParams.limit;
        const offset: number = listSettings.paginationParams.offset;

        return this.httpService.post(
            this.settingsService.backendUrl(`/service/list/${identityId}/${showAll}?limit=${limit}&offset=${offset}`),
            JSON.serialize(listSettings)
        ).pipe(map((items) => JSON.deserialize<ServiceTableData[]>(items, ServiceTableData)));
    }

    // Performs a call to the endpoint [[/service/list/:identityId/:showAll/count]]
    getAllFilteredByIdentityCount$(identityId: number, showAll: boolean, listSettings: ListSettings): Observable<number> {
        return getCount$(listSettings, `/service/list/${identityId}/${showAll}`, this.httpService, this.settingsService);
    }

    // Performs a call to the endpoint [[/service/list/:identityId/:showAll/autoFilterItems/:fieldId]]
    getAllFilteredByIdentityAutoFilter$(identityId: number, viewFieldId: string, sortFieldId: string): Observable<string[]> {
        // We set 'showAll' parameter for backend always true for autofilter
        return getAutoFilterItems$(`/service/list/${identityId}/true`, viewFieldId, sortFieldId, this.httpService, this.settingsService);
    }

    // Performs a call to the endpoint [[/service/list/pending]]
    getPendingFiltered$(listSettings: ListSettings): Observable<ServiceTableData[]> {
        const limit: number = listSettings.paginationParams.limit;
        const offset: number = listSettings.paginationParams.offset;

        return this.httpService.post(
            this.settingsService.backendUrl(`/service/list/pending?limit=${limit}&offset=${offset}`),
            JSON.serialize(listSettings)
        ).pipe(map((items) => JSON.deserialize<ServiceTableData[]>(items, ServiceTableData)));
    }

    // Performs a call to the endpoint [[/service/list/pending/count]]
    getPendingFilteredCount$(listSettings: ListSettings): Observable<number> {
        return getCount$(listSettings, `/service/list/pending`, this.httpService, this.settingsService);
    }

    // Performs a call to the endpoint [[/service/list/pending/autoFilterItems/:fieldId
    getPendingFilteredAutoFilter$(viewFieldId: string, sortFieldId: string): Observable<string[]> {
        return getAutoFilterItems$(`/service/list/pending`, viewFieldId, sortFieldId, this.httpService, this.settingsService);
    }

    getReportTemporisationData(serviceId: number): Observable<ReportTemporisationData> {
        return this.httpService.get(this.settingsService.backendUrl(`/service/${serviceId}/getReportAndTemporisationData`))
            .pipe(map((items) => JSON.deserialize<ReportTemporisationData>(items, ReportTemporisationData)));
    }

    createReport$(report: Report, serviceId: number): Observable<boolean> {
        const data = Object.assign(new ExtraReportData(), {
            postfix: report.postfix,
            yellowDeadline: report.opstellenDeadlines.yellowDeadline,
            redDeadline: report.opstellenDeadlines.redDeadline
        });
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${serviceId}/addReportBlock`),
            JSON.serialize(data),
            {observe: 'response'}
        ).pipe(map((result) => result.status === 200));
    }

    updateReport$(report: Report, serviceId: number): Observable<boolean> {
        const data = Object.assign(new EditReportData(), {
            yellowDeadline: report.opstellenDeadlines.yellowDeadline,
            redDeadline: report.opstellenDeadlines.redDeadline,
            offsetId: report.offsetId,
            name: report.postfix
        });
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${serviceId}/editReportBlock`),
            JSON.serialize(data),
            {observe: 'response'}
        ).pipe(map((result) => result.status === 200));
    }

    update$(service: Service): Observable<{ success: boolean; outOfDate: boolean; body: number }> {
        const strippedService = service.strip();
        return this.httpService.put(
            this.settingsService.backendUrl(`/service`),
            JSON.serialize(strippedService),
            {observe: 'response'}
        ).pipe(
            catchError((e) => of(e)),
            map((result) => returnStatusOfUpdatedItem(result)));
    }

    updateWithAssets$(service: Service, assets: IAssetWithData[], isInternalUser: boolean):
        Observable<{ success: boolean; outOfDate: boolean; body: number }> {
        const strippedService = service.strip();
        return this.httpService.put(
            this.settingsService.backendUrl(`/service/withAssets`),
            getFormData(strippedService, assets),
            {observe: 'response'}
        ).pipe(
            checkFileUploadError(this.modalService, isInternalUser),
            catchError((e) => of(e)),
            map((result) => returnStatusOfUpdatedItem(result)));
    }

    setOrUpdate$(identity: Identity, serviceId: number, noEmployer: boolean): Observable<boolean> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${serviceId}/identity/${noEmployer}`),
            JSON.serialize(identity),
            {observe: 'response'}
        ).pipe(map((result) => result.status === 200));
    }

    setOrUpdateWithOwner$(identity: Identity, serviceId: number, noEmployer: boolean): Observable<boolean> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${serviceId}/identity/owner/${noEmployer}`),
            JSON.serialize(identity),
            {observe: 'response'}
        ).pipe(map((result) => result.status === 200));
    }

    /**
     * Create service contacts (for requester/receiving/approving service or invoice)
     */
    createQuotationContact$(serviceId: DbId, contacts: QuotationContact[]): Observable<boolean> {
        return this.httpService.post(this.settingsService.backendUrl(`/quotationContact/${serviceId.id}`), JSON.serialize(contacts))
            .pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
    }

    /**
     * Get service contacts (for receiving/approving service or invoice)
     */
    getContact$(serviceId: DbId): Observable<QuotationContact[]> {
        return this.httpService.get(this.settingsService.backendUrl(`/quotationContact/${serviceId.id}`))
            .pipe(map((result) => JSON.deserialize<QuotationContact[]>(result)));
    }

    accept$(serviceId: number, serviceVersion: number, naw: Naw, willBeNotified: boolean):
        Observable<{ success: boolean; outOfDate: boolean; body: LocalDateTime; isNotContactPerson: boolean}> {
        return this.httpService.post(this.settingsService.backendUrl(`/service/${serviceId}/accept`),
            ServiceService.serializeNawWithAttributes(naw, serviceVersion, willBeNotified),
            {observe: 'response'})
            .pipe(
                catchError((e) => of(e)),
                map((result) => returnStatusOfUpdatedItem(result)));
    }

    reject$(serviceId: number, serviceVersion: number, naw: Naw, reason: string):
        Observable<{ success: boolean; outOfDate: boolean; body: LocalDateTime; isNotContactPerson: boolean }> {
        return this.httpService.post(this.settingsService.backendUrl(`/service/${serviceId}/reject`),
            ServiceService.serializeNawWithAttributes(naw, serviceVersion, undefined, reason),
            {observe: 'response'})
            .pipe(
                catchError((e) => of(e)),
                map((result) => returnStatusOfUpdatedItem(result)));
    }

    checkForApproveId$(approveId: string): Observable<boolean> {
        return this.httpService.get(
            this.settingsService.backendUrl(`/service/${approveId}/checkForApproveId`))
            .pipe(map((result) => JSON.deserialize<boolean>(result)));
    }

    acceptByApproveId$(approveId: string, naw: Naw, willBeNotified: boolean):
      Observable<{success: boolean; outOfDate: boolean; body: any; isNotContactPerson: boolean}> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${approveId}/acceptByApproveId`),
            ServiceService.serializeNawWithAttributes(naw, undefined, willBeNotified),
            {observe: 'response'}
        ).pipe(
          catchError((e) => {
              if (e.status === 428) {
                  return of(e);
              } else {
                  throw e;
              }
          }),
          map((result) => returnStatusOfUpdatedItem(result))
        );
    }

    rejectByApproveId$(approveId: string, naw: Naw, reason: string): Observable<boolean> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${approveId}/rejectByApproveId`),
            ServiceService.serializeNawWithAttributes(naw, undefined, undefined, reason),
            {observe: 'response'}
        ).pipe(map((result) => result.ok));
    }

    close$(serviceId: number, reason?: QuotationCancelReason): Observable<boolean> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${serviceId}/cancel`),
            reason ? JSON.serialize(reason) : {},
        ).pipe(map((result) => JSON.deserialize<boolean>(result)));
    }

    stop$(serviceId: number, reason?: string): Observable<boolean> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${serviceId}/stop`),
            JSON.serialize(reason),
            {observe: 'response'}
        ).pipe(map((result) => result.ok));
    }

    stopCompletely$(serviceId: number, reason?: string): Observable<boolean> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${serviceId}/stopCompletely`),
            JSON.serialize(reason),
            {observe: 'response'}
        ).pipe(map((result) => result.ok));
    }

    pause$(
        serviceId: number,
        tempData: TemporisationData,
        actionId?: number,
        procId?: number,
        buttonId?: string
    ): Observable<{ ok: boolean; temporisationInProgress: boolean }> {
        const queryString = (actionId && procId && buttonId) ? `?actionId=${actionId}&procId=${procId}` : '';
        const event = (actionId && procId && buttonId) ? new ButtonEvent(buttonId, null) : undefined;

        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${serviceId}/pause` + queryString),
            JSON.serialize({temporisationData: tempData, buttonEvent: event}), {observe: 'response'}
        ).pipe(
            catchError((e) => of(e)),
            map((result) => (
                {ok: result.ok, temporisationInProgress: result.status === 405}
            )));
    }

    swap$(swapDataWithProduct: ISwapDataWithProduct): Observable<boolean> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/swap`),
            {
                swapData: JSON.serialize(swapDataWithProduct.swapData),
                skipData: JSON.serialize(swapDataWithProduct.skipData),
                product: JSON.serialize(swapDataWithProduct.product)
            },
            {observe: 'response'}
        ).pipe(map((result) => result.ok));
    }

    tickWorkflow$(processId: number): Observable<boolean> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/tickWorkflow/${processId}`), {}, {observe: 'response'}
        ).pipe(map((result) => result.ok));
    }

    getAllForServiceList(
        listType: ServiceListTypes,
        listSettings: ListSettings,
        identityId: number = null,
        showAll: boolean = false
    ): Observable<IServiceColumnData[]> {
        let services$: Observable<ServiceTableData[]>;

        switch (listType) {
            case ServiceListTypes.PendingList:
                services$ = this.getPendingFiltered$(listSettings);
                break;
            case ServiceListTypes.ListForIdentity:
                services$ = this.getAllFilteredByIdentity$(identityId, showAll, listSettings);
                break;
            default: // nothing
        }

        return services$.pipe(map((qs) => qs
            .map((q) => this.setServiceColumnData(q))
        ));
    }

    getAutoFiltersForServiceList$(identityId: number, viewFieldId: string, sortFieldId: string): Observable<string[]> {
        if (identityId) {
            return this.getAllFilteredByIdentityAutoFilter$(identityId, viewFieldId, sortFieldId);
        } else {
            return this.getPendingFilteredAutoFilter$(viewFieldId, sortFieldId);
        }
    }

    updateSession(session: Session): Observable<{ success: boolean; outOfDate: boolean; body: number }> {
        return this.httpService.put(
            this.settingsService.backendUrl(`/service/session`), JSON.serialize(session),
            {observe: 'response'}
        ).pipe(
            catchError((e) => of(e)),
            map((result) => returnStatusOfUpdatedItem(result))
        );
    }

    // disable naming convention, since otherwise lint will complain about 'Content-Type' key
    /* eslint-disable @typescript-eslint/naming-convention */
    updateAdditionalInfo(serviceId: number, additionalInfo: string): Observable<boolean> {
        return this.httpService.post(
            this.settingsService.backendUrl(`/service/${serviceId}/addAdditionalInfo`), additionalInfo, {
                headers: {
                    'Content-Type': 'text/plain; charset=utf-8'
                },
                observe: 'response'
            }
        ).pipe(map((result) => result.ok));

    }

    /* eslint-disable @typescript-eslint/naming-convention */

    documentIsSending$(driveFileType: DriveFileTypes, id: number): Observable<boolean> {
        return this.httpService.get(
            this.settingsService.backendUrl(`/service/${id}/documentIsSending/${driveFileType}`))
            .pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
    }

    private setServiceColumnData(s: ServiceTableData): IServiceColumnData {
        const identityNamePipe = new IdentityNamePipe(this.translateService);
        return {
            id: s.id,
            client: s.clientIdentity?.fullNames.alternate,
            clientLastName: s.clientIdentity?.naw?.lastName,
            // the clientId field is only used when s.serviceIdentities.status !== Open and there always is a client
            clientId: s.clientIdentity?.id.id,
            employerId: s.employerIdentity?.id.id,
            company: s.employerIdentity?.company?.name,
            pdfTitle: s.clientIdentity ?
                identityNamePipe.transformIdentityDetails(s.clientIdentity, {withFirstName: false}) : '',
            owner: s.ownerIdentity?.fullNames.companyAlternate,
            ownerId: s.ownerIdentity?.id.id,
            product: s.serviceName,
            entry: s.entryDate,
            start: s.courseStartDate,
            end: s.courseEndDateTreatment,
            originalStatus: s.status,
            status: s.status.detailedStatus,
            createdAt: s.createdAt,
            actions: [],
            reportAsset: s.report,
            approveContactEmail: s.approveContactEmail,
            rowColor: s.status.status === ServiceStatusCodes.CourseInProgress ||
            s.status.status === ServiceStatusCodes.CourseEnding || s.status.status === ServiceStatusCodes.CourseTemporized
                ? rowColor : undefined,
            showTooltip: s.status.status === ServiceStatusCodes.Gestopt && !s.entryDate,
            version: s.version
        };
    }

    private static serializeNawWithAttributes(
        naw: Naw, sv: number, willBeNotified?: boolean, reason?: string
    ): NawWithAttributes {
        return JSON.serialize(new NawWithAttributes(
                naw,
                reason,
                undefined,
                willBeNotified,
            sv ?? 1
            ));
    }
}
