import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Subject, Subscription, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { RequestLoadingType } from './requestLoadingType';

@Injectable({
  providedIn: 'root',
})
export class LoadingService implements OnDestroy {

  public isBusy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private busyRequests: Map<string, {count: number; timer: Subscription}> = new Map();

  private onDestroy$: Subject<void> = new Subject();

  toggleRequesting(loadingType: RequestLoadingType, url: string): void {
    const currentBusy = this.busyRequests.get(url);
    if (loadingType === RequestLoadingType.Sent) {
      let newCount: number = 1;
      if (currentBusy) {
        newCount = 1;
        // unsubscribe current timer, and start new one
        currentBusy.timer.unsubscribe();
      }
      // wait 30s for request to time out, in that case stop waiting for that request
      const subscription = timer(30000).pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(() => this.handleRequestTimeout(url));
      this.busyRequests.set(url, {count: newCount, timer: subscription});
    } else {
      if (!currentBusy) {
        // exclude auth/refresh route because this happens due to canActivate guard being triggered twice...
        if (loadingType !== RequestLoadingType.Finalized && !url.includes('/auth/refresh')) {
          // possibly due to timeout or same call done between response and finalize
          console.log(`Request ${url} no longer busy on completion`);
        } // otherwise finalize done after response received --> normal flow
      } else if (currentBusy.count === 1) {
        // last busy call, so remove from map
        this.busyRequests.delete(url);
        currentBusy.timer.unsubscribe();
      } else {
        this.busyRequests.set(url, {count: currentBusy.count - 1, timer: currentBusy.timer});
      }
    }

    this.isBusy$.next(this.busyRequests.size !== 0);
  }

  handleRequestTimeout(url: string): void {
    const currentBusy = this.busyRequests.get(url);
    if (!currentBusy) {
      // nothing for this url anymore, so ignore
      console.log(`Waiting for request ${url} to finished timed out, but nothing was busy anymore.`); // eslint-disable-line
    } else {
      // timeout expired, so stop waiting for all calls to that url
      console.log(`Waiting for request ${url} to finished timed out, now stopping to wait for it.`); // eslint-disable-line
      this.busyRequests.delete(url);
      this.isBusy$.next(this.busyRequests.size !== 0);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
