<app-approve-or-reject-data-form
    [readonly]="readonly"
    [formObject]="form"
    [showRequired]="showRequired && !readonly"
    [fields]="formFields"
    (formDataChange)="updateForm($event)"
    (formValidChange)="updateFormValid($event)">
</app-approve-or-reject-data-form>

<app-reject-reasons *ngIf="!isApprove"
    [required] = true
    [showRequired]="showRequired"
    (reasonChanged)="onReasonChanged($event)"
    (customReasonChanged)="onReasonChanged($event)">
</app-reject-reasons>

<ng-container *ngIf="isApprove">
  <div class="d-flex mt-4">
    <input id="privacy" type="checkbox" class="checkbox" [(ngModel)]="privacyChecked" (change)="checkValid()">
    <label for="privacy" [innerHTML]="'quotationApproveOrReject.approve.approvalData.privacyCheck.label' | translate"></label>
  </div>
  <div class="text-danger" [hidden]="!showRequired || privacyChecked">
    {{ 'quotationApproveOrReject.approve.approvalData.privacyCheck.errorText'  | translate }}
  </div>

  <div class="d-flex mt-4">
    <input id="conditions" type="checkbox" class="checkbox" [(ngModel)]="conditionsChecked" (change)="checkValid()">
    <label for="conditions" [innerHTML]="'quotationApproveOrReject.approve.approvalData.conditionsCheck.label' | translate"></label>
  </div>

  <div class="text-danger" [hidden]="!showRequired || conditionsChecked">
    {{ 'quotationApproveOrReject.approve.approvalData.conditionsCheck.errorText'  | translate }}
  </div>
</ng-container>

