import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

/**
 * Service for presenting a snackbar.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomSnackbarService {

  constructor(private _snackBar: MatSnackBar,
              private translateService: TranslateService) {
  }

  /**
   * Show snackbar with given message
   *
   * @param messageKey The translation key to have as message in the snackbar
   * @return void
   */
  showSnackBar(messageKey: string): void {
    const config = new MatSnackBarConfig();
    config.panelClass = 'snackbar';
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = 3000;
    const message = this.translateService.instant(messageKey);
    this._snackBar.open(message, undefined, config);
  }
}
