// check for valid email address
export const regexEmail: RegExp = new RegExp('^(?:[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*|"' +
  '(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-zA-Z0-9]' +
  '(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}' +
  '(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|' +
  '\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$');

export const regexInitials: RegExp = new RegExp('^([a-zA-Z][. ]{1,2})+$');

export const regexRequired: RegExp = new RegExp('\\S+');

export const regexPhone: RegExp = new RegExp('^[\\d()\\- +]{10,20}$');

export const regexDate: RegExp = new RegExp('^\\d{2}-\\d{2}-\\d{4}$');

export const regexStreetNumber: RegExp = new RegExp('^\\d+ ?[\\w.-]{0,5}$');

export const regexPostalCode: RegExp = new RegExp('^\\d{4} ?[a-zA-Z]{2}$');

export const regexPassword: RegExp = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[ -@\\[-`{-~]).{8,}');

export const regexAbsoluteUrl: RegExp = new RegExp('^(?:[a-z]+:)?//', 'i');

export const regexAlphanumeric: RegExp = new RegExp('^[\\w-]+$');

export const regexUUID: RegExp = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
