import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from '../settings/settings.service';
import {Observable} from 'rxjs';
import {HistoryItem} from '../../domain/history-item/history-item-data';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {IAssetWithData} from '../../domain/assets';
import {checkFileUploadError, getFormData} from '../formHelper';
import {MsgraphService} from '../msgraph/msgraph.service';
import {TranslateService} from '@ngx-translate/core';
import {FileService, FileServiceType} from '../file.service';
import {ModalService} from '../modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryItemService extends FileService {
  public serviceType: FileServiceType = FileServiceType.HistoryItem;

  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService,
    public msGraphService: MsgraphService,
    public translateService: TranslateService,
    private modalService: ModalService
  ) {
     super(msGraphService, translateService);
    }

  getForIdentity$(identityId: number): Observable<HistoryItem[]> {
    return this.httpClient.get(this.settingsService.backendUrl(`/historyItem/identity/${identityId}`))
      .pipe(map((result) =>
        JSON.deserialize<HistoryItem[]>(result, HistoryItem)
      ));
  }

  remove$(id: number): Observable<boolean> {
    return this.httpClient.delete(this.settingsService.backendUrl(`/historyItem/${id}`), {observe: 'response'})
      .pipe(map((result) => result.status === 200));
  }

  getAll$(): Observable<HistoryItem[]> {
    return this.httpClient.get(this.settingsService.backendUrl(`/historyItem/all`))
        .pipe(map((result) => JSON.deserialize<HistoryItem[]>(result, HistoryItem)));
  }

  getById$(id: number): Observable<HistoryItem> {
    return this.httpClient.get(this.settingsService.backendUrl(`/historyItem/${id}`))
      .pipe(map((result) => JSON.deserialize<HistoryItem>(result, HistoryItem)));
  }

  update$(item: HistoryItem, assets: IAssetWithData[]): Observable<HistoryItem> {
    return this.httpClient.put(
      this.settingsService.backendUrl(`/historyItem/${item.id.id}`),
      getFormData(item, assets),
    ).pipe(
      checkFileUploadError(this.modalService),
      map((result) => JSON.deserialize<HistoryItem>(result, HistoryItem))
    );
  }

  create$(item: HistoryItem, assets: IAssetWithData[]): Observable<HistoryItem> {
    return this.httpClient.post(
      this.settingsService.backendUrl(`/historyItem`),
      getFormData(item, assets)
    ).pipe(
      checkFileUploadError(this.modalService),
      map((result) => JSON.deserialize<HistoryItem>(result, HistoryItem))
    );
  }

  sendMail$(item: HistoryItem): Observable<boolean> {
    return this.httpClient.post(
        this.settingsService.backendUrl(`/historyItem/sendMail`),
        JSON.serialize(item),
        {observe: 'response'}
    ).pipe(map((resp) => resp.status === 200));
  }
}
