import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {IdentityTypes} from '../../../domain/identity/identitytypes';

@Component({
  selector: 'app-confirm-unmerge-modal',
  templateUrl: './confirm-unmerge-modal.component.html'
})
export class ConfirmUnmergeModalComponent {
  public translatePrefix: string = 'identity.detailIntern.unmergeModal.noParent';
  public onClose: Subject<boolean> = new Subject();
  public idType: IdentityTypes;

  constructor(private _bsModalRef: BsModalRef) {}

  onButtonClicked(accepted: boolean): void {
    if (accepted) {
      this.confirm();
    } else {
      this.decline();
    }
  }

  confirm(): void {
    this.onClose.next(true);
    this._bsModalRef.hide();
  }

  decline(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
}
