import {JsonConverter, JsonElementType, JsonObject, JsonProperty} from 'ta-json';
import {DbId} from '../dbid';
import {ListButton} from '../list/list-data';
import {UTCInterpreter} from '../utcinterpreter';
import {DriveFileTypes, DriveItemIds} from '../drive-item-ids';
import {Toolbox} from '../toolbox/product-data';
import {LocalDateTime} from '@js-joda/core';
import {EmailTemplatesForCategory} from 'app/domain/email-templates/emailTemplate';
import {ArchiveData} from '../archiveData';

@JsonObject()
export class BillingInfo {
  @JsonProperty() public price: number;
  @JsonProperty() public billingAmount?: number;
  @JsonProperty() public secondaryBillingAmount?: number;
  @JsonProperty() public billingOption?: BillingOption;
}

export enum BillingOption {
  Start = 'start',
  End = 'end',
  StartEnd = 'startEnd',
  StartOrEndForWorkflow = 'startOrEndForWorkflow',
  Monthly = 'monthly'
}

export const customBillingOptions = [BillingOption.StartEnd, BillingOption.Monthly, BillingOption.StartOrEndForWorkflow];

@JsonObject()
export class ProductEmailTemplates {
  @JsonProperty() public reportingEmailTemplates: EmailTemplatesForCategory;
  @JsonProperty() public endReportingEmailTemplates: EmailTemplatesForCategory;
  @JsonProperty() public endReportingExtensionEmailTemplates: EmailTemplatesForCategory;
  @JsonProperty() public startCourseEmailTemplates: EmailTemplatesForCategory;

  validate(): boolean {
    return !!this.reportingEmailTemplates &&
        !!this.endReportingEmailTemplates &&
        !!this.endReportingExtensionEmailTemplates &&
        !!this.startCourseEmailTemplates;
  }
}

@JsonObject()
export class ShowExtraFieldBooleans {
  @JsonProperty() public showDateResignation: boolean = false;
  @JsonProperty() public showEndDateLastContract: boolean = false;
  @JsonProperty() public showDateWaiting: boolean = false;
  @JsonProperty() public showDateEzwb: boolean = false;
  @JsonProperty() public showBenefitType: boolean = false;
  @JsonProperty() public showEndDateBenefit: boolean = false;
  @JsonProperty() public showUpload: boolean = false;
  @JsonProperty() public showBudget: boolean = false;
}

@JsonObject()
export class SessionTemplate {
  @JsonProperty() public id: DbId;
  @JsonProperty() public sessionNumber: number;
  @JsonProperty() public theme: string;
  @JsonProperty() public agenda: string;
  @JsonProperty() public toolbox: Toolbox;
}

@JsonObject()
export class BillingSettings {
  // this determines what (if any) choice can be made on a per-product basis
  // BillingOption.StartOrEndForWorkflow has to be further specified in the product configuration,
  @JsonProperty() public availableBillingOptions: BillingOption;
  @JsonProperty() @JsonElementType(Number) public monthlyBillingOffsets: number[];
  @JsonProperty() public lastMonthlyBillingOffset: number;
}

@JsonObject()
export class CourseWorkflow {
  @JsonProperty() public name: string;
  @JsonProperty() public nameWithoutVersion: string;
  @JsonProperty() public sessionCount: number;
  @JsonProperty() public billingSettings: BillingSettings;
  @JsonProperty() public durationInDays: number;
  @JsonProperty() public durationInMonths: number;
  @JsonProperty() public initialWeight: number;
  @JsonProperty() public startEndOffset: number;
  @JsonProperty() @JsonElementType(Number) nonEndReportGreenDeadlines: number[];
  // no report settings, because these are not needed in the front-end, and this class is never sent back to the back-end
  @JsonProperty() public withJobPortConfig: boolean;
}

@JsonObject()
export class ProductResult {
  @JsonProperty() public product: DbId;
  @JsonProperty() public text: string;
  @JsonProperty() public allowClarification: boolean;
}

@JsonObject()
export class BaseInfo {
  @JsonProperty() public name: string;
  @JsonProperty() public slug: string = '';
  @JsonProperty() public description: string = '';
  @JsonProperty() public billingInfo: BillingInfo = new BillingInfo();
  @JsonProperty() public duration: number;
  @JsonProperty() public weight: number;
}

@JsonObject()
export class Product {
  @JsonProperty() public id: DbId = DbId.from();
  @JsonProperty() public categoryId: DbId = DbId.from();
  @JsonProperty() @JsonElementType(BaseInfo) public baseInfo: BaseInfo = new BaseInfo();
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt: LocalDateTime;
  @JsonProperty() public prepaid: boolean = true;
  @JsonProperty() public allowIntake: boolean;
  @JsonProperty() public defaultWithIntake?: boolean;
  @JsonProperty() public showExtraFieldBooleans: ShowExtraFieldBooleans = new ShowExtraFieldBooleans();
  @JsonProperty() public driveItemIds: DriveItemIds;
  @JsonProperty() @JsonElementType(ProductResult) public results: ProductResult[] = [];
  @JsonProperty() public courseFlow?: string;
  @JsonProperty() @JsonElementType(SessionTemplate) public sessions: SessionTemplate[] = [];
  @JsonProperty() public isValid: boolean = false;
  @JsonProperty() public withJobPort: boolean = false;
  @JsonProperty() public productEmailTemplates: ProductEmailTemplates = new ProductEmailTemplates();
  @JsonProperty() public startEmailAttachment?: DriveFileTypes;
  // when defined, this will be used to give an extra label to the end report actions.
  @JsonProperty() public actionLabel?: string;
  @JsonProperty() public withExtension: boolean = true;
  @JsonProperty() archiveData?: ArchiveData;
  @JsonProperty() shouldStartAllSessionsLopend: boolean = false;
}

export interface IProductColumnData {
  id: number;
  name: string;
  duration: number;
  price: number;
  createdAt: LocalDateTime;
  isValid: string;
  actions: ListButton<IProductColumnData>[];
}

@JsonObject()
export class Category {
  @JsonProperty() public id: DbId = DbId.from();
  @JsonProperty() public name: string;
  @JsonProperty() public description: string = '';
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt?: LocalDateTime = null;
  @JsonProperty() @JsonElementType(Product) public products: Product[] = [];
  @JsonProperty() archiveData?: ArchiveData;
}

export interface ICategoryColumnData {
  id: number;
  name: string;
  amountOfProducts: number;
  createdAt: LocalDateTime;
  actions: ListButton<ICategoryColumnData>[];
}

export const defaultProductResults: {text: string; allowClarification: boolean}[] = [
  {text: 'Werkhervatting EXTERN bij nieuwe werkgever', allowClarification: false},
  {text: 'Werkhervatting in ANDER WERK bij eigen werkgever', allowClarification: false},
  {text: 'Werkhervatting EIGEN WERK bij eigen werkgever', allowClarification: false},
  {text: 'Werkhervatting in EIGEN bedrijf (zzp)', allowClarification: false},
  {text: 'Vaststellingsovereenkomst', allowClarification: false},
  {text: 'Cliënt is beter gemeld', allowClarification: false},
  {text: 'Geen plaatsing, 80%-100% WGA of IVA', allowClarification: false},
  {text: 'Geen plaatsing, cliënt niet in staat tot werk om gezondheidsredenen', allowClarification: false},
  {text: 'Geen plaatsing, cliënt krijgt WW', allowClarification: false},
  {text: 'Geen plaatsing, cliënt krijgt WGA', allowClarification: false},
  {text: 'Geen plaatsing, cliënt krijgt ZW', allowClarification: false},
  {text: 'Geen plaatsing, overig, te weten:', allowClarification: true},
  {text: 'Dienst volledig doorlopen, overig, te weten:', allowClarification: true},
  {text: 'Dienst doorlopen conform offerte', allowClarification: false},
  {text: 'Tussentijdse beëindiging wegens onvoldoende belastbaarheid', allowClarification: false},
  {text: 'Tussentijdse beëindiging, cliënt werkt niet mee', allowClarification: false},
  {text: 'Tussentijdse beëindiging, opdracht ingetrokken', allowClarification: false},
  {text: 'Tussentijdse beëindiging, reden te weten:', allowClarification: true},
  {text: 'Traject wordt verlengd', allowClarification: false},
  {text: 'Geen plaatsing, WIA onbekend', allowClarification: false}
];

export const weights: number[] = [
  0,
  0.25,
  0.50,
  0.75,
  1.0,
  1.25,
  1.50,
  1.75,
  2.0
];
