<div class="input-wrapper" [ngClass]="cssClass">
  <div class="d-flex flex-row">
    <input
      [attr.id]="!!id ? id : null"
      class="form-control" #input
      [required]="required"
      [disabled]="disabled"
      [minlength]="minlength"
      [maxlength]="maxlength"
      [pattern]="pattern"
      [min]="type === 'number' ? minValue : null"
      [max]="type === 'number' ? maxValue : null"
      [type]="showPassword ? 'text' : type"
      [placeholder]="(!disabled ? placeholder : '') | translate"
      [(ngModel)]="data"
      (blur)="onBlur()"
      (focus)="focusChanged(true)"
      (keyup)="onKeyUp()"
      [attr.aria-label]="!!ariaLabel ? ariaLabel : null">
    <app-show-password-eye-icon *ngIf=isPassword [(showPassword)]="showPassword"></app-show-password-eye-icon>
  </div>
  <div *ngIf="!required && !disabled && !data" class="input-optional">
    {{ 'input.optional' | translate }}
  </div>
  <div *ngIf="placeholder && (disabled || !!data)" class="input-optional">
    {{ '[' + (placeholder | translate) + ']' }}
  </div>
  <div class="text-danger" [hidden]="valid || disabled || focused || untouched">
    {{ errorText | translate }}
  </div>
  <div class="text-danger" [hidden]="!showRequired || !dataEmpty || !required">
    {{ 'input.required' | translate }}
  </div>
</div>
