import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {LoginService} from 'app/services/login/login.service';
import {TranslatorService} from 'app/services/translator/translator.service';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {regexEmail} from 'app/domain/regex';
import {RedirectService} from 'app/services/redirectService';

@Component({
  selector: 'app-login-content',
  templateUrl: 'login-content.component.html',
  styleUrls: ['./login-content.component.scss']
})
export class LoginContentComponent {
  public smallScreen: number = 480;
  public $screenResize: Observable<number> = fromEvent(window, 'resize').pipe(
      startWith(window.innerWidth),
      map(() => document.documentElement.clientWidth),
      debounceTime(200)
  );

  @Input()
  public withRegister: boolean = false;

  @Input()
  public withOnLogin: boolean = false;

  @Input()
  public registerEndpoint: string = '';

  @Output()
  public onLogin: EventEmitter<any> = new EventEmitter<any>();

  public formEmail: string;
  public formPassword: string;
  public loading: boolean = false;
  public incorrect: boolean = false;
  public regexEmail: RegExp = regexEmail;
  public showPassword: boolean = false;

  constructor(
      private _activatedRoute: ActivatedRoute,
      private _router: Router,
      private _loginService: LoginService,
      private _translatorService: TranslatorService,
      private _redirectService: RedirectService
  ) {}

  public doLogin(): void {
    this.loading = true;

    this._loginService.login(this.formEmail, this.formPassword).then(() => {
      this.loading = false;
      if (this.withOnLogin) {
        this.onLogin.emit();
      } else {
        void this._router.navigate([this._redirectService.redirectUrl]);
      }
    }).catch(() => {
      this.incorrect = true;
      this.loading = false;
    });
  }

  register(): void {
    void this._router.navigate([this.registerEndpoint]);
  }

  toShop(): void {
    window.open('https://power4people.nl/shop/', '_self');
  }
}
