import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {LoginStateService} from 'app/services/login/loginstate.service';
import {Injectable, OnDestroy} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

/**
 * Intercept all requests to add the current user to the query parameters of this request.
 */
@Injectable()
export class TraceabilityInterceptor implements HttpInterceptor, OnDestroy {
  // We save the identifier to this local variable everytime the currentUser Observable emits a new value.
  private currentUserIdentifier: string | null = null;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(public loginStateService: LoginStateService) {
    this.loginStateService.currentUser$.pipe(takeUntil(this.onDestroy$)).subscribe({
      next: user => {
        if (user === null) {
          this.currentUserIdentifier = null;
        } else {
          this.currentUserIdentifier = `${uuidv4()}_${user.user.roles.toString()}`;
        }
      },
      complete: () => this.currentUserIdentifier = null
    });
  }

  /**
   * Intercept requests to add a query parameter that consists of a random UUID of the user performing the request,
   * appended with a list of their roles.
   * If there is no logged-in user, the request is sent as-is to the next HttpHandler in the queue.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.currentUserIdentifier === null) {
      return next.handle(req);
    } else {
      // clone the current request
      const newRequest: HttpRequest<any> = req.clone({
        // update the query parameters to include the userIdentifier=<userId>_<currentRoles> parameter, overwriting it if it already exists.
        params: req.params.set('userIdentifier', this.currentUserIdentifier)
      });
      return next.handle(newRequest);
    }
  }

  // cleanup
  ngOnDestroy() {
    this.currentUserIdentifier = null;
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
