<div class="alert-box">
  <div class="modal-header position-relative flex-column">
    <span *ngIf="!mustAnswer && canClose" class="close-icon" (click)="buttonClicked(false)">
      <ion-icon name="close" ></ion-icon>
    </span>
    <ng-content *ngIf="customTitle" select="h4"></ng-content>
    <h4 *ngIf="!customTitle" class="modal-title">{{ _translatePrefix + ".title" | translate}}</h4>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <button *ngIf="cancelBtn" type="button" class="btn btn-default" (click)="buttonClicked(false)" disableDuringRequest>
      {{_translatePrefix + ".cancelCaption" | translate}}
    </button>
    <button *ngIf="canClose && confirmVisible" type="button" class="btn" [ngClass]="typeOfButton" (click)="buttonClicked(true)" disableDuringRequest [disableDefault]="!confirmEnabled">
      {{_translatePrefix + ".confirmCaption" | translate}}
    </button>
  </div>
</div>
