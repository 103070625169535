import {JsonConverter, JsonObject, JsonProperty} from 'ta-json';
import { v4 as uuid } from 'uuid';
import {DbId} from './dbid';
import {UTCInterpreter} from './utcinterpreter';
import {SettingsService} from '../services/settings/settings.service';
import {LocalDateTime} from '@js-joda/core';

@JsonObject()
export class Asset {
  @JsonProperty() id: DbId;
  @JsonProperty() uuid: string;
  @JsonProperty() title: string;
  @JsonProperty() filename: string;
  @JsonProperty() assetType: AssetType;
  @JsonProperty() path: string = '';
  @JsonProperty() @JsonConverter(UTCInterpreter) createdAt: LocalDateTime;
  @JsonProperty() @JsonConverter(UTCInterpreter) updatedAt: LocalDateTime;
  @JsonProperty() identityId?: number;
  @JsonProperty() toolboxId?: number;
  @JsonProperty() historyItemId?: number;

  constructor(title: string, assetType: AssetType, identityId?: number) {
    this.id = DbId.from(0);
    this.uuid = uuid();
    this.title = title;
    this.assetType = assetType;
    this.identityId = identityId;
  }

  /**
   * Create the back-end URL from the file path, including auth token
   */
  resolvePath(token: string, settingsService: SettingsService): Asset {
    if (this.id.id) {
      const fileUrl = this.assetType === AssetType.Toolbox ? 'toolbox' : 'identity';

      return Object.assign(this, {
        path: settingsService.backendUrl(`/file/${fileUrl}/${this.id.id}?token=${token}`)
      });
    }
  }
}

export enum AssetType {
  Quotation = 'quotation',
  Naw = 'naw',
  History = 'history',
  Toolbox = 'toolbox'
}

export interface IAssetWithData {
  asset: Asset;
  data: File;
}
