<div class="h-100 d-flex flex-column">
<!-- top navbar-->
  <app-header *ngIf="showHeader" class="header"></app-header>
  <!-- Main section-->
  <main class="flex-grow-1 content-area">
    <!-- Page content-->
    <section class="container container-large">
      <div class="card m-5 p-5">
        <router-outlet></router-outlet>
      </div>
    </section>
  </main>
</div>
