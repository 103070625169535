import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SharedModule} from 'app/shared/shared.module';
import {LayoutComponent} from './layout.component';
import {HeaderComponent} from './header/header.component';
import {LayoutWithSidebarComponent} from './with-sidebar/layout-with-sidebar.component';
import {IdentityLayoutComponent} from './identity-layout/identity-layout.component';

@NgModule({
  imports: [
    RouterModule,
    SharedModule
  ],
  declarations: [
    LayoutComponent,
    LayoutWithSidebarComponent,
    IdentityLayoutComponent,
    HeaderComponent
  ],
  exports: [
    LayoutComponent,
    LayoutWithSidebarComponent,
    IdentityLayoutComponent,
    HeaderComponent
  ]
})
export class LayoutModule {
}
