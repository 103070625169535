import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApproveOrRejectDataComponent} from '../../components/approval-data/approve-or-reject-data.component';
import {LoginStateService} from '../../services/login/loginstate.service';
import {Naw} from '../../domain/identity/identitydata';
import {IdentityService} from '../../services/identity/identity.service';
import {LoginService} from '../../services/login/login.service';
import {first} from 'rxjs/operators';
import {ModalService} from '../../services/modal/modal.service';
import {ServiceService} from '../../services/service/service.service';

@Component({
  templateUrl: './approve-or-reject-quotation.component.html',
  styleUrls: ['./approve-or-reject-quotation.component.scss']
})
export class ApproveOrRejectQuotationComponent implements OnInit {
  public approveIdExists: boolean = false;
  public isApprove: boolean = false;
  public approveOrRejectPending: boolean = true;
  public approvedOrRejected: boolean = false;
  public errorText: string = '';
  public translatePrefix: string;
  @ViewChild(ApproveOrRejectDataComponent, {static: false})
  private approveOrRejectData: ApproveOrRejectDataComponent;
  public currentNaw: Naw = null;
  public loading: boolean = true;

  private approveOrRejectId: string;
  private showRequired: boolean = false;


  constructor(
      router: Router,
      activeRoute: ActivatedRoute,
      private serviceService: ServiceService,
      private modalService: ModalService,
      private loginService: LoginService,
      private loginStateService: LoginStateService,
      private identityService: IdentityService
  ) {
    this.approveOrRejectId = activeRoute.snapshot.params['approveOrRejectId'];
    this.isApprove = router.url.includes('/approve/');
  }

  ngOnInit(): void {
    this.serviceService.checkForApproveId$(this.approveOrRejectId).subscribe((res) => {
      this.approveIdExists = res;
      this.translatePrefix = this.isApprove ? 'approve.' : 'reject.';
      this.errorText = res ? 'failed' : 'alreadyApprovedRejected';
    }, (e) => {
      this.errorText = 'quotationApproveOrReject.errorText';
    });

    // Check if we have a token before deciding if we should wait for the user to be loaded.
    this.loginStateService.auth$.subscribe((t) => {
      if (t) {
        // We have a token; get the current user.
        this.loginStateService.currentUser$.pipe(
            first((u) => !!u)
        ).subscribe((u) => {
          if (u) {
            this.currentNaw = u.user.userIdentity.naw;
          }
          this.loading = false;
        });
      } else {
        // No token means we'll never get a user, just load the page.
        this.loading = false;
      }
    });
  }

  onApproveOrReject(): void {
    if (this.approveOrRejectData.valid) {
      if (this.currentNaw) {
        this.doApproveOrReject(this.currentNaw);
      } else {
        this.identityService.createNaw$(Naw.fromApprovalDataFormObject(this.approveOrRejectData.form)).subscribe((result) => {
          if (result[0]) {
            this.doApproveOrReject(Naw.fromApprovalDataFormObject(this.approveOrRejectData.form, result[1]));
          } else {
            this.approvedOrRejected = false;
            this.approveOrRejectPending = false;
          }
        });
      }
    } else {
      this.showRequired = true;
    }
  }

  doApproveOrReject(naw: Naw): void {
    this.modalService.getConfirmation(
        {translatePrefix: `quotationApproveOrReject.${this.translatePrefix}confirmation`}
    ).subscribe((confirmed) => {
      if (confirmed) {
        if (this.isApprove) {
          this.serviceService.acceptByApproveId$(this.approveOrRejectId, naw, undefined).subscribe(
              (result) => {
                if(result.isNotContactPerson){
                  this.modalService.getConfirmation(
                    {translatePrefix: `quotationApproveOrReject.shouldBeNotified.confirmation`}
                  ).subscribe((willBeNotified) => {
                    this.serviceService.acceptByApproveId$(this.approveOrRejectId, naw, willBeNotified)
                      .subscribe((result2) => {
                        this.approvedOrRejected = result2.success;
                        this.approveOrRejectPending = false;
                      });
                  });
                } else {
                  this.approvedOrRejected = result.success;
                  this.approveOrRejectPending = false;
                }
              },
              (e) => {
                this.handleError(e);
              }
          );
        } else {
          this.serviceService.rejectByApproveId$(this.approveOrRejectId, naw, this.approveOrRejectData.rejectReason).subscribe(
              (result) => {
                this.approvedOrRejected = result;
              },
              (e) => {
                this.handleError(e);
              },
              () => {
                this.approveOrRejectPending = false;
              }
          );
        }
      }
    });
  }

  handleError(e: any): void {
    if (e.status === 404 && e.error === 'UNKNOWN_APPROVE_ID') {
      this.errorText = 'alreadyApprovedRejected';
    }
    this.approvedOrRejected = false;
    this.approveIdExists = false;
  }
}
