import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSpec } from 'app/domain/translator/language';
import { SettingsService } from 'app/services/settings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class TranslatorService {

  public defaultLanguage: LanguageSpec;
  public currentLanguage: LanguageSpec;
  public availableLangs: Array<LanguageSpec>;

  constructor(private translate: TranslateService,
              private settingsService: SettingsService) {
    // Get the settings for the language
    this.availableLangs = settingsService.getSetting('language.options');
    this.defaultLanguage = settingsService.getSetting('language.default');
    this.currentLanguage = this.defaultLanguage;

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(this.defaultLanguage.code);

    // Start with the default language.
    this.useLanguage();
  }

  /**
   * Change the current language
   *
   * @param language
   */
  useLanguage(language: LanguageSpec = this.defaultLanguage): void {
    this.translate.use(language.code);
    this.currentLanguage = language;
  }

  /**
   * Return the available languages.
   *
   * @returns
   */
  getAvailableLanguages(): Array<LanguageSpec> {
    return this.availableLangs;
  }

  /**
   * Return the readable current language
   *
   * @returns
   */
  getCurrentLangDisplay(): string {
    return this.availableLangs.find( (language) => language.code === this.currentLanguage.code).text;
  }

}
