import {Directive, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {PermissionsService} from '../services/permissions/permissions.service';
import {PermissionName} from '../domain/userdata';
import {combineLatest, Observable, Subject} from 'rxjs';
import {takeUntil, toArray} from 'rxjs/operators';

/**
 * Show/hide components based on user permissions using this directive. Use like:
 * <div secureAction="operation.name" requiredPermission="Read">
 *   > requiredPermission represents the bit level (specified in PermissionName)
 */
@Directive({
  selector: '[secureAction]'
})
export class SecureActionDirective implements OnInit, OnDestroy {

  // note that an array is treated like an OR
  @Input() secureAction: string | string[];
  @Input() requiredPermission: PermissionName;
  @HostBinding('hidden') isHidden: boolean = true;
  private destroy$: Subject<void> = new Subject();

  constructor(private permissionsService: PermissionsService) {}

  private observePermission$(action: string): Observable<boolean> {
    return this.permissionsService.observePermission(
      action,
      this.requiredPermission
    );
  }
  ngOnInit(): void {
    if (this.secureAction && this.requiredPermission) {

      if ( typeof this.secureAction === 'string' ) {
        this.observePermission$(this.secureAction as string).pipe(takeUntil(this.destroy$))
          .subscribe((permitted) => this.isHidden = !permitted);
      } else {
        combineLatest((this.secureAction as string[]).map((s) => this.observePermission$(s)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((anyPermitted) => {
            this.isHidden = !anyPermitted.find((b) => b);
          });
      }

    } else if (this.secureAction === undefined && this.requiredPermission === undefined) {
      // if no secureAction and requiredPermission are given, the element should not be hidden
      this.isHidden = false;
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
