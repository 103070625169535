import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NawNamePipe} from './naw-name.pipe';
import {CompanyDetails, Identity, NawDetails} from '../domain/identity/identitydata';
import {IdentityDetails} from '../domain/identity/identityTableData';

@Pipe({
  name: 'identityName'
})
export class IdentityNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transformIdentity(identity: Identity, args?: any): string {
    return this.transform(identity as unknown as {
      company?: CompanyDetails;
      naw?: NawDetails;
      isInternal(): () => boolean;
    }, args);
  }

  transformIdentityDetails(identity: IdentityDetails, args?: any): string {
    return this.transform(identity as unknown as {
      company?: CompanyDetails;
      naw?: NawDetails;
      isInternal(): () => boolean;
    }, args);
  }

  transform(value: {
    company?: CompanyDetails;
    naw?: NawDetails;
    isInternal(): () => boolean;
  }, args?: any): string {
    if (!value) {
      return '';
    }

    if (value.company && !!args && args.showCompany) {
      return value.company.name;
    }

    if (!value.naw) {
      if (value.company && !!args && args.companyFallback) {
        return value.company.name;
      }
      return '';
    }

    return new NawNamePipe(this.translateService).transform(value.naw as any,
      !args ? {isInternal: value.isInternal()} : Object.assign(args, {isInternal: value.isInternal()}));
  }
}
