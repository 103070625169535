import {JsonConverter, JsonObject, JsonProperty} from 'ta-json';
import {DbId} from '../dbid';
import {IdentityTypes, internalIdentityTypes} from './identitytypes';
import {CompanyDetails, Identity, NawDetails, IdentityFullNames} from './identitydata';
import {DetailedQuotationStatusCodes, PublicQuotationStatusCodes, ServiceStatus, ServiceStatusCodes} from '../service/service-status';
import {ListButton} from '../list/list-data';
import {LocalDateTime} from '@js-joda/core';
import {UTCInterpreter} from '../utcinterpreter';
import {ArchiveData} from 'app/domain/archiveData';

@JsonObject()
export class IdentityDetails {
  @JsonProperty() id: DbId;
  @JsonProperty() idType: IdentityTypes;
  @JsonProperty() fullNames: IdentityFullNames;
  @JsonProperty() mainCity?: string = null;
  @JsonProperty() naw: NawDetails;
  @JsonProperty() company: CompanyDetails;
  @JsonProperty() createdAt: LocalDateTime;
  @JsonProperty() ownerId: number;
  @JsonProperty() isActive: boolean;
  @JsonProperty() isNew: boolean;
  @JsonProperty() archiveData?: ArchiveData;

  static toIdentityDetails(identity: Identity): IdentityDetails {
    if (identity) {
      return Object.assign(new IdentityDetails(),
        {
          id: identity.id,
          idType: identity.idType,
          naw: identity.naw,
          company: identity.company,
          isActive: identity.isActive,
          createdAt: identity.createdAt,
          ownerId: identity.owner,
          archiveData: identity.archiveData
        }
      );
    } else {
      return new IdentityDetails();
    }
  }

  public isInternal(): boolean {
    return Object.keys(internalIdentityTypes).map((k) => internalIdentityTypes[k]).includes(this.idType);
  }
}

@JsonObject()
export class IdentityTableData {
  @JsonProperty() identity: IdentityDetails;
  @JsonProperty() mainCompanyName?: string = null;
  @JsonProperty() serviceName: string = null;
  @JsonProperty() serviceStatus: ServiceStatus = null;
  @JsonProperty() coach: IdentityDetails = null;
  @JsonProperty() serviceOwner: IdentityDetails = null;
  @JsonProperty() employer: IdentityDetails = null;
  @JsonProperty() serviceTrajectAssistent: IdentityDetails = null;
}

export interface IIdentityColumnData {
  identity: IdentityDetails;
  fullName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobFunction: string;
  company: string;
  serviceProduct: string;
  serviceStatus: ServiceStatusCodes | DetailedQuotationStatusCodes | PublicQuotationStatusCodes;
  serviceCoach: string;
  coachLastName: string;
  city: string;
  createdAt: LocalDateTime;
  serviceOwner: string;
  ownerId?: number;
  toggleNew?: ListButton<IIdentityColumnData>;
  isOwner: boolean;
  rowColor?: string;
  serviceTrajectAssistent: string;
  archiveButton?: ListButton<IIdentityColumnData>;
}

