import {JSON} from 'ta-json';
import {IAssetWithData} from '../domain/assets';
import {catchError} from 'rxjs/operators';
import {of, OperatorFunction} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {ModalService} from './modal/modal.service';
import {allowedFileExtensions, allowedFileExtensionsForInternal} from '../components/file-upload/file-upload.component';

/**
 * Create form data from a set of normal data (serializable to json) and uploaded files
 * This is used to do a post with json and file data to the backend
 */
export function getFormData<T>(data: T, assets: IAssetWithData[]): FormData {
  const formData = new FormData();
  // add normal data as json field in the form
  formData.append('json', JSON.stringify(JSON.serialize(data)));
  // add all files that were newly uploaded (have data) to the form
  assets.filter((a) => !!a && !!a.data)
    .forEach((a) => formData.append(a.asset.uuid, a.data));

  return formData;
}

/**
 * Check if the response contains an error that the uploaded file was invalid.
 * It shows a slightly altered text for internal and external users.
 * If so, show a warning to the user.
 *
 * @param modalService
 * @param isInternalUser
 */
export function checkFileUploadError(
    modalService: ModalService,
    isInternalUser: boolean = true
): OperatorFunction<HttpResponse<any>, HttpResponse<any>> {
  return catchError((e) => {
    if (e.status === 400 && e.error === 'File is not valid') {
      // append the items that are allowed for internal users if the current user is internal.
      const extraFileExtensions = isInternalUser ? Array.from(allowedFileExtensionsForInternal.values()) : [];
      const allAllowedExtensions = Array.from(allowedFileExtensions.values()).concat(extraFileExtensions).join(', ');

      modalService.showWarning({
        translatePrefix: 'incorrectFileWarning',
        messageParams: {allowedExtensions: allAllowedExtensions}
      });
    }

    if (e.status >= 200 && e.status < 300) {
      return of(e);
    } else {
      throw e;
    }
  });
}
