import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {SettingsService} from 'app/services/settings/settings.service';
import {Observable} from 'rxjs';
import {LoginStateService} from 'app/services/login/loginstate.service';
import {IMenuItem} from 'app/layouts/header/menu-item.interface';
import {UserWithPermissions} from 'app/domain/userdata';
import {first, map} from 'rxjs/operators';


/**
 * Guard that checks whether the current user is allowed to follow a particular route.
 * This uses the permission definitions in the MenuItems defined in the SettingService.
 * Users can only follow a guarded route if their token contains a required permission (and corresponding bit on that permission)
 *  for that route.
 *
 * Use this guard for all routes that have a corresponding menu-item which defines the permission(s) required to follow this route.
 * Do not use this guard if the route you are guarding has no corresponding menu-item (it will always return false in this case),
 *  or if there are no permissions required to follow a route.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class HasPermissionGuard  {
  constructor(
      private settingsService: SettingsService,
      private loginStateService: LoginStateService,
      private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.guard(state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.guard(state);
  }

  /**
   * Guard a route by checking the current user's permission against the required permissions for that route.
   */
  private guard(state: RouterStateSnapshot): Observable<boolean> {
    return this.loginStateService.currentUser$.pipe(
        first((u) => !!u),
        map((u) => {
          const menuItem = this.settingsService.getMenuItemByRoute(state.url, u.user.isInternalUser());
          // assume not allowed if there is no MenuItem for the guarded route.
          const isAllowed = menuItem !== undefined && HasPermissionGuard.checkUserPermissionOnMenuItem(menuItem, u);

          if (!isAllowed) {
            // redirect if not allowed to view this page
            this.router.navigateByUrl('/login-redirect');
          }
          return isAllowed;
        }));
  }

  /**
   * Checks a UserWithPermission to see if they have the correct Permission (operation name and bit) to "activate" the routes associated
   * with this menu item. This check succeeds iff the user has at least one 'operation' that is also defined on the MenuItem (as an action)
   * and the required permission bit is matched.
   */
  private static checkUserPermissionOnMenuItem(menuItem: IMenuItem, user: UserWithPermissions): boolean {
    // admins have a blanket permission for everything
    return user.permissions.some((p) => p.operation.name === '*') ||
        // check all the user's permissions
        user.permissions.some((p) =>
          // for each permission, check if the current MenuItem has defined that permission as a required action
          !!menuItem.actions && menuItem.actions.some((action) => p.operation.name === action) &&
          // if so, then check if the current user's permission bit matches (or contains) the required permission bit.
          (menuItem.permission & p.bit) === menuItem.permission
      );
  }
}
