import {Injectable, OnDestroy, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {first, take, takeUntil} from 'rxjs/operators';
import {ModalService} from './modal/modal.service';
import {ButtonType} from '../domain/button/button-type';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService implements OnDestroy {
  // this will be replaced by actual hash post-build.js
  private currentHash: string = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private onDestroy$: Subject<void> = new Subject();

  constructor(private http: HttpClient,
              private modalService: ModalService) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Checks in every set frequency the version of frontend application
   *
   * @param url
   * @param frequency - in milliseconds, defaults to 5 minutes
   */
  public initVersionCheck(url: string, frequency: number = 1000 * 60 * 5): void {
    if (url) {
      // make sure version is called upon restart
      this.checkVersion(url);

      setInterval(() => {
        this.checkVersion(url);
      }, frequency);
    }
  }

  /**
   * Will do the call and check if the hash has changed or not
   *
   * @param url
   */
  private checkVersion(url: string): void {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().valueOf()).pipe(takeUntil(this.onDestroy$), take(1))
      .subscribe((response: any) => {
        const hash = response.hash;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);

        // If new version, do something
        if (hashChanged) {
          // Setting true forces to reload from server. Seems to be marked as deprecated because it is
          // not in explicitly in the specs, though it still seems to be present:
          // https://github.com/Microsoft/TypeScript/issues/28898
          this.modalService.getConfirmation({translatePrefix: 'oldVersionModal', typeOfButton: ButtonType.BtnDefault})
            .subscribe((confirmed) => {
              if (confirmed) {
                location.reload();
              }
            }
          );
        }
      },
      (err) => {
        console.error(err, 'Could not get version');
      }
    );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   *
   * @param currentHash
   * @param newHash
   * @returns
   */
  private hasHashChanged(currentHash: string, newHash: string): boolean {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
