import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, finalize, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {LoadingService} from '../loading.service';
import { RequestLoadingType } from '../requestLoadingType';

/**
 * Interceptor to check if http-requests are going on
 */
@Injectable()
export class IsBusyInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.Sent) {
          this.loadingService.toggleRequesting(RequestLoadingType.Sent, req.url);
        } else {
          this.loadingService.toggleRequesting(RequestLoadingType.Response, req.url);
        }
      }),
      catchError((e) => {
        this.loadingService.toggleRequesting(RequestLoadingType.Error, req.url);
        return throwError(e);
      }),
      finalize(() => {
        this.loadingService.toggleRequesting(RequestLoadingType.Finalized, req.url);
      })
    );
  }
}
