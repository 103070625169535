import {
  JsonConverter,
  JsonDiscriminatorProperty,
  JsonDiscriminatorValue,
  JsonElementType,
  JsonObject,
  JsonProperty
} from 'ta-json';
import {Identity} from '../identity/identitydata';
import {IdentityTypes} from '../identity/identitytypes';
import {UTCInterpreter, DateInterpreter} from '../utcinterpreter';
import {DbId} from '../dbid';
import {
  DetailedQuotationStatusCodes,
  OfferteBijnaVerlopenCode
} from '../service/service-status';
import {IdentityDetails} from '../identity/identityTableData';
import { CourseActionCodes, getCourseActionCode } from '../service/service-data';
import {HistoryItemCategory} from '../history-item/history-item-data';
import {LocalDate, LocalDateTime} from '@js-joda/core';

@JsonObject()
export class WrappedId {
  @JsonProperty()
  public id: string;
}

@JsonObject()
export class ActionButton {
  @JsonProperty()
  public id: WrappedId;
}

@JsonObject()
export class ActionMeta {
  @JsonProperty()
  public id: WrappedId;
  @JsonProperty()
  @JsonElementType(ActionButton)
  public buttons: ActionButton[];
  @JsonProperty()
  public text: string;
  @JsonProperty()
  public first: boolean;
}

export enum ActionType {
  Quotation = 'quotation'
}

export enum ActionStatus {
  Open = 'open',
  Closed = 'closed',
  Reverted = 'reverted',
  Aborted = 'aborted'
}

export enum PrematureEndFlowActionHandling {
  CloseAction = 'closeAction',
  LeaveActionOpen = 'leaveActionOpen',
  UpdateAction = 'updateAction'
}

export enum StopFlowActionHandling {
  StopCloseAction = 'closeAction',
  StopLeaveActionOpen = 'leaveActionOpen',
  SwapStopLeaveActionOpen = 'swapLeaveActionOpen'
}

export enum ActionUrgency {
  Open = '1_open',
  Urgent = '2_urgent',
  TooLate = '3_tooLate'
}

export enum EventType {
  ButtonEventType = 'ButtonEvent',
  BackEventType = 'Back',
  AbortEventType = 'Abort'
}

export enum ActionExtraFilter {
  ReportOrQuotationSendOrFeedback = 'report_or_quotation_send_or_feedback'
}

export class Button {
  @JsonProperty() id: { id: string };

  getTitle(action: Action): string {
    return Action.getActionTakenTitle(this.id.id, action.code);
  }
}

export enum ActionIdentityType {
  Owner = 'Owner',
  NextOwner = 'NextOwner',
  Subject = 'Subject'
}

export interface IActionIdentity {
  type: ActionIdentityType;
  actionIdentity: ActionIdentity;
}

export interface IActionLink {
  path?: string;
  title: string;
  queryParams?: {[k: string]: string | number};
}

@JsonObject()
export class ActionIdentity {
  @JsonProperty() idType: IdentityTypes;
  @JsonProperty() identity: Identity;
}

@JsonObject()
export class ActionIdentityDetails {
  @JsonProperty() idType: IdentityTypes;
  @JsonProperty() identity: IdentityDetails;
}

@JsonObject()
export class CompletionData {
  @JsonProperty() actionTaken: string;
  @JsonProperty() performedBy: ActionIdentity;
  @JsonProperty() @JsonConverter(UTCInterpreter) completedOn: LocalDateTime;
}

@JsonObject()
export class CompletionDataDetails {
  @JsonProperty() actionTaken: string;
  @JsonProperty() performedBy: ActionIdentityDetails;
  @JsonProperty() @JsonConverter(UTCInterpreter) completedOn: LocalDateTime;
}

@JsonObject()
export class ActionDeadlines {
  @JsonProperty() @JsonConverter(DateInterpreter) greenDeadline: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) yellowDeadline: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) redDeadline: LocalDate;
}

@JsonObject()
export class CustomActionProperties {
  @JsonProperty() customAction: boolean = false;
  @JsonProperty() automaticCustomAction: boolean = false;
  @JsonProperty() customActionServiceId: number;
}

@JsonObject()
export class NextActionDetails {
  @JsonProperty() code: string;
  @JsonProperty() owner: ActionIdentity;
  @JsonProperty() actionText?: string;
  @JsonProperty() translationKey?: string;
  @JsonProperty() translationPostfix?: string;
}

@JsonObject()
export class NextActionDetailsDetails {
  @JsonProperty() code: string;
  @JsonProperty() owner: ActionIdentityDetails;
  @JsonProperty() actionText?: string;
  @JsonProperty() translationKey?: string;
  @JsonProperty() translationPostfix?: string;
}

@JsonObject()
export class ActionFlowMetadata {
  @JsonProperty() prematureEndFlowActionHandling: PrematureEndFlowActionHandling = PrematureEndFlowActionHandling.LeaveActionOpen;
  @JsonProperty() stopFlowActionHandling: StopFlowActionHandling = StopFlowActionHandling.StopLeaveActionOpen;
  @JsonProperty() first: boolean;
  @JsonProperty() maySpecifyNextActionData?: boolean;
  @JsonProperty() isStartAction: boolean;
  @JsonProperty() isEndAction: boolean;
  @JsonProperty() allowAbort: boolean;
  @JsonProperty() offsetId?: string;
  @JsonProperty() moveOtherOffsetActions: boolean;
  @JsonProperty() areDeadlinesChangeable: boolean;
}

@JsonObject()
export class ActionIdentities {
  @JsonProperty() owner: ActionIdentity;
  @JsonProperty() creator: Identity;
  @JsonProperty() subject: ActionIdentity;
}

@JsonObject()
export class ActionTexts {
  @JsonProperty() description?: string;
  @JsonProperty() extraDescription?: string;
  @JsonProperty() previousActionText?: string;
  @JsonProperty() extraInfo?: string;
}

@JsonObject()
export class FilterSortingData {
  // when defined, this is added to the action title in action lists
  @JsonProperty() actionLabel?: string;
  @JsonProperty() extraFilters?: string;
}

@JsonObject()
export class Action {
  @JsonProperty() id: DbId;
  @JsonProperty() processId: DbId;
  @JsonProperty() code: string;
  @JsonProperty() actionTexts: ActionTexts;
  @JsonProperty() status: ActionStatus;
  @JsonProperty() actionIdentities: ActionIdentities = new ActionIdentities();
  @JsonProperty() @JsonElementType(ActionDeadlines)  deadlines: ActionDeadlines = new ActionDeadlines();
  @JsonProperty() urgency?: ActionUrgency;
  @JsonProperty() @JsonElementType(Button) buttons: Button[];
  @JsonProperty() workflowProcessType: string;
  @JsonProperty() displayButtons: boolean = true;
  @JsonProperty() @JsonConverter(UTCInterpreter) startDate: LocalDateTime;
  @JsonProperty() actionFlowMetadata: ActionFlowMetadata = new ActionFlowMetadata();
  @JsonProperty() completionData?: CompletionData;
  @JsonProperty() translationKey?: string;
  @JsonProperty() nextActionDetails: NextActionDetails = new NextActionDetails();
  @JsonProperty() customActionProperties: CustomActionProperties = new CustomActionProperties();
  @JsonProperty() translationPostfix?: string;
  @JsonProperty() phoneNoteHistoryItemId?: DbId;
  // this field is not used, we only use it on the ActionTableData for now. It is included on the object for completeness' sake.
  @JsonProperty() filterSortingData?: FilterSortingData;
  @JsonProperty() version: number;

  public link?: IActionLink[];

  /**
   * Gets link for action, based on action type
   */
  public getLink(data: { [k: string]: any } = {}): void {
    this.displayButtons = true;

    if (!!this.workflowProcessType || this.customActionProperties.automaticCustomAction) {
      if (this.workflowProcessType?.split('_')[0] === ActionType.Quotation) {
        this.onQuotationAction(data);
      } else {
        this.onCourseAction(data);
      }
    }
  }

  public static getActionTakenTitle(actionTaken: string, actionCode: string): string {
    return actionTaken.replace(`${actionCode}_`, '');
  }

  /**
   * Defines links for actions for quotation flow
   */
  private onQuotationAction(data: { [k: string]: any }): void {
    const identityBaseUrl = `/identity/client/detail/${this.actionIdentities.subject.identity.id.id}/`;
    const identityServiceBaseUrl = `${identityBaseUrl}services/detail/${data['serviceId']}/`;
    const linkToQuotation = {
      path: `${identityServiceBaseUrl}action/${this.id.id}`,
      title: 'toQuotation'
    };

    switch (this.code) {
      case DetailedQuotationStatusCodes.NieuweOfferteAanvraag:
      case DetailedQuotationStatusCodes.OpstellenVerzenden:
      case DetailedQuotationStatusCodes.Opstellen:
      case DetailedQuotationStatusCodes.Verzenden:
      case OfferteBijnaVerlopenCode:
        this.link = [linkToQuotation];
        break;
      case DetailedQuotationStatusCodes.StatusWachtOpAkkoord:
        this.link = [linkToQuotation];
        this.link[0].title = this.link[0].title + 'ForApproval';
        this.displayButtons = false;
        break;
      case DetailedQuotationStatusCodes.Nakijken:
      case DetailedQuotationStatusCodes.Intake:
        this.link = [linkToQuotation];
        break;
      case DetailedQuotationStatusCodes.RegelenIntake:
        this.link = [linkToQuotation];
        break;
      case DetailedQuotationStatusCodes.Bellen:
        this.link = [{
          path: `${identityBaseUrl}history/details${this.phoneNoteHistoryItemId?.id ? '/' + this.phoneNoteHistoryItemId.id : ''}`,
          title: 'toPhoneNotes',
          queryParams: {actionId: this.id.id, category: HistoryItemCategory.PhoneNotes, serviceId: data['serviceId']}
        }];
        break;
      default: // nothing
        break;
    }
  }

  /**
   * Defines links for actions for course flow
   */
  private onCourseAction(data: { [k: string]: any }): void {
    const identityBaseUrl = `/identity/client/detail/${this.actionIdentities.subject.identity.id.id}/`;
    const identityServiceBaseUrl = `${identityBaseUrl}services/detail/${data['serviceId']}/`;

    const detailedCodes = [
      CourseActionCodes.PlanDienstverlening,
      CourseActionCodes.ResultaatBegeleiding,
      CourseActionCodes.KoppelTrajectassistent,
      CourseActionCodes.RapportageOpstellen,
      CourseActionCodes.RapportageNakijken,
      CourseActionCodes.RapportageVerzenden,
      CourseActionCodes.CheckWerkzaamheden,
      CourseActionCodes.WiaUitslagOpvragen
    ];

    const toReportsPageCodes: CourseActionCodes[] = [
      CourseActionCodes.TemporiseerBegeleiding,
      CourseActionCodes.HerstartBegeleiding,
      CourseActionCodes.ControleerPlanningWisselDienst
    ];

    if (this.code === CourseActionCodes.CheckVoortgang) {
      this.link = [{
        path: `${identityServiceBaseUrl}sessions/${this.id.id}`,
        title: 'toQuotation'
      }];
    } else if ([CourseActionCodes.OpdrachtgeverBellen, CourseActionCodes.ClientBellenOptions, CourseActionCodes.RapportageBellen]
        .includes(getCourseActionCode(this.code))) {
      this.link = [{
        path: `${identityBaseUrl}history/details${this.phoneNoteHistoryItemId?.id ? '/' + this.phoneNoteHistoryItemId.id : ''}`,
        title: 'toPhoneNotes',
        queryParams: {actionId: this.id.id, category: HistoryItemCategory.PhoneNotes, serviceId: data['serviceId']}
      }];
    } else if (this.code.includes('factuur')) {
      this.link = [{
        path: `${identityServiceBaseUrl}details/${this.id.id}`,
        title: 'toQuotationCommunication'
      }];
    } else if (toReportsPageCodes.includes(getCourseActionCode(this.code))) {
      this.link = [{
        path: `${identityServiceBaseUrl}reports/${this.id.id}`,
        title: 'toReports'
      }];
    } else if (Object.keys(detailedCodes).map((k) => detailedCodes[k]).includes(getCourseActionCode(this.code))) {
      this.link = [{
        path: `${identityServiceBaseUrl}action/${this.id.id}`,
        title: 'toQuotation'
      }];
    }
  }
}

@JsonObject()
export class ActionWithMetadata {
  @JsonProperty() action: Action;
  @JsonProperty() permissionBit: number;

  /**
   * Checks for an action which crud operations are allowed
   */
  updateActionIsPermitted(requiredPermission: number): boolean {
    return (this.permissionBit & requiredPermission) === requiredPermission;
  }
}

@JsonObject()
@JsonDiscriminatorProperty('type')
export abstract class Event {

  @JsonProperty() type: EventType;

  // Needs to be used to prevent it being seen as dead code by optimization
  static useMessageClasses(): void {
    const msg: Event = new ButtonEvent('');
  }
}

@JsonObject()
@JsonDiscriminatorValue(EventType.ButtonEventType)
export class ButtonEvent extends Event {

  @JsonProperty() buttonId: string;
  @JsonProperty() @JsonConverter(UTCInterpreter) date: LocalDateTime;
  // eslint-disable-next-line
  @JsonProperty() extra?: object;

  // eslint-disable-next-line
  constructor(buttonId: string, extra: object = null) {
    super();
    this.type = EventType.ButtonEventType;
    this.buttonId = buttonId;
    this.date = LocalDateTime.now();
    this.extra = extra;
  }
}

@JsonObject()
@JsonDiscriminatorValue(EventType.ButtonEventType)
export class BackEvent extends Event {

  @JsonProperty() actionId: {id: string};

  constructor(actionId: string) {
    super();
    this.type = EventType.BackEventType;
    this.actionId = {id: actionId};
  }
}

@JsonObject()
@JsonDiscriminatorValue(EventType.ButtonEventType)
export class AbortEvent extends Event {

  @JsonProperty() actionId: {id: string};

  constructor(actionId: string) {
    super();
    this.type = EventType.AbortEventType;
    this.actionId = {id: actionId};
  }
}

@JsonObject()
export class EventWithVersionNumber {

  @JsonProperty() event: Event;
  @JsonProperty() versionNumber: number;

  constructor(event: Event, version: number) {
    this.event = event;
    this.versionNumber = version;
  }
}

@JsonObject()
export class EventWithUpdatedAt {
  @JsonProperty() event: Event;
  @JsonProperty() @JsonConverter(UTCInterpreter) actionUpdatedAt: LocalDateTime;

  constructor(event: Event, actionUpdatedAt: LocalDateTime = null) {
    this.event = event;
    this.actionUpdatedAt = actionUpdatedAt;
  }
}

export interface IActionColumnData {
  id: DbId;
  title: string;
  deadlineAsString: string;
  description?: string;
  extraDescription?: string;
  urgency: ActionUrgency;
  subject?: string;
  subjectId?: number;
  owner?: string;
  planGroup?: string;
  ownerType: IdentityTypes;
  deadlineAsDate: LocalDate;
  updatedAt: LocalDateTime;
  showTooltip?: boolean;
  extraFilters?: string;
  version: number;
}

@JsonObject()
export class ActionTableData {
  @JsonProperty() id: DbId;
  @JsonProperty() code: string;
  @JsonProperty() description?: string;
  @JsonProperty() extraDescription?: string;
  @JsonProperty() translationKey?: string;
  @JsonProperty() nextActionDetails: NextActionDetailsDetails;
  @JsonProperty() @JsonElementType(ActionDeadlines) deadlines: ActionDeadlines;
  @JsonProperty() urgency: ActionUrgency;
  @JsonProperty() subject: IdentityDetails;
  @JsonProperty() owner: ActionIdentityDetails;
  @JsonProperty() @JsonConverter(UTCInterpreter) startDate: LocalDateTime;
  @JsonProperty() @JsonConverter(UTCInterpreter) updatedAt: LocalDateTime;
  @JsonProperty() completionData?: CompletionDataDetails;
  @JsonProperty() translationPostfix?: string;
  @JsonProperty() filterSortingData?: FilterSortingData;
  @JsonProperty() version: number;
}

@JsonObject()
export class ActionTableDataWithMetadata {
  @JsonProperty() actionTableData: ActionTableData;
  @JsonProperty() coach?: IdentityDetails;
  @JsonProperty() planner?: IdentityDetails;
}

export interface IIdentityActionColumnData {
  id: DbId;
  title: string;
  startDate: LocalDateTime;
  updatedAt: LocalDateTime;
  deadline: LocalDate;
  completedAt: LocalDateTime;
  completedBy?: string;
  description?: string;
  extraDescription?: string;
  showTooltip?: boolean;
}

@JsonObject()
export class ReportIdAndPreviousActionText {
  @JsonProperty() reportId: string;
  @JsonProperty() previousActionText: string;
  @JsonProperty() postfix?: string;
}

export function getReportBlockTranslationParams(reportId: string, postfix: string): any {
  const translationPostfix: string = reportId.includes('eind') ? (postfix ? postfix + '_eind': 'eind') : postfix;

  return {
    translatePrefix: 'actions.report.label.',
    translationKey: 'report',
    translationPostfix
  };
}
