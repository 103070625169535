import {Directive, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {LoadingService} from '../services/http/loading.service';
import {takeUntil} from 'rxjs/operators';

/**
 * Disables buttons during http requests.
 * If button should be disabled in its initial state (e.g. based on a certain condition),
 * this condition can be passed to 'disableDefault' property
 * https://stackoverflow.com/questions/47636879/disable-buttons-during-ajax-requests
 */
@Directive({
  selector: '[disableDuringRequest]'
})
export class DisableDuringRequestDirective implements OnInit, OnDestroy {

  @Input() set disableDefault(disabled: boolean) {
    this._defaultDisabled = disabled;
    // re-evaluate current disabled state
    this.loadingService.isBusy$.next(this.loadingService.isBusy$.value);
  }
  public _defaultDisabled: boolean = false;

  @HostBinding('disabled') isDisabled: boolean;

  private onDestroy$: Subject<void> = new Subject();

  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.isDisabled = this._defaultDisabled;
    this.checkRequestProgress();
  }

  checkRequestProgress(): void {
    this.loadingService.isBusy$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((res) => {
      this.isDisabled = res || this._defaultDisabled;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
