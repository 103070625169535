import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoginStateService} from 'app/services/login/loginstate.service';
import {Injectable} from '@angular/core';

/**
 * Intercept all requests to add the current user to the query parameters of this request.
 */
@Injectable()
export class ShowArchivedInterceptor implements HttpInterceptor {
  constructor(
    private _loginStateService: LoginStateService
  ) { }

  /**
   * Intercept requests to add a query parameter [[withArchivedData]] whose value is retrieved from localStorage.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // clone the current request
    const newRequest: HttpRequest<any> = req.clone({
      // update the query parameters to include the withArchivedData param
      params: req.params.set('withArchivedData', this._loginStateService.showArchived$.value)
    });
    return next.handle(newRequest);
  }
}
