import {Component, Input, OnInit} from '@angular/core';
import {ApprovalDataFormObject} from './approval-data-form-object';
import {IFormObjectFieldData} from '../../pages/identity/form-data-and-validation';
import {Naw} from '../../domain/identity/identitydata';

@Component({
  selector: 'app-approve-or-reject-data',
  templateUrl: './approve-or-reject-data.component.html'
})
export class ApproveOrRejectDataComponent implements OnInit {
  @Input() public naw: Naw = null;
  @Input() public isApprove: boolean;
  @Input() public isContactPerson = false;

  public form: ApprovalDataFormObject = new ApprovalDataFormObject();
  public privacyChecked: boolean = false;
  public conditionsChecked: boolean = false;
  public formValid: boolean = false;
  @Input() public showRequired: boolean = false;
  public valid: boolean = false;
  public readonly: boolean;
  public rejectReason: string;

  public formFields: IFormObjectFieldData[] = [
    {name: 'gender', required: true},
    {name: 'initials', required: false},
    {name: 'lastNameInfix', required: false},
    {name: 'lastName', required: true},
    {name: 'email', required: true}
  ];

  ngOnInit(): void {
    this.form = ApprovalDataFormObject.from(this.naw);
    this.readonly = !!this.form.id; // If the Naw already exists, we can't change its data.
    this.formValid = !!this.form.id;
  }

  onReasonChanged(reason: string): void {
    this.rejectReason = reason;
    this.checkValid();
  }

  updateForm(form: ApprovalDataFormObject): void {
    this.form = form;
  }

  updateFormValid(valid: boolean): void {
    this.formValid = valid;
    this.checkValid();
  }

  checkValid(): void {
    this.valid = (this.formValid || this.readonly) && (this.isApprove && this.privacyChecked && this.conditionsChecked
        || !this.isApprove && !!this.rejectReason);
  }
}
