import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {applyPolyfills, defineCustomElements} from 'ionicons/dist/loader';

if (environment.production) {
  enableProdMode();
}

// This enables ionicons to show by telling the Ionicons script to look for the svg directory within assets/ionicons/
// https://github.com/ionic-team/ionicons/issues/769
applyPolyfills().then(() => {
  defineCustomElements(window, {
    resourcesUrl: 'assets/ionicons/'
  });
});

platformBrowserDynamic().bootstrapModule(AppModule);
