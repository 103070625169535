<div>
  <span><strong>{{ 'nawForm.gender.title' | translate }}</strong></span>
  <div class="d-flex">
    <div *ngFor="let btn of genderBtns">
      <app-radio-button
          [name]="'gender'"
          [id]="'gender' + '_' + btn['value']"
          [checked]="_formObject.gender === btn['value']"
          [labelText]="btn['label']"
          [readonly]="readonly"
          (change)="formDataChanged('gender', btn['value']); formValidChanged('gender', true)">
      </app-radio-button>
    </div>
  </div>
  <div class="text-danger" [hidden]="!showReq('gender')">
    {{ 'input.required' | translate }}
  </div>

  <div class="d-flex flex-column justify-content-between">
    <app-input-field
        [placeholder]="'nawForm.name.initials.placeholder'"
        [required]="false"
        [showRequired]="showRequired"
        [pattern]="regexRequired"
        [errorText]="'nawForm.name.initials.errorText'"
        [data]="_formObject.initials"
        [disabled]="readonly"
        (dataChange)="formDataChanged('initials', $event)"
        (validChange)="formValidChanged('initials', $event)">
    </app-input-field>
    <app-input-field
        [placeholder]="'nawForm.name.lastNameInfix.placeholder'"
        [required]="false"
        [showRequired]="showRequired"
        [pattern]="regexRequired"
        [errorText]="'nawForm.name.lastNameInfix.errorText'"
        [data]="_formObject.lastNameInfix"
        [disabled]="readonly"
        (dataChange)="formDataChanged('lastNameInfix', $event)"
        (validChange)="formValidChanged('lastNameInfix', $event)">
    </app-input-field>
    <app-input-field
        [placeholder]="'nawForm.name.lastName.placeholder'"
        [required]="true"
        [showRequired]="showRequired"
        [pattern]="regexRequired"
        [errorText]="'nawForm.name.lastName.errorText'"
        [data]="_formObject.lastName"
        [disabled]="readonly"
        (dataChange)="formDataChanged('lastName', $event)"
        (validChange)="formValidChanged('lastName', $event)">
    </app-input-field>
    <app-input-field
        [placeholder]="'nawForm.email.placeholder'"
        [required]="true"
        [showRequired]="showRequired"
        [pattern]="regexEmail"
        [errorText]="'nawForm.email.errorText'"
        [data]="_formObject.email"
        [disabled]="readonly"
        (dataChange)="formDataChanged('email', $event)"
        (validChange)="formValidChanged('email', $event)">
    </app-input-field>
  </div>
</div>
