<div class="container mt-3">
  <div class="card">
    <div class="card-header bg-transparent">
      <p>{{'quotationApproveOrReject.' + translatePrefix + (approvedOrRejected ? 'success.title' : 'title') | translate}}</p>
    </div>
    <div class="card-body">
      <ng-container *ngIf="approveIdExists; else noApproveId">
        <ng-container *ngIf="approveOrRejectPending; else doneApproving">
          <p>{{ 'quotationApproveOrReject.' + translatePrefix + 'approvalData.message' | translate }}</p>
          <app-approve-or-reject-data *ngIf="!loading"
                                      [naw]="currentNaw"
                                      [showRequired]="showRequired"
                                      [isApprove]="isApprove"
          ></app-approve-or-reject-data>
          <button class="btn btn-fill float-end" (click)="onApproveOrReject()" disableDuringRequest>
            {{ 'quotationApproveOrReject.' + translatePrefix + 'approvalData.buttons.accept' | translate }}
          </button>
        </ng-container>
        <ng-template #doneApproving>
          <p *ngIf="!approvedOrRejected"
             [innerHTML]="'quotationApproveOrReject.' + translatePrefix + errorText | translate"></p>
          <p *ngIf="approvedOrRejected"
             [innerHTML]="'quotationApproveOrReject.' + translatePrefix + 'success.message' | translate"></p>
        </ng-template>
      </ng-container>

      <ng-template #noApproveId>
        <p [innerHTML]="'quotationApproveOrReject.' + translatePrefix + errorText | translate"></p>
      </ng-template>
    </div>
  </div>
</div>
