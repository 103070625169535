import {Injectable} from '@angular/core';

import {AppAccessToken, UserWithPermissions} from 'app/domain/userdata';

import {BehaviorSubject, Observable} from 'rxjs';
import {JSON} from 'ta-json';

/**
 * Keeps track of current user and token
 * Required for preventing circular dependency of authorization
 * service and http-options service.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginStateService {
  // must be an observable for the http-options service to be able to subscribe
  // the header of http requests to the user authentication token
  public auth$: BehaviorSubject<AppAccessToken>;

  public currentUser$: BehaviorSubject<UserWithPermissions> = new BehaviorSubject(null);

  // key of token in local storage
  private tokenKey: string = 'token';

  // Archive settings
  private readonly showArchivedLocalStorageKey: string = 'showArchivedLocalStorageKey';
  public showArchived$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(localStorage.getItem(this.showArchivedLocalStorageKey) === 'true');

  constructor() {
    this.auth$ = new BehaviorSubject(this.getFromLocalStorage());
    this.auth$.subscribe(token => {
        this.writeToLocalStorage.bind(this)(token);
        // set showArchivedLocalStorageKey to false in local storage when the user logs out (`null` is pushed on auth$ on logout).
        if (token === null) {
          this.removeShowArchivedFromLocalStorage();
        }
      }
    );

    this.currentUser$.subscribe(u => {
      if (u?.user && !u?.user?.isInternalUser()) {
        this.setShowArchivedInLocalStorage();
      }
    });
  }

  /**
   * Read auth info from local storage.
   */
  public getFromLocalStorage(): AppAccessToken | null {
    const token = localStorage.getItem(this.tokenKey);

    if (token) {
      try {
        return JSON.deserialize(JSON.parse(token), AppAccessToken);
      } catch (e) {
        return null;
      }
    }

    return null;
  }

  public removeShowArchivedFromLocalStorage() {
    this.showArchived$.next(false);
    localStorage.removeItem(this.showArchivedLocalStorageKey);
  }

  public setShowArchivedInLocalStorage() {
    this.showArchived$.next(true);
    localStorage.setItem(this.showArchivedLocalStorageKey, 'true');
  }

  public hasArchivingPermission(): boolean {
    return this.currentUser$.value.permissions.map(p => p.operation.name).includes('archived.update');
  }

  /**
   * Write auth info to localstorage.
   */
  private writeToLocalStorage(token: AppAccessToken): void {
    if (token !== null) {
      localStorage.setItem(this.tokenKey, JSON.stringify(token));
    } else {
      this.removeFromLocalStorage();
    }
  }

  /**
   * Remove auth info from localstorage
   */
  private removeFromLocalStorage(): void {
    localStorage.removeItem(this.tokenKey);
  }
}
