import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {ChronoUnit, LocalDate, LocalDateTime, TemporalUnit} from '@js-joda/core';

export function toString(date: NgbDateStruct): string {
  return `${date.day}-${date.month}-${date.year}`;
}

export function instanceOf(date: any): boolean {
  return date instanceof Object && 'year' in date && 'month' in date && 'day' in date;
}

export function compareTo(date1: NgbDateStruct, date2: NgbDateStruct): number {
  if (!date2) {
    return 1;
  } else if (!date1) {
    return -1;
  } else if (date1.year > date2.year) {
    return 1;
  } else if (date1.year < date2.year) {
    return -1;
  } else if (date1.month > date2.month) {
    return 1;
  } else if (date1.month < date2.month) {
    return -1;
  } else if (date1.day > date2.day) {
    return 1;
  } else if (date1.day < date2.day) {
    return -1;
  } else {
    return 0;
  }
}

export function toDate(ngbDate: NgbDateStruct): LocalDate {
  return catchErrorAndReturnNull<LocalDate>(!!ngbDate, () => LocalDate.of(ngbDate.year, ngbDate.month, ngbDate.day));
}

export function toDateTime(ngbDate: NgbDateStruct, ngbTime: NgbTimeStruct = {hour: 0, minute: 0, second: 0}): LocalDateTime {
  return catchErrorAndReturnNull<LocalDateTime>(
    !!ngbDate,
    () => LocalDateTime.of(ngbDate.year, ngbDate.month, ngbDate.day, ngbTime.hour, ngbTime.minute, ngbTime.second)
  );
}

export function fromDate(date: LocalDate): NgbDateStruct {
  // javascript month is 0 based, bootstrap struct 1 based
  return date ? {day: date.dayOfMonth(), month: date.monthValue(), year: date.year()} : null;
}

export function fromDateTime(date: LocalDateTime): NgbDateStruct {
  // javascript month is 0 based, bootstrap struct 1 based
  return date ? {day: date.dayOfMonth(), month: date.monthValue(), year: date.year()} : null;
}

export function timeFromDateTime(date: LocalDateTime): NgbTimeStruct {
  return date ? {hour: date.hour(), minute: date.minute(), second: date.second()} : null;
}

/**
 * Calculate duration in days (diff between start and end) between two dates.
 */
export function diffInDays(date1: LocalDate, date2: LocalDate): number {
  return catchErrorAndReturnNull<number>(!!date1 && !!date2, () => ChronoUnit.DAYS.between(date1, date2));
}

function catchErrorAndReturnNull<T>(valid: boolean, returnFunc: () => T): T {
  if (valid) {
    try {
      return returnFunc();
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
}
