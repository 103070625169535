import {JsonObject, JsonProperty} from 'ta-json';

@JsonObject()
export class DbId {
  @JsonProperty() id: number;

  static from(id?: number): DbId {
    const dbId = new DbId();
    if (!id) {
      dbId.id = 0;
    } else {
      dbId.id = id;
    }
    return dbId;
  }
}
