// This config file contains the environment setting for
// Production

import { versions, backendUrl, assetsFolder, versionCheck, owoActive, owoFrontendUrl } from './settings';

export const environment = {
  production: true,
  backendUrl,
  apiVersion: '/v1',
  assetsFolder,
  envName: 'Production',
  sentryDSN: 'https://8e37672c9ca9440eb5fb29812641a05a@sentry.eaglescience.nl/7',
  versions,
  versionCheckUrl: versionCheck,
  owoActive,
  owoFrontendUrl
};
