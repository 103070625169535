import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ButtonType} from '../../domain/button/button-type';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {

  @Input() public translatePrefix: string;
  @Input() public arguments: string;
  @Input() public typeOfButton: ButtonType = ButtonType.BtnPrimary;
  @Input() public canClose: boolean = true;
  @Input() public mustAnswer: boolean = false;
  @Input() public confirmVisible: boolean = true;

  @Input() public set messageParams(params: { [k: string]: any }) {
    // handles possible translation of message parameters
    const newParams = {};
    Object.keys(params).forEach((k) => {
      // Only translate if param value exists and is not an empty string, otherwise translateService.instant throws error
      if (params[k]) {
        newParams[k] = this.translateService.instant(params[k]);
      }
    });

    this._messageParams = newParams;
  }

  public _messageParams: { [k: string]: any };

  public title: SafeHtml;
  public message: SafeHtml;
  public onClose: Subject<boolean> = new Subject();

  constructor(
    private _bsModalRef: BsModalRef,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    // necessary because innerHTML in template doesn't accept string concatenation
    this.translateService.get(`${this.translatePrefix}.title`).subscribe((title) =>
      this.title = this.sanitizer.bypassSecurityTrustHtml(title)
    );
    this.translateService.get(`${this.translatePrefix}.message`, this._messageParams).subscribe((message) =>
      this.message = this.sanitizer.bypassSecurityTrustHtml(message)
    );
  }

  onButtonClicked(accepted: boolean): void {
    if (accepted) {
      this.onClose.next(true);
      this._bsModalRef.hide();
    } else {
      this.onClose.next(false);
      this._bsModalRef.hide();
    }
  }
}
