import {Component, Input} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-rich-text-modal',
  templateUrl: './rich-text-modal.component.html'
})
export class RichTextModalComponent {

  @Input() public translatePrefix: string;
  @Input() public editorData: string;
  @Input() public typeOfButton: string = 'btn-primary';
  @Input() public readonly: boolean = false;

  public valid: boolean = true;

  // Emits (true, <text>) when the modal has been confirmed, or (false, <null>) when it has been declined.
  public onClose: Subject<[boolean, string]> = new Subject();

  constructor(private _bsModalRef: BsModalRef) {}

  onButtonClicked(accepted: boolean): void {
    if (accepted) {
      if (this.valid) {
        this.onClose.next([true, this.editorData]);
        this._bsModalRef.hide();
      }
    } else {
      this.onClose.next([false, null]);
      this._bsModalRef.hide();
    }
  }

  setValid(valid: boolean): void {
    this.valid = valid;
  }
}
