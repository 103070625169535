import {Injectable} from '@angular/core';
import {MsgraphService} from '../msgraph/msgraph.service';
import {FileService, FileServiceType} from '../file.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * Service implementation for global documents
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentService extends FileService {
  public serviceType: FileServiceType = FileServiceType.Document;

  constructor(public msGraphService: MsgraphService, public translateService: TranslateService) {
    super(msGraphService, translateService);
  }
}
