import {Component, Input} from '@angular/core';
import {FormComponent} from '../form/form.component';
import {regexEmail, regexRequired} from '../../domain/regex';
import {Genders} from '../../domain/identity/identitydata';
import {ApprovalDataFormObject} from './approval-data-form-object';

@Component({
  selector: 'app-approve-or-reject-data-form',
  templateUrl: './approve-or-reject-data-form.component.html',
  styleUrls: ['./approve-or-reject-data-form.component.scss']
})
export class ApproveOrRejectDataFormComponent extends FormComponent<ApprovalDataFormObject> {
  public regexEmail: RegExp = regexEmail;
  public regexRequired: RegExp = regexRequired;

  public genderBtns: {[k: string]: string}[] = [
    {
      value: Genders.Male,
      label: 'nawForm.gender.male'
    },
    {
      value: Genders.Female,
      label: 'nawForm.gender.female'
    }
  ];
}
