import {JsonConverter, JsonObject, JsonProperty} from 'ta-json';
import {CompanyFormObject} from '../../components/company-form/company-form-object';
import {NawFormObject} from '../../components/naw-form/naw-form-object';
import {IdentityTypes, internalIdentityTypes} from './identitytypes';
import {toDate} from '../../utils/ngbDateStructUtils';
import {DateInterpreter, UTCInterpreter} from '../utcinterpreter';
import {DbId} from '../dbid';
import {Asset} from '../assets';
import {ApprovalDataFormObject} from '../../components/approval-data/approval-data-form-object';
import {LocalDate, LocalDateTime} from '@js-joda/core';
import {ArchiveData} from '../archiveData';

export enum Genders {
  Male = 'male',
  Female = 'female'
}

@JsonObject()
export class Company {
  @JsonProperty() id: DbId;
  @JsonProperty() public name: string;
  @JsonProperty() public street?: string;
  @JsonProperty() public streetNumber?: string;
  @JsonProperty() public city?: string;
  @JsonProperty() public postalCode?: string;
  @JsonProperty() public establishmentInfo?: string;

  static from(form: CompanyFormObject, id?: number): Company {
    const company = new Company();
    company.id = DbId.from(id);
    company.name = form.name;
    company.street = form.street;
    company.streetNumber = form.streetNumber;
    company.city = form.city;
    company.postalCode = form.postalCode;
    company.establishmentInfo = form.establishmentInfo;
    return company;
  }
}

@JsonObject()
export class CompanyDetails {
  @JsonProperty() id: DbId;
  @JsonProperty() public name: string;
}

@JsonObject()
export class Maatwerk {
  @JsonProperty() private derivedMaatwerk?: string;
  @JsonProperty() private maatwerkIdentityId?: number;
  @JsonProperty() private maatwerk?: string;

  public getMaatwerk(identityId: number): string {
    if (this.canEditMaatwerk(identityId)) {
      return this.maatwerk;
    } else {
      return this.derivedMaatwerk;
    }
  }

  public canEditMaatwerk(identityId: number): boolean {
    return !this.maatwerkIdentityId || this.maatwerkIdentityId === identityId;
  }

  public strip(): Maatwerk {
    const copy = Object.assign(new Maatwerk(), this);
    copy.derivedMaatwerk = null;
    return copy;
  }
}

export enum CarOptionType {
  Yes = 'yes',
  No = 'no',
  Sometimes = 'sometimes'
}

@JsonObject()
export class Naw {
  @JsonProperty() id: DbId;
  @JsonProperty() public firstName?: string;
  @JsonProperty() public lastName?: string;
  @JsonProperty() public initials?: string;
  @JsonProperty() public lastNameInfix?: string;
  @JsonProperty() public gender: Genders;
  @JsonProperty() @JsonConverter(DateInterpreter) public birthDate?: LocalDate;
  @JsonProperty() public email?: string;
  @JsonProperty() public phoneNumber?: string;
  @JsonProperty('function') public jobTitle?: string;
  @JsonProperty() public street?: string;
  @JsonProperty() public streetNumber?: string;
  @JsonProperty() public city?: string;
  @JsonProperty() public postalCode?: string;
  @JsonProperty() public privateEmail?: string;
  @JsonProperty() public privatePhoneNumber?: string;
  @JsonProperty() public hasCar?: CarOptionType;
  @JsonProperty() public picture?: Asset;
  @JsonProperty() public profile?: Asset;

  static getBaseNaw(form: NawFormObject | ApprovalDataFormObject, id?: number): Naw {
    const naw = new Naw();
    naw.id = DbId.from(id);
    naw.gender = form.gender;
    naw.initials = form.initials;
    naw.lastNameInfix = form.lastNameInfix;
    naw.lastName = form.lastName;
    naw.email = form.email;

    return naw;
  }

  static from(form: NawFormObject, id?: number, picture?: Asset, profile?: Asset): Naw {
    const naw = this.getBaseNaw(form, id);
    naw.firstName = form.firstName;
    naw.birthDate = toDate(form.birthDate);
    naw.phoneNumber = form.phoneNumber;
    naw.street = form.street;
    naw.streetNumber = form.streetNumber;
    naw.city = form.city;
    naw.postalCode = form.postalCode;
    naw.jobTitle = form.jobTitle;
    naw.privateEmail = form.privateEmail;
    naw.privatePhoneNumber = form.privatePhoneNumber;
    naw.hasCar = form.hasCar;
    naw.picture = picture;
    naw.profile = profile;

    return naw;
  }

  static fromApprovalDataFormObject(form: ApprovalDataFormObject, id?: number): Naw {
    return this.getBaseNaw(form, id);
  }
}

@JsonObject()
export class NawDetails {
  @JsonProperty() id: DbId;
  @JsonProperty() public lastName?: string;
  @JsonProperty() public email?: string;
  @JsonProperty() public phoneNumber?: string;
  @JsonProperty() public city?: string;
  @JsonProperty('function') public jobTitle?: string;
}

@JsonObject()
export class NawIdentity {
  @JsonProperty() id: DbId;
  @JsonProperty() idType: IdentityTypes;
  @JsonProperty() company?: number;
  @JsonProperty() naw?: Naw;
  @JsonProperty() owner: number;
  @JsonProperty() parent: number;
  @JsonProperty() hasAccount: boolean;
  @JsonProperty() isActive: boolean;
  @JsonProperty() isValid: boolean;
  @JsonProperty() maatwerk?: Maatwerk;
  @JsonProperty() additionalInfo?: string;
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt?: LocalDateTime = null;
  @JsonProperty() autoApproveQuotations?: boolean = false;
  @JsonProperty() referrerId?: number;
  @JsonProperty() employerId?: number;
  @JsonProperty() isNew?: boolean;
  @JsonProperty() linkedPlanner?: number;
}

@JsonObject()
export class IdentityFullNames {
  @JsonProperty() normal: string;
  @JsonProperty() alternate: string;
  @JsonProperty() alternateCompanyFallback: string;
  @JsonProperty() company: string;
  @JsonProperty() companyAlternate: string;
}

@JsonObject()
export class Identity {
  @JsonProperty() id: DbId;
  @JsonProperty() idType: IdentityTypes;
  @JsonProperty() fullNames?: IdentityFullNames;
  @JsonProperty() company?: Company;
  @JsonProperty() naw?: Naw;
  @JsonProperty() owner: number;
  @JsonProperty() parent: number;
  @JsonProperty() hasAccount: boolean;
  @JsonProperty() isActive: boolean;
  @JsonProperty() isValid: boolean;
  @JsonProperty() maatwerk?: Maatwerk;
  @JsonProperty() additionalInfo?: string;
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt?: LocalDateTime = null;
  @JsonProperty() autoApproveQuotations?: boolean = false;
  @JsonProperty() referrerId?: number;
  @JsonProperty() employerId?: number;
  @JsonProperty() isNew?: boolean;
  @JsonProperty() linkedPlanner?: NawIdentity;
  @JsonProperty() archiveData?: ArchiveData;
  @JsonProperty() owoId?: string;

  static from(
    idType: IdentityTypes,
    identityId: number,
    naw?: Naw,
    company?: Company,
    id?: number,
    autoApproveQuotations: boolean = false
  ): Identity {
    const identity = new Identity();
    identity.id = DbId.from(id);
    identity.idType = idType;
    identity.company = company;
    identity.naw = naw;
    identity.owner = identityId;
    identity.autoApproveQuotations = autoApproveQuotations;
    return identity;
  }

  public isInternal(): boolean {
    return Object.keys(internalIdentityTypes).map((k) => internalIdentityTypes[k]).includes(this.idType);
  }

  public toNawIdentity(): NawIdentity {
    return Object.assign(new NawIdentity(),
      {
        id: this.id,
        idType: this.idType,
        company: this.company ? this.company.id.id : null,
        naw: this.naw,
        owner: this.owner,
        parent: this.parent,
        hasAccount: this.hasAccount,
        isActive: this.isActive,
        isValid: this.isValid,
        maatwerk: this.maatwerk,
        additionalInfo: this.additionalInfo,
        autoApproveQuotations: this.autoApproveQuotations,
        referrerId: this.referrerId,
        employerId: this.employerId,
        isNew: this.isNew,
        linkedPlanner: this.linkedPlanner
      });
  }

  public strip(): Identity {
    const copy = Object.assign(new Identity(), this);
    copy.fullNames = null;
    copy.linkedPlanner = null;
    copy.maatwerk = copy.maatwerk.strip();
    return copy;
  }
}

export interface IRoleData {
  img: string;
  type: IdentityTypes;
}

export interface IPlangroupColumnData {
  id: number;
  name: string;
  lastName: string;
  email?: string;
  role?: IdentityTypes;
  city?: string;
  phoneNumber?: string;
}

export enum IdentityListTypes {
  SiblingList = 'SiblingList',
  MainList = 'MainList',
  MainListAll = 'MainListAll',
  OpdrachtgeverList = 'OpdrachtgeverList',
  OpdrachtgeverListAll = 'OpdrachtgeverListAll',
  InternList = 'InternList',
  InternListAll = 'InternListAll'
}


