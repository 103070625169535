import {ListSettings} from '../components/list/list-settings';
import {Observable} from 'rxjs';
import {JSON} from 'ta-json';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings/settings.service';

export function getCount$(
  listSettings: ListSettings,
  url: string,
  httpService: HttpClient,
  settingsService: SettingsService
): Observable<number> {
  return httpService.post(
    settingsService.backendUrl(`${url}/count`),
    JSON.serialize(listSettings)
  ).pipe(map((items) => JSON.deserialize<number>(items, Number)));
}

export function getAutoFilterItems$(
  url: string,
  viewFieldId: string,
  sortFieldId: string,
  httpService: HttpClient,
  settingsService: SettingsService
): Observable<string[]> {
  return httpService.get(
    settingsService.backendUrl(`${url}/autoFilterItems/${viewFieldId}`), {params: sortFieldId ? {sortFieldId} : null})
    .pipe(map((result) => JSON.deserialize<string[]>(result)));
}

export const pageSize: number = 25;
export const maxPageNumbersShown: number = 10;
