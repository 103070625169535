import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ButtonType} from '../../domain/button/button-type';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-disabled-modal',
  templateUrl: './disabled-modal.component.html'
})
export class DisabledModalComponent implements OnInit {

  @Input() public translatePrefix: string;
  @Input() public typeOfButton: ButtonType = ButtonType.BtnPrimary;

  public title: SafeHtml;
  public message: SafeHtml;
  public onClose: Subject<boolean> = new Subject();

  constructor(
    private _bsModalRef: BsModalRef,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    // necessary because innerHTML in template doesn't accept string concatenation
    this.translateService.get(`${this.translatePrefix}.title`).subscribe((title) =>
        this.title = this.sanitizer.bypassSecurityTrustHtml(title)
    );
    this.translateService.get(`${this.translatePrefix}.message`).subscribe((message) =>
      this.message = this.sanitizer.bypassSecurityTrustHtml(message)
    );
  }

  onButtonClicked(): void {
      this.onClose.next(false);
      this._bsModalRef.hide();
  }

}
