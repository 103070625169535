import {JsonObject, JsonProperty} from 'ta-json';
import {Identity} from './identitydata';
import { ServiceStatus } from '../service/service-status';

@JsonObject()
export class IdentityWithMetadata {
  @JsonProperty() identity: Identity;
  @JsonProperty() serviceName: string = null;
  @JsonProperty() serviceStatus: ServiceStatus = null;
  @JsonProperty() coach?: Identity = null;
  @JsonProperty() serviceId: number = null;
  @JsonProperty() serviceOwner: Identity = null;
  @JsonProperty() serviceEmployer: Identity = null;
  @JsonProperty() serviceTrajectAssistent?: Identity = null;
  @JsonProperty() strips: number = null;
}
