import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * Very simple component that can toggle a boolean switch (used to show/hide passwords) by clicking an interactive icon.
 */
@Component({
  selector: 'app-show-password-eye-icon',
  templateUrl: './show-password-eye-icon.component.html'
})
export class ShowPasswordEyeIconComponent {
  @Input() public showPassword: boolean = false;
  @Output() public showPasswordChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
    this.showPasswordChange.emit(this.showPassword);
  }
}
