// this file is automatically generated by git.settings.ts script
// eslint-disable
export const versions = {
  version: '2.2.4',
  revision: '5b569d38541a65891c573a082c633862ae213527',
  branch: 'refs/tags/release/240910_v2.2.4'
 };
export const backendUrl = 'https://powerengine-api.power4people.nl/api';
export const assetsFolder = 'assets5b569d38541a65891c573a082c633862ae213527';
export const versionCheck = 'https://powerengine.power4people.nl/version.json';
export const owoActive = false;
export const owoFrontendUrl = 'https://onlinewerkomgeving.power4people.nl';
