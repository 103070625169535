import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {QuotationRejectReason} from '../../domain/service/service-data';

@Component({
  selector: 'app-reject-reasons',
  templateUrl: './reject-reasons.component.html'
})
export class RejectReasonsComponent {
  @Input() showRequired: boolean = false;
  @Input() required: boolean = false;

  @Output() public reasonChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public customReasonChanged: EventEmitter<string> = new EventEmitter<string>();

  public translatePrefix: string = 'quotationApproveOrReject.reject';
  public reasons: string[] = Object.keys(QuotationRejectReason).map((k) => QuotationRejectReason[k]);
  public chosenReason: string;
  public customChosenReason: string;
  public onClose: Subject<string> = new Subject();
  public showReasonInput: boolean = false;

  constructor() {}

  chooseReasonToReject(reason: string): void {
    this.chosenReason = reason;
    this.showReasonInput = !this.chosenReason;

    this.reasonChanged.emit(this.chosenReason);
  }
}
