import {Injectable} from '@angular/core';
import {SettingsService} from '../settings/settings.service';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {Category, CourseWorkflow, Product} from '../../domain/product/product-data';
import {MsgraphService} from '../msgraph/msgraph.service';
import {FileService, FileServiceType} from '../file.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * Crud service implementation for products
 */
@Injectable({
  providedIn: 'root',
})
export class ProductService extends FileService {
  public serviceType: FileServiceType = FileServiceType.Product;

  constructor(public httpService: HttpClient,
              private settingsService: SettingsService,
              public msGraphService: MsgraphService,
              public translateService: TranslateService
  ) {
   super(msGraphService, translateService);
  }

  verifyProductSlug(slug: string, mustBeValid: boolean = true): Observable<boolean> {
    return this.httpService.get(this.settingsService.backendUrl(`/register/${slug}/${mustBeValid}`))
      .pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
  }

  get(id: number): Observable<Product> {
    return this.httpService.get(this.settingsService.backendUrl(`/product/${id}`))
      .pipe(map((result) =>
        JSON.deserialize<Product>(result, Product)));
  }

  create(newProduct: Product, categoryId: number): Observable<number> {
    return this.httpService.post(this.settingsService.backendUrl(`/category/${categoryId}/product`), JSON.serialize(newProduct))
      .pipe(map((result) => JSON.deserialize<number>(result, Number)));
  }

  update(updatedProduct: Product): Observable<boolean> {
    return this.httpService.put(this.settingsService.backendUrl(`/product`), JSON.serialize(updatedProduct))
      .pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
  }

  archiveProduct(productId: number): Promise<boolean> {
    return firstValueFrom(this.httpService.put(
        this.settingsService.backendUrl(`/product/archive/${productId}`), {}).pipe(map(_ => null)));
  }

  unarchiveProduct(productId: number): Promise<void> {
    // we only need to know if the promise succeeds or fails, so just map the result to `null`.
    return firstValueFrom(
        this.httpService.put(
            this.settingsService.backendUrl(`/product/unArchive/${productId}`), {}).pipe(map(_ => null)
        )
    );
  }

  getAllCategories(withValidProducts: boolean = true): Observable<Category[]> {
    return this.httpService.get(this.settingsService.backendUrl(`/categories${withValidProducts ? '/valid' : ''}`))
      .pipe(map((result) => JSON.deserialize<Category[]>(result, Category)));
  }

  getCategory(id: number): Observable<Category> {
    return this.httpService.get(this.settingsService.backendUrl(`/category/${id}`))
      .pipe(map((result) => JSON.deserialize<Category>(result, Category)));
  }

  createCategory(newCategory: Category): Observable<Category> {
    return this.httpService.post(this.settingsService.backendUrl(`/category`), JSON.serialize(newCategory))
      .pipe(map((result) => JSON.deserialize<Category>(result, Category)));
  }

  updateCategory(updatedCategory: Category): Observable<boolean> {
    return this.httpService.put(this.settingsService.backendUrl(`/category`), JSON.serialize(updatedCategory))
      .pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
  }

  archiveCategory(categoryId: number): Promise<boolean> {
    return firstValueFrom(this.httpService.put(
        this.settingsService.backendUrl(`/category/archive/${categoryId}`), {}).pipe(map(_ => null)));
  }

  unarchiveCategory(categoryId: number): Promise<void> {
    // we only need to know if the promise succeeds or fails, so just map the result to `null`.
    return firstValueFrom(
        this.httpService.put(
            this.settingsService.backendUrl(`/category/unArchive/${categoryId}`), {}).pipe(map(_ => null)
        )
    );
  }

  getAllCourseWorkflows(): Observable<CourseWorkflow[]> {
    return this.httpService.get(this.settingsService.backendUrl(`/product/courseWorkflows`))
      .pipe(map((result) => JSON.deserialize<CourseWorkflow[]>(result, CourseWorkflow)));
  }

  setExtensionProduct$(id: number): Observable<boolean> {
    return this.httpService.put(this.settingsService.backendUrl(`/product/extensionProduct/${id}`), {})
      .pipe(map((result) => JSON.deserialize<boolean>(result, Boolean)));
  }

  getExtensionProduct$(): Observable<Product | null> {
    return this.httpService.get(this.settingsService.backendUrl(`/product/extensionProduct`))
      .pipe(map((result) => {
        if (result) {
          return JSON.deserialize<Product>(result, Product);
        } else {
          return null;
        }
      }));
  }

  getAllExtensionProducts(): Observable<Product[]> {
    return this.httpService.get(this.settingsService.backendUrl(`/product/getAllExtensionProducts`))
        .pipe(map((result) => JSON.deserialize<Product[]>(result, Product)));
  }
}
