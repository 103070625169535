import {JsonConverter, JsonElementType, JsonObject, JsonProperty} from 'ta-json';
import {UTCInterpreter} from '../utcinterpreter';
import {DbId} from '../dbid';
import {Naw} from '../identity/identitydata';
import {Asset} from '../assets';
import {ListButton} from '../list/list-data';
import {DriveFileTypes} from '../drive-item-ids';
import {LocalDateTime} from '@js-joda/core';

// The order of these values should not be altered, since P4P requested it to be ordered this way for the categories
// dropdown in history-details.component.html
export enum HistoryItemCategory {
  Documents = 'documents',
  Mails = 'mails',
  AutomaticMails = 'automaticMails',
  PhoneNotes = 'phoneNotes',
  InternalMemo = 'internalMemo',
  GlobalDocuments = 'globalDocuments',
  Quotation = 'quotation',
  Reports = 'reports',
  ServiceAction = 'serviceAction',
  ActionChange = 'actionChange',
  Other = 'other',
  Empty = 'empty'
}

export enum MailSendStatus {
  NotSent = 'notSent',
  Sent = 'sent',
  Sending = 'sending'
}

@JsonObject()
export class HistoryItem {
  @JsonProperty() public id: DbId;
  @JsonProperty() public title: string;
  @JsonProperty() public subjectId: number;
  @JsonProperty() public customItem: boolean;
  @JsonProperty() public category: HistoryItemCategory;
  @JsonProperty() public description?: string;
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt?: LocalDateTime;
  @JsonProperty() @JsonConverter(UTCInterpreter) public updatedAt?: LocalDateTime;
  @JsonProperty() public updatedBy?: Naw;
  @JsonProperty() @JsonElementType(Asset) public assets: Asset[] = [];
  @JsonProperty() public serviceId?: number;
  @JsonProperty() public actionId?: DbId;
  @JsonProperty() public driveItemId?: string;
  @JsonProperty() public driveFileType?: DriveFileTypes;
  @JsonProperty() public driveFileSending?: MailSendStatus = MailSendStatus.NotSent;
  @JsonProperty() public emailTitle?: string;
  @JsonProperty() public emailContent?: string;
  @JsonProperty() public emailRecipient?: string;

  getDriveFileSending(): MailSendStatus {
    if (this.category === HistoryItemCategory.GlobalDocuments) {
      return this.driveFileSending;
    } else {
     return null;
    }
  }

  driveFileSent(): boolean {
    return this.category === HistoryItemCategory.GlobalDocuments && this.driveFileSending !== MailSendStatus.NotSent;
  }
}

export interface IHistoryColumnData {
  id: number;
  title: string;
  createdAt: LocalDateTime;
  updatedAt: LocalDateTime;
  updatedBy: string;
  updatedByLastName?: string;
  category: HistoryItemCategory;
  customItem: boolean;
  subjectId: number;
  driveFileSending: string;
  attachments: string[];
  recipient: string;
  actions: ListButton<IHistoryColumnData>[];
}
