<div class="d-flex flex-row">
  <div class="input-group">
    <label class="input-group-btn" *ngIf="!disabled">
      <span class="btn btn-default btn-small ms-0 clickable">
        <!-- setting fileInput.value to null is required to allow the same file to be uploaded, deleted and uploaded again. -->
          {{ labelKey | translate }}&hellip;<input #fileInput
            type="file"
            style="display: none;"
            (change)="onFileChange($event); fileInput.value = null"
            [disabled]="loading"
            accept="{{ acceptedExtensions}}">
      </span>
    </label>
    <input type="text" *ngIf="!hideInput"
      class="form-control"
      readonly
      disabled="disabled"
      value="{{ fileName }}" />
  </div>
</div>

<div class="text-danger" [hidden]="valid">
  {{ 'files.sizeError' | translate: {size: maxFileSize} }}
</div>
