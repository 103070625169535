 import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// public pages
import { LoginComponent } from 'app/pages/login/login.component';

// Guards
import {LoggedInGuard} from 'app/services/login/loggedin.guard';

// Layout
import {LayoutComponent} from 'app/layouts/layout.component';
import {ProductExistsGuard} from './services/product/product-exists.guard';
import {ApproveOrRejectQuotationComponent} from './pages/approve_quotation/approve-or-reject-quotation.component';
import {OpenFileComponent} from './components/open-file/open-file.component';
import {PEditorComponent} from './components/p-editor/p-editor.component';
import {HideForProductionGuard} from './services/hide-for-production.guard';
import {HasPermissionGuard} from 'app/services/login/has-permission.guard';

// constant variable to prevent possible mistakes with the 'registreren' url in the future
export const registerSegment: string = 'registreren';

export const routes: Routes = [
  {
    path: 'tevredenheids-onderzoek/:id',
    loadChildren: () => import('app/pages/satisfaction-survey/satisfaction-survey.module').then((m) => m.SatisfactionSurveyModule)
  },
  {
    path: 'quotation/approve/:approveOrRejectId',
    component: ApproveOrRejectQuotationComponent
  },
  {
    path: 'quotation/reject/:approveOrRejectId',
    component: ApproveOrRejectQuotationComponent
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/:redirectUrl',
    component: LoginComponent
  },
  {
    canActivateChild: [LoggedInGuard],
    path: 'openDocument/:serviceType/:driveFileType',
    component: OpenFileComponent
  },
  {
    canActivateChild: [LoggedInGuard],
    path: 'openDocument/:serviceType/:driveFileType/:id',
    component: OpenFileComponent
  },
  {
    canActivateChild: [LoggedInGuard, HasPermissionGuard],
    path: 'action',
    loadChildren: () => import('app/pages/action/action.module').then((m) => m.ActionModule)
  },
  {
    path: 'quotation/approve/:approveOrRejectId',
    component: ApproveOrRejectQuotationComponent
  },
  {
    path: 'quotation/reject/:approveOrRejectId',
    component: ApproveOrRejectQuotationComponent
  },
  {
    canActivateChild: [LoggedInGuard, HasPermissionGuard],
    path: `service`,
    loadChildren: () => import('app/pages/service/service.module').then((m) => m.ServiceModule)
  },
  {
    canActivateChild: [LoggedInGuard, HasPermissionGuard],
    path: 'identity',
    loadChildren: () => import('app/pages/identity/identity.module').then((m) => m.IdentityModule)
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'account',
        loadChildren: () => import('app/pages/account/account.module').then((m) => m.AccountModule)
      },
      {
        canActivateChild: [LoggedInGuard, HasPermissionGuard],
        path: 'profile',
        loadChildren: () => import('app/pages/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        canActivateChild: [LoggedInGuard],
        path: 'login-redirect',
        loadChildren: () => import('app/pages/login-redirect/login-redirect.module').then((m) => m.LoginRedirectModule)
      },
      {
        canActivate: [ProductExistsGuard],
        path: `${registerSegment}/:productSlug`,
        loadChildren: () => import('app/pages/register/register.module').then((m) => m.RegisterModule)
      },
      {
        canActivateChild: [LoggedInGuard],
        path: `quotation-request`,
        loadChildren: () => import('app/pages/quotation-request/quotation-request.module').then((m) => m.QuotationRequestModule)
      },
      {
        canActivateChild: [LoggedInGuard],
        path: `quotation-request-intern`,
        loadChildren: () => import('app/pages/quotation-request-intern/quotation-request-intern.module')
          .then((m) => m.QuotationRequestInternModule)
      },
      {
        canActivateChild: [LoggedInGuard],
        path: 'shop',
        loadChildren: () => import('app/pages/shop/shop.module').then((m) => m.ShopModule)
      },
      { // catch-all
        path: '**',
        redirectTo: 'login-redirect',
        pathMatch: 'full'
      }
    ]
  },
  { // catch-all
    path: '**',
    redirectTo: 'login-redirect',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
