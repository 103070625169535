import {JsonObject, JsonProperty} from 'ta-json';
import {Naw} from 'app/domain/identity/identitydata';
import {LocalDateTime} from '@js-joda/core';

/**
 * Helper class to send naw and other attributes, but keep serialization settings of Naw
 */
@JsonObject()
export class NawWithAttributes {
  @JsonProperty() naw: Naw;
  @JsonProperty() reason?: string;
  @JsonProperty() serviceUpdatedAt?: LocalDateTime;
  @JsonProperty() willBeNotified?: boolean;
  @JsonProperty() version?: number;

  constructor(naw: Naw, reason: string, serviceUpdatedAt: LocalDateTime, willBeNotified: boolean, version: number) {
    this.naw = naw;
    this.reason = reason;
    this.serviceUpdatedAt = serviceUpdatedAt;
    this.willBeNotified = willBeNotified;
    this.version = version;
  }
}
