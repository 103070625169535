<app-modal-default
  [typeOfButton]="typeOfButton"
  [confirmVisible]="confirmVisible"
  [translatePrefix]="translatePrefix"
  [canClose]="canClose"
  [mustAnswer]="mustAnswer"
  (modalAccepted)="onButtonClicked($event)"
  [customTitle]="true">
  <h4 class="modal-title w-100" [innerHTML]="title"></h4>
  <p [innerHTML]="message"></p>
</app-modal-default>
