// msSaveOrOpenBlob does not exist on Navigator anymore, but can still be used
// See also: https://github.com/microsoft/TypeScript/issues/45612
declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}

export function downloadFile(data: Blob, fileName: string, newTab: boolean = false): void {
  // In some cases IE doesn't support using HTMLAnchorElement.click() (in the downloadFileUrl function),
  // therefore we need to use the navigator: https://stackoverflow.com/questions/46232980/click-giving-access-denied-in-ie11
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(data, fileName);
  } else {
    const objectUrl: string = URL.createObjectURL(data);
    downloadFileUrl(objectUrl, fileName, newTab);
  }
}

export function downloadFileUrl(url: string, fileName: string, newTab: boolean = false): void {
  const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
  if (newTab) {
    a.target = 'blank';
  }
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export function getFileSize(size: number): number {
  return Number(((size / 1024) / 1024).toFixed(4));
}
