import {JSON} from 'ta-json';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from '../settings/settings.service';
import {Observable, of} from 'rxjs';
import {OwoUserStatus} from '../../domain/owodata';
import {Identity} from '../../domain/identity/identitydata';
import {IdentityTypes} from '../../domain/identity/identitytypes';
import {DbId} from '../../domain/dbid';
import {EmailInputValidationResponse} from '../../domain/emailInputValidationResponse';

export class OwoAuthRequest {
  userId: number;
  effectiveUserId: number;

  constructor(userId: number, effectiveUserId: number) {
    this.userId = userId;
    this.effectiveUserId = effectiveUserId;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OwoService {
  constructor(
    private httpService: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  public getOwoUserStatus(id: DbId): Observable<OwoUserStatus> {
    return this.httpService.post(
      this.settingsService.backendUrl('/user/getOwoUserStatus'),
      id.id
    ).pipe(
      map((result) => JSON.deserialize<OwoUserStatus>(result)),
      catchError((err, _) => {
        /* eslint-disable no-console */
        console.debug('Error while fetching OWO user status', err);
        return of(undefined);
      })
    );
  }

  public getEmailAvailability(email: string): Observable<EmailInputValidationResponse> {
    return this.httpService.get(
      this.settingsService.backendUrl('/owo/getEmailAvailability/' + email)
    ).pipe(
      map((result) => JSON.deserialize<EmailInputValidationResponse>(result)),
      catchError((err, _) => {
        /* eslint-disable no-console */
        console.debug('Error while fetching Email Availability', err);
        return of(undefined);
      })
    );
  }
  public createOwoAccount(identity: Identity, accountType: IdentityTypes): Observable<string> {
    let url: string;
    switch (accountType) {
      case IdentityTypes.Coach:
      case IdentityTypes.TrajectAssistent:
      case IdentityTypes.Freelancer:
        url = '/user/createCoachAccount'; break;
      case IdentityTypes.Admin:
        url = '/user/createTBMAccount'; break;
      case IdentityTypes.Client:
        url = '/user/createClientAccount'; break;
      default:
        throw new Error('Unsupported account type');
    }
    return this.httpService.post(
      this.settingsService.backendUrl(url),
      JSON.serialize(identity)
    ).pipe(map((result) => JSON.deserialize<string>(result)));
  }

  public getToken(owoAuthRequest: OwoAuthRequest): Observable<OwoAuthToken> {
    return this.httpService.post(
      this.settingsService.backendUrl('/owo/getOwoAuthToken'), owoAuthRequest
    ).pipe(map((result) => JSON.deserialize<OwoAuthToken>(result)));
  }
}

export class OwoAuthToken {
  token: string;
  userId: string;
  effectiveUserId: string;

  constructor(token: string, userId: string, effectiveUserId: string) {
    this.token = token;
    this.userId = userId;
    this.effectiveUserId = effectiveUserId;
  }
}
