import {ListColumn} from '../../domain/list/list-data';
import {SortDirection} from './list-settings';
import {OperatorFunction} from 'rxjs';
import {IFilterItem} from './filter-item';
import {map} from 'rxjs/operators';

export function sortColumn<T>(columnName: string, columns: ListColumn<T>[], forceOrder: SortDirection = null): ListColumn<T> {
  columns = columns.map((col) => {
    if (col.name === columnName) {
      let order: SortDirection = forceOrder;
      if (!order) {
        switch (col.sortOrder) {
          case SortDirection.Unsorted:
          case SortDirection.Desc:
            order = SortDirection.Asc;
            break;
          case SortDirection.Asc:
            order = SortDirection.Desc;
            break;
        }
      }
      return Object.assign(col, {sortOrder: order });
    }
    // return ascOrder to default value
    return Object.assign(col, {sortOrder: SortDirection.Unsorted});
  });

  return columns.find((c) => c.name === columnName);
}

export const getFilterOptionsOperator: OperatorFunction<string[], IFilterItem[]> = map((rawFilterItems) =>
  rawFilterItems.map(
    (item): IFilterItem => ({
      // set to empty string if item is null or undefined, so that value is not serialized away once list settings are serialized
      value: item === null || item ===  undefined ? '' : item,
      label: item ? item : 'list.filter.empty',
    })
  )
);
