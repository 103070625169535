/**
 * Main status codes (service.status in back-end)
 */
import {IFilterItem} from '../../components/list/filter-item';
import {JsonObject, JsonProperty} from 'ta-json';

export enum ServiceStatusCodes {
  Open = 'open',
  Pending = 'pending',
  CourseInProgress = 'in_progress',
  CourseEnding = 'course_ending',
  CourseTemporized = 'course_temporized',
  CourseFinished = 'course_finished',
  CourseStopped = 'course_stopped',
  Gestopt = 'offerte_gestopt',
  Afgewezen = 'offerte_afgewezen',
  Verlopen = 'offerte_verlopen'
}

/**
 * Private quotation status codes when quotation is in progress (PrivateQuotationStatus in back-end)
 */
export enum DetailedQuotationStatusCodes {
  NieuweOfferteAanvraag = 'koppel_coach',
  OpstellenVerzenden = 'opstellen_verzenden',
  RegelenIntake = 'regelen_intake',
  Bellen = 'bellen',
  Intake = 'intake_offerte_opstellen',
  Opstellen = 'opstellen',
  Nakijken = 'nakijken',
  Verzenden = 'verzenden',
  StatusWachtOpAkkoord = 'wacht_akkoord'
}

// not used as status, but code is used in some if statements
export const OfferteBijnaVerlopenCode = 'offerte_bijna_verlopen';

/**
 * Public quotation status codes when quotation is in progress (PublicQuotationStatus in back-end)
 */
export enum PublicQuotationStatusCodes {
  NieuweOfferteAanvraag = 'koppel_coach',
  StatusInBehandeling = 'in_behandeling',
  StatusWachtOpAkkoord = 'wacht_akkoord'
}

@JsonObject()
export class ServiceStatus {
  @JsonProperty()
  public status: ServiceStatusCodes;
  @JsonProperty()
  public detailedStatus: ServiceStatusCodes | DetailedQuotationStatusCodes | PublicQuotationStatusCodes;
  @JsonProperty()
  public reason: string;

  constructor(status: ServiceStatusCodes, detailedStatus: ServiceStatusCodes | DetailedQuotationStatusCodes | PublicQuotationStatusCodes) {
    this.status = status;
    this.detailedStatus = detailedStatus;
  }
}

const serviceStatusSortValues: string[] = [
  ServiceStatusCodes.Pending, DetailedQuotationStatusCodes.NieuweOfferteAanvraag,
  PublicQuotationStatusCodes.NieuweOfferteAanvraag, DetailedQuotationStatusCodes.OpstellenVerzenden,
  PublicQuotationStatusCodes.StatusInBehandeling, DetailedQuotationStatusCodes.RegelenIntake, DetailedQuotationStatusCodes.Bellen,
  DetailedQuotationStatusCodes.Intake, DetailedQuotationStatusCodes.Opstellen, DetailedQuotationStatusCodes.Nakijken,
  DetailedQuotationStatusCodes.Verzenden, DetailedQuotationStatusCodes.StatusWachtOpAkkoord,
  PublicQuotationStatusCodes.StatusWachtOpAkkoord, ServiceStatusCodes.CourseInProgress, ServiceStatusCodes.CourseTemporized,
  ServiceStatusCodes.CourseEnding, ServiceStatusCodes.CourseFinished,
  ServiceStatusCodes.CourseStopped, ServiceStatusCodes.Gestopt, ServiceStatusCodes.Afgewezen,
  ServiceStatusCodes.Verlopen, ServiceStatusCodes.Open
];

export function defaultQuotationStatusFilters(): IFilterItem[] {
  return [
    ServiceStatusCodes.Open,
    ServiceStatusCodes.Gestopt,
    ServiceStatusCodes.Afgewezen,
    ServiceStatusCodes.Verlopen
  ].map((status) => ({
      label: `quotationOrActionCodes.${status}`,
      value: status,
      enabled: false,
      sortValue: serviceStatusToSortValue(status)
    }));
}

export function courseStatusFilters(): IFilterItem[] {
  return [
    ServiceStatusCodes.CourseInProgress,
    ServiceStatusCodes.CourseStopped,
    ServiceStatusCodes.CourseFinished,
    ServiceStatusCodes.CourseEnding,
    ServiceStatusCodes.CourseTemporized
  ].map((status) => ({
      label: `quotationOrActionCodes.${status}`,
      value: status,
      enabled: false,
      sortValue: serviceStatusToSortValue(status)
    }));
}

export function serviceStatusFilters(isIntern: boolean, includeCourseStatusses: boolean = true): IFilterItem[] {
  // add default quotation status filters (when quotation isn't in progress)
  let filters = defaultQuotationStatusFilters();
  // add course statusses if needed
  if (includeCourseStatusses) {
    filters = filters.concat(courseStatusFilters());
  }
  // add quotation status codes, public or private depending on if user is an internal user
  if (isIntern) {
    filters = filters.concat([
      ...Object.keys(DetailedQuotationStatusCodes).map((key) => ({
        label: `quotationOrActionCodes.${DetailedQuotationStatusCodes[key]}`,
        value: DetailedQuotationStatusCodes[key],
        enabled: false,
        sortValue: serviceStatusToSortValue(DetailedQuotationStatusCodes[key])
      }))
    ]);
  } else {
    filters = filters.concat([
      ...Object.keys(PublicQuotationStatusCodes).map((key) => ({
        label: `quotationOrActionCodes.${PublicQuotationStatusCodes[key]}`,
        value: PublicQuotationStatusCodes[key],
        enabled: false,
        sortValue: serviceStatusToSortValue(PublicQuotationStatusCodes[key])
      }))
    ]);
  }
  // sort filters on sort value
  return filters.sort((a, b) => (a.sortValue as number) - (b.sortValue as number));
}

export function serviceStatusToSortValue(s: ServiceStatusCodes | DetailedQuotationStatusCodes | PublicQuotationStatusCodes): number {
  return serviceStatusSortValues.indexOf(s);
}

/**
 * Returns true if course of service has ever started at some point (though it might be finished/stopped now)
 */
export function courseStarted(s: ServiceStatusCodes | DetailedQuotationStatusCodes | PublicQuotationStatusCodes): boolean {
  return s === ServiceStatusCodes.CourseInProgress || s === ServiceStatusCodes.CourseEnding || s === ServiceStatusCodes.CourseTemporized ||
    s === ServiceStatusCodes.CourseFinished || s === ServiceStatusCodes.CourseStopped;
}

/**
 * Returns true if course is completely done, either because it is finished or because it was stopped
 */
export function courseDone(s: ServiceStatusCodes): boolean {
  return [ServiceStatusCodes.CourseFinished, ServiceStatusCodes.CourseStopped].includes(s);
}

export const serviceStoppedStatusCodes: ServiceStatusCodes[] = [
  ServiceStatusCodes.Gestopt,
  ServiceStatusCodes.Afgewezen,
  ServiceStatusCodes.Verlopen,
  ServiceStatusCodes.CourseStopped
];
