<app-radio-button *ngFor="let reason of reasons"
  [name]="'reject_reason'"
  [id]="reason"
  [labelText]="translatePrefix + '.reasons.' + reason"
  (change)="chooseReasonToReject(reason)">
</app-radio-button>
<app-radio-button
  [name]="'reject_reason'"
  [id]="'other'"
  [labelText]="translatePrefix + '.reasons.other'"
  (change)="chooseReasonToReject(undefined)">
</app-radio-button>
<div class="ms-5">
  <app-input-field *ngIf="showReasonInput"
    [required]="true"
    [showRequired]="showRequired"
    [validateOnChange]="true"
    [(data)]="customChosenReason"
    (dataChange)="customReasonChanged.emit(customChosenReason)">
  </app-input-field>
</div>

<div class="text-danger" [hidden]="!showRequired || !required || chosenReason || customChosenReason">
  {{ translatePrefix + '.errorText' | translate }}
</div>
