/* "Barrel" of Http Interceptors */
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ConnectionFailureInterceptor} from './interceptors/conn-failure.interceptor';
import {IsBusyInterceptor} from './interceptors/is-busy.interceptor';
import {ContentTooLargeInterceptor} from './interceptors/content-too-large.interceptor';
import {IENoCacheInterceptor} from './interceptors/ie-no-cache.interceptor';
import {TraceabilityInterceptor} from 'app/services/http/interceptors/traceability.interceptor';
import {ShowArchivedInterceptor} from './interceptors/showArchived.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: ConnectionFailureInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: IsBusyInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ContentTooLargeInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: IENoCacheInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: TraceabilityInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ShowArchivedInterceptor, multi: true}
];
