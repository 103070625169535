import {JsonObject, JsonProperty, JsonElementType} from 'ta-json';
import {pageSize} from '../../services/paginationHelper';

export enum SortDirection {
  Unsorted = 0,
  Asc = 1,
  Desc = -1
}

@JsonObject()
export class ColumnSortSettings {
  @JsonProperty() fieldId: string; // string of identifier for backend
  @JsonProperty() order: SortDirection;
  @JsonProperty() translatePrefix?: string;
}

// NB: If you change this structure, make sure to update sortVersion in settings.service.ts
@JsonObject()
export class ListSortSettings {
  @JsonProperty() column: string;
  @JsonProperty() settings: ColumnSortSettings;
}

@JsonObject()
export class StrippedListColumnFilter {
  @JsonProperty() displayValue: string;
  @JsonProperty() internalValue: any;
}

@JsonObject()
export class StrippedListFilterSettings {
  @JsonProperty() fieldId: string;
  @JsonProperty() @JsonElementType(StrippedListColumnFilter) filters: StrippedListColumnFilter[];
}

@JsonObject()
export class PaginationParams {
  // unless otherwise specified, start on page 1 with a page size of 50.
  @JsonProperty() limit: number = pageSize;
  @JsonProperty() offset: number = 1;
}

// NB: If you change this structure, make sure to update filterVersion in settings.service.ts
export type ListFilterSettings = {
  [column: string]: StrippedListFilterSettings;
};

export type ListSearchSettings = {
  fieldId: string;
  value: string;
  translatePrefix?: string;
};

@JsonObject()
export class ListSettings {
  @JsonProperty() @JsonElementType(StrippedListFilterSettings) filterSettings: StrippedListFilterSettings[] = [];
  @JsonProperty() searchSettings: ListSearchSettings = null;
  @JsonProperty() paginationParams: PaginationParams = new PaginationParams();
  @JsonProperty() sortSettings?: ListSortSettings;
}

