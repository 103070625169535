import {IPropertyConverter, JsonValue} from 'ta-json';
import {LocalDate, LocalDateTime, ZoneId} from '@js-joda/core';

/**
 * Interprets local date as the same date in UTC timezone, and vice versa.
 */
export class UTCInterpreter implements IPropertyConverter {
  private zoneId: ZoneId;

  /**
   * Optionally specify specific timezone
   * Now only used for unit tests
   */
  constructor(zoneId: ZoneId = ZoneId.SYSTEM) {
    this.zoneId = zoneId;
  }

  serialize(localDateTime: LocalDateTime): JsonValue {
    // use local date to calculate offset for current timezone
    const offset = localDateTime.atZone(this.zoneId).offset().totalSeconds();
    // subtract local offset to create UTC date
    return localDateTime.minusSeconds(offset).toJSON();
  }

  deserialize(jsonDate: JsonValue): LocalDateTime {
    // get UTC date first
    const utcDateTime = LocalDateTime.parse(jsonDate as string);
    // use UTC date to get zone offset
    const offset = utcDateTime.atZone(this.zoneId).offset().totalSeconds();
    // add local offset to create local date time
    return utcDateTime.plusSeconds(offset);
  }
}

/**
 * Interprets date using default writer/parser, but needed because LocalDate has no @JsonObject annotation
 */
export class DateInterpreter implements IPropertyConverter {
  serialize(localDate: LocalDate): JsonValue {
    return localDate.toJSON();
  }

  deserialize(jsonDate: JsonValue): LocalDate {
    return LocalDate.parse(jsonDate as string);
  }
}
