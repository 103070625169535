<div>
  <h4 class="login-header card-header">{{'login.title' | translate}}</h4>
  <div class="d-flex flex-row flex-wrap-reverse">
    <div class="login-item card">
      <div class="card-header ps-0">
        <h5 class="card-title">{{'login.intro.title' | translate}}</h5>
      </div>
      <div class="card-body d-flex flex-column" [ngClass]="withRegister ? 'justify-content-between' : ''">
        <div>
          <div *ngIf="withRegister else noRegister">
            <p [innerHtml]="'login.intro.registerText' | translate"></p>
            <ul>
              <li *ngFor="let item of [1, 2, 3, 4]" class="pb-1">
                <ion-icon name="checkmark-circle" class="me-2"></ion-icon>{{ 'login.intro.bullets.' + item | translate }}</li>
            </ul>
          </div>
          <ng-template #noRegister>
            <p [innerHTML]="'login.intro.text' | translate"></p>
          </ng-template>
        </div>
        <div *ngIf="withRegister" class="mb-5 d-flex flex-row align-self-end">
          <button type=submit class="btn btn-fill ms-3 clickable"
                  (click)="register()">{{ 'account.new.create' | translate }}</button>
        </div>
      </div>
    </div>
    <form class="login-item card" (ngSubmit)="loginForm.form.valid && doLogin()" #loginForm="ngForm">
      <div class="card-header ps-0">
        <h5 class="card-title">{{'login.login.title' | translate}}</h5>
      </div>
      <div class="card-body d-flex flex-column justify-content-between">
        <p [innerHTML]="'login.login.text' | translate"></p>
        <div *ngIf="loginForm.submitted && incorrect" class="failed-login"
             [innerHTML]="'login.login.failed' | translate "></div>
        <div class="d-flex flex-column">
          <div class="mb-4">
            <div class="d-flex flex-column">
              <label for="userEmail">{{ 'login.email.title' | translate }}</label>
              <input id="userEmail" name="userEmail" type="email" class="form-control"
                     [pattern]="regexEmail" [(ngModel)]="formEmail" required #userEmail="ngModel">
              <span *ngIf="userEmail.touched && userEmail.invalid" class="text-danger">{{ 'form.requiredEmail' | translate }}</span>
            </div>
          </div>
          <div class="mb-4">
            <div class="d-flex flex-column">
              <label for="userPassword">{{ 'login.password.title' | translate }}</label>
              <div class="d-flex flex-row">
                <input id="userPassword" name="userPassword" [type]="showPassword ? 'text' : 'password'" class="form-control"
                       [(ngModel)]="formPassword" required maxlength="60" #userPassword="ngModel">
                <app-show-password-eye-icon [(showPassword)]="showPassword" class="align-self-center"></app-show-password-eye-icon>
              </div>
              <span *ngIf="userPassword.touched && userPassword.invalid" class="text-danger">{{ 'form.requiredField' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row mb-5 align-self-end">
          <a [routerLink]="'/account/reset'" class="align-self-center">{{'login.password.forgotPassword' | translate}}</a>
          <button type="submit" class="btn btn-fill ms-3" [disableDefault]="loading || loginForm.invalid" disableDuringRequest>
            {{ 'login.login.button' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="($screenResize | async) as screenWidth" class="card-footer d-flex flex-row align-items-center"
       [ngClass]="withRegister ? 'justify-content-between' : 'justify-content-end'">
    <button *ngIf="withRegister" class="btn btn-default" (click)="toShop()">
      {{ (screenWidth > smallScreen ? 'login.contact.backToSite' : 'login.contact.toSite') | translate }}
    </button>
    <app-contact-button
        [mailToLabel]="'login.contact.mail'"
        [phoneNo]="'login.contact.phone'">
    </app-contact-button>
  </div>
</div>
