<div>
    <div class="ql-editor-wrapper"
         [tabIndex]="0"
         (click)="onFocus()"
         (blur)="onBlur()"
    >
        <p-editor #pEditor
                  [id]="id"
                  [ngModel]="data"
                  [maxlength]="maxlength"
                  [readonly]="disabled"
                  (ngModelChange)="setMaxStringLength($event)"
                  [style]="{'min-height':'200px'}">
            <p-header>
                <span class="ql-formats">
                    <select class="ql-header"></select>

                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button class="ql-underline" aria-label="Underline"></button>
                    <button class="ql-strike" aria-label="Strike"></button>


                    <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)"></select>
                    <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)"></select>
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>

                    <select title="Text Alignment" class="ql-align">
                        <option selected>Gauche</option>
                        <option value="center" label="Center"></option>
                        <option value="right" label="Right"></option>
                        <option value="justify" label="Justify"></option>
                    </select>
                </span>
            </p-header>
        </p-editor>
    </div>
    <div *ngIf="!valid && !dataEmpty"
         class="text-danger">{{ 'editor.maxlengthError' | translate: {number: maxlength} }}</div>
    <div *ngIf="dataEmpty && required && showRequired" class="text-danger">{{ 'editor.required' | translate}}</div>
</div>
