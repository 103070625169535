import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  template: ''
})
export abstract class InputComponent<T> implements OnChanges {

  @Input() id: string;
  @Input() ariaLabel: string;

  @Input() required: boolean = false;
  @Input() disabled: boolean = false;

  @Input() errorText: string;
  @Input() showRequired: boolean = false;

  @Input() data: T;
  @Output() dataChange: EventEmitter<T> = new EventEmitter<T>();
  @Output() validChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onFocusChange: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  public valid: boolean = false;
  public focused: boolean = false;
  public untouched: boolean = true;
  public dataEmpty: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && (changes['data'].currentValue !== changes['data'].previousValue)) {
      this.dataEmpty = this.checkEmptyData();
    }
  }

  public validChanged(valid: boolean): void {
    this.dataEmpty = this.checkEmptyData();
    this.valid = valid;
    this.validChange.emit(this.valid);
  }

  public focusChanged(focus: boolean): void {
    this.dataEmpty = this.checkEmptyData();
    this.focused = focus;
    this.untouched = false;
    this.onFocusChange.emit();
  }

  abstract checkEmptyData(): boolean;
}
