import {DriveFileTypes} from '../domain/drive-item-ids';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {MsgraphService} from './msgraph/msgraph.service';
import {IdentityNamePipe} from '../pipes/identity-name.pipe';
import {TranslateService} from '@ngx-translate/core';
import {CompanyDetails, Identity, NawDetails} from '../domain/identity/identitydata';
import {IdentityDetails} from '../domain/identity/identityTableData';

export enum FileServiceType {
  Document = 'document',
  HistoryItem = 'historyItem',
  Product = 'product',
  Service = 'service'
}

/**
 * File service that uses functionality having to do with MsGraphService
 */
@Injectable({
  providedIn: 'root',
})
export abstract class FileService {
  abstract serviceType: FileServiceType;

  protected constructor(
      public msGraphService: MsgraphService,
      public translateService: TranslateService
  ) {}

  getFileUrl$(driveFileType: DriveFileTypes, id: number = null): Observable<[boolean, string, string]> {
    return this.msGraphService.getFileUrl$(`/${this.serviceType}${id ? '/' + id : ''}/getFileUrl/${driveFileType}`);
  }

  instantiateFile$(driveFileType: DriveFileTypes, id: number = null): Observable<[boolean, string, string]> {
    return this.msGraphService.getFileUrl$(`/${this.serviceType}${id ? '/' + id : ''}/instantiateFile/${driveFileType}`);
  }

  downloadPdf$(driveFileType: DriveFileTypes, id: number): Observable<Blob> {
    return this.msGraphService.getPDFAsBlob$(`/${this.serviceType}/${id}/getPDF/${driveFileType}`);
  }

  getFileName(type: DriveFileTypes, client: Identity, productName: string): string {
    const identityNamePipe = new IdentityNamePipe(this.translateService);
    const pdfType = this.translateService.instant(`driveFileTypes.${type}`);
    const pdfTitle = client ? identityNamePipe.transformIdentity(client, {withFirstName: false}) : '';

    return `${pdfType} ${productName} ${pdfTitle}, Power4People.pdf`;
  }
}
