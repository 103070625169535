import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IQuestionMarkDetails} from '../question-mark/question-mark-details';
import {IFormObjectFieldData} from '../../pages/identity/form-data-and-validation';
import {DisabledModalComponent} from '../disabled-modal/disabled-modal.component';
import {ModalService} from '../../services/modal/modal.service';
import {UserService} from '../../services/userlist/user.service';
import {LoginStateService} from '../../services/login/loginstate.service';

@Component({
  template: ''
})
export class FormComponent<T> {

  @Input() offerteFlow: boolean = true;
  @Input() readonly: boolean = false;
  @Input() showRequired: boolean = false;
  @Input() qmDetails: IQuestionMarkDetails;

  @Input() set formObject(formObject: T) {
    this._formObject = formObject;
    // copy formObject to check initial value for disabling field.
    this._formObjectCopy = Object.assign({}, this._formObject);
    if (this._fields && this._formObject) {
      this.initForm();
    }
  }

  @Input() set fields(fields: IFormObjectFieldData[]) {
    this._fields = fields;
    if (this._fields && this._formObject) {
      this.initForm();
    }
  }

  private _fields: IFormObjectFieldData[];
  public _formObject: T;
  public _formObjectCopy: any;
  public initialized: boolean = false;

  public formValidStates: { [k: string]: boolean };
  public formRequiredFields: { [k: string]: boolean };
  public formReadOnlyFields: { [k: string]: boolean };

  @Output() formDataChange: EventEmitter<T> = new EventEmitter<T>();
  @Output() formValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
      private _modalService: ModalService,
      private _loginStateService: LoginStateService
  ) {
  }

  initForm(): void {
    this.formValidStates = this._fields.reduce((map, f) => {
      map[f.name] = !f.required || (!!this._formObject && !!this._formObject[f.name]);
      return map;
    }, {});
    this.formRequiredFields = this._fields.reduce((map, f) => {
      map[f.name] = f.required;
      return map;
    }, {});
    this.formReadOnlyFields = this._fields.reduce((map, f) => {
      map[f.name] = f.readOnly;
      return map;
    }, {});
    this.initialized = true;
  }

  formDataChanged(key: string, value: string): void {
    this._formObject[key] = value;
    this.formDataChange.emit(this._formObject);
  }

  formValidChanged(name: string, valid: boolean): void {
    this.formValidStates[name] = valid;
    // If none of the values in the formValidStates Map is false, the form is valid.
    this.formValidChange.emit(!Object.keys(this.formValidStates).some((f) => this.formValidStates[f] === false));
  }

  showField(f: string): boolean {
    return f in this.formValidStates;
  }

  showReq(f: string): boolean {
    return this.showRequired && this.formRequiredFields[f] && !this.formValidStates[f];
  }

  public showPopUp(condition: boolean): void {
    if (condition) {
      this._loginStateService.currentUser$.subscribe(u => {
        if (u.user.isInternalUser() && this.offerteFlow) {
          this._modalService.show(DisabledModalComponent, {translatePrefix: 'quotation.disabled.internal'});
        } else {
          this._modalService.show(DisabledModalComponent, {translatePrefix: 'quotation.disabled.external'});
        }
      });
    }
  }
}
