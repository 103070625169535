<div *ngIf="!!userIdentity" class="d-flex align-items-center w-100 h-100" [ngClass]="isSubmenu ? 'flex-wrap' : 'flex-row'">
  <ul *ngFor="let item of menuItems">
    <li *ngIf="!item.submenu && showItem(item); else withSubMenu">
      <button *ngIf="item.showAsButton; else asMenuItem" class="me-3 px-2 clickable btn btn-fill"
          [secureAction]="item?.actions" [requiredPermission]="item?.permission"
          [ngClass]="activeMenuItems.indexOf(item.nameCode) > -1 ? 'active': ''" (click)="itemClicked(item)">
        {{ 'menu.' + item.nameCode | translate }}
      </button>
      <ng-template #asMenuItem>
        <li class="navbar-item navbar-menu-item clickable me-3"
          [secureAction]="item?.actions" [requiredPermission]="item?.permission"
            [ngClass]="activeMenuItems.indexOf(item.nameCode) > -1 ? 'active': ''" (click)="itemClicked(item)">
          <a class="text-nowrap">{{ 'menu.' + item.nameCode | translate }}</a>
        </li>
      </ng-template>
    </li>
    <ng-template #withSubMenu>
      <li *ngIf="showItem(item)" class="dropdown navbar-item navbar-menu-item me-2" dropdown [secureAction]="item?.actions"
          [requiredPermission]="item?.permission" [ngClass]="activeMenuItems.indexOf(item.nameCode) > -1 ? 'active': ''">
        <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
           aria-expanded="false">
          {{ ('menu.' + item.nameCode + '.title' | translate) + (item.nameCode === 'user' ? (userIdentity | identityName: {withGender: false}) : '') }}
          <ion-icon *ngIf="showArchived(item)" name="folder-open-outline" size="small"></ion-icon>
          <span class="caret"></span>
        </a>
        <ul *dropdownMenu class="dropdown-menu">
          <li *ngFor="let subItem of item.submenu" class="navbar-item p-0 clickable dropdown-item"
              [secureAction]="subItem?.actions" [requiredPermission]="subItem?.permission"
              [ngClass]="{'fw-bold': activeMenuItems.indexOf(subItem.nameCode) > -1, 'submenu': isSubmenu}"
              (click)="itemClicked(subItem)">
            <a class="text-nowrap">{{ 'menu.' + subItem.nameCode | translate }}</a>
            <ion-icon *ngIf="showArchiveOpened(subItem)" name="folder" size="small"></ion-icon>
            <ion-icon *ngIf="showArchiveClosed(subItem)" name="folder-open-outline" size="small"></ion-icon>
          </li>
        </ul>
      </li>
    </ng-template>
  </ul>
</div>
