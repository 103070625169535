import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html'
})
export class RadioButtonComponent {
  @Input() name: string;
  @Input() readonly: boolean;
  @Input() checked: boolean = false;
  @Input() labelText: string;
  @Input() id: string;
}
