import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { LoginService } from './login.service';

import {Observable, of} from 'rxjs';
import {catchError, first, map, tap} from 'rxjs/operators';
import {isIE} from '../../domain/browserchecks';
import {ModalService} from '../modal/modal.service';
import {LoginStateService} from './loginstate.service';
import {RedirectService} from 'app/services/redirectService';

/**
 * Guard that checks whether the user is logged in. Users can only follow a guarded route if their token is valid.
 * If the current user is an internal user, they are subsequently required to be "fullyActive" to be considered logged in by this guard.
 *
 * Use this guard for all routes that require a user to be "fully" logged in:
 *  logged in with MFA as internal user, or just logged in as external user.
 *
 * Note: this guard does not check for any permissions, it only validates the current user's token to see if they are logged in.
 */
@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard  {

  constructor(
    private _router: Router,
    private _loginService: LoginService,
    private _loginStateService: LoginStateService,
    private _modalService: ModalService,
    private _redirectService: RedirectService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this._redirectService.redirectUrl = state.url;
    return this.guard();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this._redirectService.redirectUrl = state.url;
    return this.guard();
  }

  /**
   * Check the token for the required additional security measures and redirect the user accordingly.
   */
  private guard(): Observable<boolean> {
    return this._loginService.validate().pipe(
      catchError((_) => of(null)),
        // additional interceptive behavior - handles mfa and password expiration checks
        map((token) => {
        if (!token) {
          return '/login';
        } else if (!token.mfaValid && token.isInternal) {
          return '/account/totp/login/';
        } else if (!token.mfaValid) {
          return '/account/mfa/login/';
        } else if (token.passwordExpired) {
          return 'account/newPassword';
        } else {
          return null;
        }
      }),
      map((url) => {
        if (isIE) {
          this._modalService.showWarning({translatePrefix: 'IEwarning', canClose: false}, false);
          return false;
        } else if (url !== null) {
          void this._router.navigate([url]);
          return false;
        }
        return true;
      })
    );
  }
}
