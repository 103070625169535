import {Genders, Naw} from '../../domain/identity/identitydata';
import {DbId} from '../../domain/dbid';

export class ApprovalDataFormObject {
  public id?: DbId; // the approvalDataFormObject will only have an ID if an existing NAW is used to create it.
  public gender: Genders;
  public initials?: string;
  public lastNameInfix?: string;
  public lastName: string;
  public email: string;

  static from(naw: Naw): ApprovalDataFormObject {
    const formObject = new ApprovalDataFormObject();

    if (naw) { // if the input NAW is empty, we just return an empty ApprovalDataFormObject
      formObject.id = DbId.from(naw.id.id);
      formObject.gender = naw.gender;
      formObject.initials = naw.initials;
      formObject.lastNameInfix = naw.lastNameInfix;
      formObject.lastName = naw.lastName;
      formObject.email = naw.email;
    }

    return formObject;
  }
}
