import {Component, OnDestroy} from '@angular/core';
import {LoginStateService} from '../services/login/loginstate.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-mainlayout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {

  public showHeader: boolean = false;

  private onDestroy$: Subject<void> = new Subject();

  constructor(authService: LoginStateService) {
    authService.auth$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(token =>
      this.showHeader = !!token && token.mfaValid && !token.passwordExpired
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


}
