import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from '../settings/settings.service';
import {Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {JSON} from 'ta-json';
import {ServiceService} from '../service/service.service';
import {ProductService} from '../product/product.service';
import {DriveFileTypes} from '../../domain/drive-item-ids';
import {FileService} from '../file.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MsgraphService {
  constructor(
      private httpClient: HttpClient,
      private settingsService: SettingsService,
      private router: Router
  ) {}

  getFileUrl$(backendUrl: string): Observable<[boolean, string, string]> {
    return this.httpClient.get(this.settingsService.backendUrl(backendUrl),
      {observe: 'response'})
      .pipe(
        catchError((e) => of({ok: false, body: '', message: e.error})),
        map((result) => [result.ok, JSON.deserialize<string>(result.body), result['message']])
      );
  }

  getPDFAsBlob$(backendUrl: string): Observable<Blob> {
    return this.httpClient.get(this.settingsService.backendUrl(backendUrl),
      {observe: 'response', responseType: 'arraybuffer'})
      .pipe(
        catchError((_) => of({ok: false, body: ''})),
        map((result) => {
          if (result.ok) {
            return new Blob([result.body], {type: 'application/pdf'});
          } else {
            throw new Error('Failed to download PDF');
          }
        })
      );
  }
}
