import {UTCInterpreter} from './utcinterpreter';
import {ModalService} from '../services/modal/modal.service';
import {JsonValue} from 'ta-json';

export enum UpdatedItemType {
  Service = 'service',
  Action = 'action',
  Session = 'session'
}

export function askForOutOfDateReload(item: UpdatedItemType, modalService: ModalService): void {
  modalService.showWarning({
    translatePrefix: 'outOfDateModal',
    messageParams: {item: 'outOfDateModal.type.' + item}
  }).subscribe((res) => {
      if (res) {
        location.reload();
      }
    }
  );
}

/**
 * Returns status for items that should be updated but can potentially be outdated
 *
 * @result: result returned from backend
 * @deserialize: function to deserialize body of result. If there no specific deserializer is given, result should be
 * number of new version
 */
export function returnStatusOfUpdatedItem(
  result: any,
  deserialize: (jsValue: JsonValue) => any = (body) => deserialize(body)
): {success: boolean; outOfDate: boolean; body: any; isNotContactPerson: boolean} {
  return {
    success: result.status === 200,
    outOfDate: result.status === 409,
    body: result.status === 200 && !!result.body? result.body : null,
    isNotContactPerson: result.status === 428
  };
}
