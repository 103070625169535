import {JsonObject, JsonProperty} from 'ta-json';
import {DbId} from 'app/domain/dbid';
import {LocalDateTime} from '@js-joda/core';
import {ListButton} from 'app/domain/list/list-data';

// Should be a copy of the Scala trait and be equal to the EmailTemplate.category column enum values.
export enum EmailTemplateCategory {
  Reporting = 'reporting',
  EndReporting = 'end_reporting',
  EndReportingExtension = 'end_reporting_extension',
  StartCourse = 'start_course'
}

export interface HasEmailTemplateCategory {
  category: EmailTemplateCategory;
}

export function splitByEmailTemplateCategory<T extends HasEmailTemplateCategory>(inputToSplit: T[]): {[key: string]: T[]} {
  const inputByCategory: {[key: string]: T[]} = {};
  Object.keys(EmailTemplateCategory).forEach(k => {
    inputByCategory[EmailTemplateCategory[k]] = inputToSplit.filter(i => i.category === EmailTemplateCategory[k]);
  });

  return inputByCategory;
}

export interface IEmailTemplateColumnData {
  id: number;
  name: string;
  category: EmailTemplateCategory;
  actions: ListButton<IEmailTemplateColumnData>[];
}


@JsonObject()
export class EmailTemplate {
  @JsonProperty() public title: string;
  @JsonProperty() public body: string;
}

// This is here so that we can safely loop over all templates (currently A, B and C)
// and to make sure they are equal for the EmailTemplates and their metadata.
@JsonObject()
export class TemplateWrapper<T> {
  @JsonProperty() public templateA: T;
  @JsonProperty() public templateB: T;
  @JsonProperty() public templateC?: T;
  @JsonProperty() public templateD?: T;
}

@JsonObject()
export class EmailTemplateMetadata {
  @JsonProperty() public category: EmailTemplateCategory;
  @JsonProperty() public name: string;
  @JsonProperty() public templates: TemplateWrapper<string>;
}

@JsonObject()
export class EmailTemplatesForCategory {
  @JsonProperty() public id: DbId = DbId.from();
  @JsonProperty() public category: EmailTemplateCategory;
  @JsonProperty() public name: string;
  @JsonProperty() public templates: TemplateWrapper<EmailTemplate>;

  constructor(meta: EmailTemplateMetadata) {
    this.category = meta.category;
    this.name = '';
    this.templates = {
      templateA: new EmailTemplate(),
      templateB: new EmailTemplate()
    };

    // only add the following templates if the category supports it.
    if (meta.templates.templateC) {
      this.templates.templateC = new EmailTemplate();
    }

    if (meta.templates.templateD) {
      this.templates.templateD = new EmailTemplate();
    }
  }
}
