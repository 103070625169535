import {JsonConverter, JsonObject, JsonProperty} from 'ta-json';
import {DbId} from '../dbid';
import {DateInterpreter, UTCInterpreter} from '../utcinterpreter';
import {LocalDate, LocalDateTime} from '@js-joda/core';

@JsonObject()
export class TemporisationData {
  @JsonProperty() public id: DbId = DbId.from(0);
  @JsonProperty() public serviceId: number;
  @JsonProperty() @JsonConverter(DateInterpreter) public startDate: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) public inventorisationDate: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) public endDate: LocalDate;
  @JsonProperty() @JsonConverter(DateInterpreter) public lastEndDate?: LocalDate = null;
  @JsonProperty() public weightToRestore: number;
  @JsonProperty() public reason?: string;
  @JsonProperty() public isFirstTemporisation: boolean = true; // "first" means the first of a "temporisation loop".
  @JsonProperty() @JsonConverter(UTCInterpreter)  public createdAt: LocalDateTime;

  public constructor(init?: Partial<TemporisationData>) {
    Object.assign(this, init);
  }
}
