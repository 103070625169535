import {IFilterItem} from '../../components/list/filter-item';
import {SortDirection} from '../../components/list/list-settings';
import {PermissionName} from '../userdata';

export class ListColumn<T> {
  public name: string;
  public type: string;
  public translatePrefix: string;
  public sortFieldId: string;
  public filterFieldId: string;
  public headerTranslatePrefix: string;
  public sortOrder: SortDirection;
  public filterOptions: IFilterItem[];
  public autoFilter: boolean;
  public extract: (t: T) => string;
  public sortFunction: (a: T, b: T, order: SortDirection) => number;
  public hidden: boolean = false;
  public filterSortFunction: (a: string | number, b: string | number, order: SortDirection) => number;
  public extractFilterSortValue: (t: T) => string | number;
  public searchFieldId: string;
  public useTranslatePrefixForBackend: boolean;
  public allowSorting: boolean = true;

  constructor(
      name: string,
      type: string,
      translatePrefix: string = null,
      sortFieldId: string = null,
      filterFieldId: string = null,
      sortOrder: SortDirection = SortDirection.Unsorted,
      filterOptions: IFilterItem[] = [],
      autoFilter: boolean = false,
      hidden: boolean = false,
      extract?: (t: T) => string,
      sortFunction?: (a: T, b: T, order: SortDirection) => number,
      filterSortFunction?: (a: string | number, b: string | number, order: SortDirection) => number,
      extractFilterSortValue?: (t: T) => string | number,
      searchFieldId: string = null,
      useTranslatePrefixForBackend: boolean = true,
      allowSorting: boolean = true
  ) {
    this.name = name;
    this.type = type;
    this.translatePrefix = translatePrefix;
    this.sortFieldId = sortFieldId;
    this.filterFieldId = filterFieldId;
    this.sortOrder = sortOrder;
    this.filterOptions = filterOptions;
    this.autoFilter = autoFilter;
    this.sortFunction = sortFunction;
    this.hidden = hidden;
    this.filterSortFunction = filterSortFunction;
    this.extractFilterSortValue = extractFilterSortValue;
    this.searchFieldId = searchFieldId;
    this.useTranslatePrefixForBackend = useTranslatePrefixForBackend;
    this.allowSorting = allowSorting;

    if (extract) {
      this.extract = extract;
    } else {
      this.extract = (t) => t[name];
    }
  }
}

export enum ButtonColorType {
  Black = 'black',
  Red = 'red',
  Green = 'green',
  Blue = 'blue',
  Yellow = 'yellow',
  Gray = 'gray'
}

export class ListButton<T> {
  icon: string;
  onClick: (t: T) => void;
  action: string[];
  permission: PermissionName;
  tooltip: string;
  color: string;
  display: boolean;

  constructor(
      icon: string,
      onClick: (t: T) => void,
      action: string[],
      permission: PermissionName,
      tooltip: string,
      color: ButtonColorType = ButtonColorType.Black,
      display: boolean = true,
  ) {
    this.icon = icon;
    this.onClick = onClick;
    this.action = action;
    this.permission = permission;
    this.tooltip = tooltip;
    this.color = color;
    this.display = display;
  }
}

export const rowColor: string = '#F1F7FF';


