<nav class="navbar navbar-default p-0 pt-3 d-block">
  <div class="container container-xs-large d-flex">
    <div class="navbar-header pb-2 d-lg-none">
      <a class="navbar-brand" [routerLink]="'/'"><img [src]="assetsFolder + '/img/logo@2x.png'" [alt]="'header.logoLink' | translate"/></a>
    </div>
    <button
        type="button"
        class="navbar-toggle d-lg-none"
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        [attr.aria-label]="'mainMenu'"
        aria-controls="mobile-navbar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>

    <div class="row w-100 ms-0">
      <div class="navbar-collapse d-flex justify-content-between" id="mobile-navbar" [ngClass]="{'collapse': isCollapsed}">
        <div class="nav navbar-nav h-100 pt-2">
          <div class="d-none d-lg-block navbar-brand navbar-item">
            <a class="pb-0" [routerLink]="'/'"><img [src]="assetsFolder + '/img/logo-small.svg'" [alt]="'header.logoLink' | translate"/></a>
          </div>
          <navbar-menu
              [menuItems]="leftMenuItems"
              [activeMenuItems]="activeMenuItems"
              (clicked)="menuItemClicked($event)">
          </navbar-menu>
        </div>
        <div class="nav navbar-nav navbar-right h-100 pt-2">
          <navbar-menu
              [menuItems]="rightMenuItems"
              [activeMenuItems]="activeMenuItems"
              (clicked)="menuItemClicked($event)">
          </navbar-menu>
        </div>
      </div>
    </div>
  </div>
</nav>
