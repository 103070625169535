import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Permission, PermissionName} from '../../domain/userdata';
import {BehaviorSubject, Observable} from 'rxjs';
import {LoginStateService} from '../login/loginstate.service';
import {ModalService} from '../modal/modal.service';

/**
 * Checks permissions of the user currently signed in
 * The code below was inspired by the backend's AuthActionBuilders
 * > Use the SecureActionDirective to hide/show components based on permissions in a view
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {

  public $currentPermissions: BehaviorSubject<Permission[]> = new BehaviorSubject([]);

  constructor(public modalService: ModalService, private loginStateService: LoginStateService) {
    this.loginStateService.currentUser$
      .subscribe((user) => {
        if (!user) {
          this.$currentPermissions.next([]); // reset permissions
        } else {
          this.$currentPermissions.next(user.permissions);
        }
      });
  }

  /**
   * Observe permission, can be useful when displaying things
   */
  observePermission(operationName: string, requiredPermission: PermissionName): Observable<boolean> {
    return this.$currentPermissions.pipe(
      map((permissions) => this.isPermitted(permissions, operationName, requiredPermission))
    );
  }

  isAnyPermitted(permissions: Permission[], operationNames: string[], requiredPermission: PermissionName): boolean {
    return operationNames.some((operationName) => this.isPermitted(permissions, operationName, requiredPermission));
  }

  /**
   * Compares the user's permission with the required permission
   * Special case operation star skips the operation check and only checks the permission level
   */
  isPermitted(permissions: Permission[], operationName: string, requiredPermission: PermissionName): boolean {
    return !!permissions.find((p) => {
      let correctOperation;
      if (p.operation.name === '*') {
        correctOperation = true;
      } else {
        correctOperation = p.operation.name === operationName;
      }

      return correctOperation && this.bitMatch(p.bit, requiredPermission);
    });
  }

  /**
   * Bit-matches the required level to the permission bit. For example
   * 6 & 5 == 5 because 0111 & 0101 = 0101 == 5
   * 6 & 2 == 2 because 0110 & 0010 = 0010 == 2
   * 6 & 1 != 1 because 0110 & 0001 = 0000 == 0
   */
  bitMatch(bit: number, required: number): boolean {
    return (bit & required) === required;
  }
}
