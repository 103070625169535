import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {Observable} from 'rxjs';
import {ProductService} from './product.service';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductExistsGuard  {

  constructor(
    private router: Router,
    private productService: ProductService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.guard(route.params.productSlug);
  }

  private guard(slug: string): Observable<boolean> {
    return this.productService.verifyProductSlug(slug)
      .pipe(
        tap((result) => {
          if (!result) {
             this.router.navigate(['']);
            }
          })
      );
  }
}
