import {PermissionName} from '../../domain/userdata';
import {IdentityTypes} from '../../domain/identity/identitytypes';

export enum MenuAlignment {
  Left= 'left',
  Right= 'right'
}

export interface IMenuItem {
  nameCode: string; // Should correspond to menu.<code> in the i18n files for a translation.
  urlPath?: string; // Path (relative to base url) to follow when clicking the link.
  altUrls?: string[]; // Other URLs for which this menu item counts as active (important for pages with a sub-menu bar).
  actions?: string[];
  permission?: PermissionName;
  identityTypes?: IdentityTypes[]; // Use this to filter on IdTypes, or omit it to allow all IdTypes to see it.
  submenu?: IMenuItem[];
  align?: MenuAlignment;
  showAsButton?: boolean;
  fn?: () => void;
}
