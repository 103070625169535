import {JsonObject, JsonProperty} from 'ta-json';

@JsonObject()
export class DriveItemIds {
  @JsonProperty() public quotation?: string;
  @JsonProperty() public intake?: string;
  @JsonProperty() public reporting?: string;
  @JsonProperty() public startCoachingMail?: string;
  @JsonProperty() public infoSheet?: string;
  @JsonProperty() public startResearchMail?: string;
  get(dft: DriveFileTypes): string {
    switch (dft) {
      case DriveFileTypes.Intake:
        return this.intake;
      case DriveFileTypes.Quotation:
        return this.quotation;
      case DriveFileTypes.Reporting:
        return this.reporting;
      case DriveFileTypes.StartCoachingMail:
        return this.startCoachingMail;
      case DriveFileTypes.StartResearchMail:
        return this.startResearchMail;
      case DriveFileTypes.InfoSheet:
        return this.infoSheet;
    }
  }
}

export enum DriveFileTypes {
  Intake = 'intake',
  Quotation = 'quotation',
  StartCoachingMail = 'startCoachingMail',
  Reporting = 'reporting',
  PlanningChange = 'planningChange',
  MovingPlaces = 'movingPlaces',
  CoachChange = 'coachChange',
  HolidayClient = 'holidayClient',
  EmptyLetter = 'emptyLetter',
  InfoSheet = 'infoSheet',
  StartResearchMail = 'startResearchMail',
  RestartAfterTemporisation = 'restartAfterTemporisation'
}

export const specificFileTypes = [
  DriveFileTypes.Quotation,
  DriveFileTypes.Reporting,
  DriveFileTypes.StartCoachingMail,
  DriveFileTypes.StartResearchMail,
  DriveFileTypes.InfoSheet
];

export const globalFileTypes = [
  DriveFileTypes.Intake,
  DriveFileTypes.PlanningChange,
  DriveFileTypes.MovingPlaces,
  DriveFileTypes.CoachChange,
  DriveFileTypes.HolidayClient,
  DriveFileTypes.EmptyLetter,
  DriveFileTypes.RestartAfterTemporisation
];
