import {JsonConverter, JsonElementType, JsonObject, JsonProperty} from 'ta-json';
import {Identity} from './identity/identitydata';
import {IdentityTypes} from './identity/identitytypes';
import {UTCInterpreter} from './utcinterpreter';
import {ListButton} from './list/list-data';
import {IdentityDetails} from './identity/identityTableData';
import {LocalDateTime} from '@js-joda/core';
import {ArchiveData} from './archiveData';

@JsonObject()
export class User {
  @JsonProperty() email: string;
  @JsonProperty() id: number;
  @JsonProperty() firstName: string;
  @JsonProperty() @JsonElementType(String) roles: string[] = [];
  @JsonProperty() userIdentity: Identity = null;
  @JsonProperty() verified: boolean;
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt?: LocalDateTime = null;
  @JsonProperty() passwordToken: string;
  @JsonProperty() firstLogin: boolean;
  @JsonProperty() archiveData: ArchiveData;


  public isInternalUser(): boolean {
    return this.userIdentity && this.userIdentity.isInternal();
  }
}

@JsonObject()
export class UserTableData {
  @JsonProperty() id: number;
  @JsonProperty() userIdentity: IdentityDetails = null;
  @JsonProperty() archiveData: ArchiveData;
  @JsonProperty() @JsonConverter(UTCInterpreter) public createdAt?: LocalDateTime = null;

  public isInternalUser(): boolean {
    return this.userIdentity && this.userIdentity.isInternal();
  }
}

/**
 * Describes some operation a permission can be set for. This could represent one or multiple entrypoints that make
 * sense to group together. For example the operation of managing users. Or it could be very specific; the operation
 * of changing your own password.
 *
 * @param name Unique permission name (database key)
 * @param description A description or display name for the operation
 * @param kind The kind of operation may specify admin control options. For example, it won't make sens to set an
 *             'execute' permission for some operations. The kind may control the permission options presented.
 */
@JsonObject()
export class Operation {
  @JsonProperty() name: string;
  @JsonProperty() description: string;
  @JsonProperty() kind: string;
}

/**
 * The permission defines a relationship between a role and an operation.
 *
 * The permission bit represents a UNIX style permission level, so different roles
 * may have different levels of permission on some operation. All actions on operations
 * may be expressed in write, read, and execute. See http://permissions-calculator.org/
 *
 * read - 4
 * write - 2
 * execute - 1
 * readwrite - 6
 * all - 7
 */
@JsonObject()
export class Permission {
  @JsonProperty() bit: number;
  @JsonProperty() operation: Operation;
}

@JsonObject()
export class UserWithPermissions {
  @JsonProperty() user: User;
  @JsonProperty()
  @JsonElementType(Permission)
  permissions: Permission[];
}

@JsonObject()
export class CrudUserData {
  @JsonProperty() user: User;
  @JsonProperty() password: string;
  @JsonProperty() passwordConfirm: string;

  constructor(u: User, p: string, pc: string) {
    this.user = u;
    this.password = p;
    this.passwordConfirm = pc;
  }
}

@JsonObject()
export class AppAccessToken {
  @JsonProperty() token: string;
  @JsonProperty() useremail: string;
  @JsonProperty() lastRefresh: number;
  @JsonProperty() createdAt: number;
  @JsonProperty() isInternal: boolean;
  @JsonProperty() mfaValid: boolean;
  @JsonProperty() passwordExpired: boolean;

}

export enum PermissionName {
  Create = 1,
  Read = 2,
  Update = 4,
  Delete = 8,
  All = Create | Read | Update | Delete
}

export interface IUserColumnData {
  id: number;
  name: string;
  lastName: string;
  email?: string;
  company?: string;
  isActive: boolean;
  role?: IdentityTypes;
  city?: string;
  phoneNumber?: string;
  createdAt: LocalDateTime;
  actions: ListButton<IUserColumnData>[];
  identityId: number;  // We add this to have access to the Identity belonging to the user we click in the list.
  idType?: IdentityTypes;
}

export enum UserType {
  Internal = 'internal',
  External = 'external'
}

